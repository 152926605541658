import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { items } from "@/api/items/useItemQuery";
import { mutateLots } from "@/api/lots/useLotsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { LotSelectComplete } from "@/features/ui/autoComplete/lot/lot-\bselectComplete";
import useGetLotsIdQuery from "@/features/ui/autoComplete/lot/select-useGetLotsQuery";
import { LotForm } from "@/features/ui/form/lots/form";
import { Button, Flex, Space, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { DefaultApiLotsPostRequest, DefaultApiWmsOutgoingPostRequest, StockPlansGet200ResponseRowsInner, WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { EventHandler, useState } from "react";
import { LotMutationFromProps } from "./stockPlanIncomingForm";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Date: DateInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    ItemAutoComplete: ItemAutoComplete,
    LocationAutoComplete: LocationAutoComplete,
});

interface StockPlanOutgoingFormProps {
    formatterProps: StockPlansGet200ResponseRowsInner
}

export const StockPlanOutgoingForm = (params: StockPlanOutgoingFormProps) => {
    const { openModal, closeModal } = useModal();
    const { formatterProps } = params;
    const queryClient = useQueryClient();

    const { mutate: outgoingMutate } = useMutation(
        (params: DefaultApiWmsOutgoingPostRequest) =>
            mutateInventories.outgoing(params).mutationFn(params as DefaultApiWmsOutgoingPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["inventories"]);
            }
        }
    )
    const { mutate: postLotsMutate } = useMutation(
        (params: DefaultApiLotsPostRequest) =>
            mutateLots.create(params).mutationFn(params as DefaultApiLotsPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["lots"]);
            }
        }
    );
    const [newLot, setNewLot] = useState<LotMutationFromProps>();

    const form = useForm<Partial<WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner>>({
        initialValues: {
            itemCode: formatterProps.itemCode,
            locationCode: formatterProps.sourceLocationCode,
            quantity: formatterProps.quantity,
            lotId: formatterProps.lotId,
            purchaseOrderItemId: formatterProps.purchaseOrderItemId,
            lotInfo: {
                name: null,
                expiration: null,
            },
        },
    });

    const { data: itemData } = useQuery(items.codeSearch({
        query: form.values.itemCode
            ? { $and: [{ code: { $eq: form.values.itemCode } }] }
            : "",
    }));

    const { data: lotData } = useGetLotsIdQuery(
        Number(form.values?.lotId) || Number(newLot?.id),
        !!form.values?.lotId || !!newLot?.id
    );

    const onSubmit = () => {
        try {
            outgoingMutate(
                {
                    workLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner: {
                        itemCode:
                            formatterProps.itemCode ?? '',
                        purchaseOrderItemId: form.values.purchaseOrderItemId,
                        locationCode:
                            formatterProps.sourceLocationCode ?? '',
                        quantity: form.values.quantity ? form.values.quantity : formatterProps.quantity as string,
                        lotId: Number(formatterProps.lotId || form.values.lotId),
                        lotInfo: {
                            name:
                                form.values.itemCode && lotData?.name
                                    ? lotData?.name
                                    : newLot?.name,
                            expiration:
                                form.values.itemCode && lotData?.expiration
                                    ? lotData?.expiration
                                    : newLot?.expiredAt,
                        },
                        actionFrom: 'WEB'
                    },
                },
                {
                    onSuccess: (res: { status: number }) => {
                        closeModal(form.values);
                        if (res.status === 200) {
                            customAlert(
                                "출고에 성공하였습니다.",
                                "출고 성공",
                                "green"
                            )
                        } else {
                            customAlert(
                                "출고에 실패하였습니다.",
                                "출고 실패",
                                "red"
                            )
                        }
                    },
                }
            );
        } catch (e) {
            customAlert(
                "재고 출고에 실패하였습니다.",
                "재고 출고 실패",
                "red"
            )
        }
    };

    const onCloseHandler: EventHandler<any> = (values) => {
        postLotsMutate(
            {
                lotsGetRequest: {
                    name: values.name!,
                    expiration: values.expiredAt!,
                    itemCode: values.itemCode!,
                },
            },
            {
                onSuccess: (data) => {
                    setNewLot(data?.data as LotMutationFromProps | undefined);
                    alert("로트를 생성했습니다.");
                },
            }
        );
        closeModal(values);
    };

    const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
        e
    ) => {
        e.preventDefault();
        openModal(
            <LotForm
                itemCodeValue={form.values.itemCode as string}
                onSubmit={onCloseHandler}
            />,
            null,
            "신규 LOT 등록",
            true
        ).then((result) => { });
    };

    return (
        <>
            <Create.Wrapper>
                <Create.ItemAutoComplete
                    label="품목코드"
                    withAsterisk
                    {...form.getInputProps("itemCode")}
                />
                <Create.LocationAutoComplete
                    label="로케이션코드"
                    withAsterisk
                    {...form.getInputProps("locationCode")}
                />
                <Create.Input
                    label="수량"
                    withAsterisk
                    type="number"
                    {...form.getInputProps("quantity")}
                />
                <Flex justify="space-between" align="center">
                    <LotSelectComplete
                        label="LOT 정보"
                        description={`LOT 관리 기준 : 
                        ${form.values.itemCode &&
                                itemData?.data?.rows &&
                                itemData?.data?.rows[0]?.usesLotName &&
                                itemData?.data?.rows[0]?.usesLotExpiration
                                ? "이름 + 만료일"
                                : form.values.itemCode &&
                                    itemData?.data?.rows &&
                                    itemData?.data?.rows[0]?.usesLotName
                                    ? "이름"
                                    : form.values.itemCode &&
                                        itemData?.data?.rows &&
                                        itemData?.data?.rows[0]?.usesLotExpiration
                                        ? "만료일"
                                        : "관리 안함"
                            }`}
                        itemCodeValue={
                            (form.values.itemCode as string) || (lotData?.itemCode as string)
                        }
                        w="100%"
                        newIdValue={lotData?.id}
                        dropdownPosition="bottom"
                        {...form.getInputProps("lotId")}
                        defaultValue={lotData && lotData?.id!.toString()}
                        mr="sm"
                        styles={
                            lotData
                                ? {
                                    description: { fontSize: 10, color: "red" },
                                    input: { fontSize: "0.8em", paddingBottom: "0.5rem" },
                                }
                                : {
                                    description: { fontSize: 10, color: "red" },
                                }
                        }
                        disabled={
                            (itemData?.data?.rows && itemData?.data?.rows[0]?.usesLotName) ||
                                (itemData?.data?.rows &&
                                    itemData?.data?.rows[0]?.usesLotExpiration)
                                ? false
                                : true
                        }
                    />
                    <div>
                        <Space h="xl" />
                        <Space h="lg" />
                        <Create.Button
                            my="xl"
                            disabled={
                                (itemData?.data?.rows &&
                                    itemData?.data?.rows[0]?.usesLotName) ||
                                    (itemData?.data?.rows &&
                                        itemData?.data?.rows[0]?.usesLotExpiration)
                                    ? false
                                    : true
                            }
                            onClick={addNewModalHandler}
                        >
                            신규로트 생성
                        </Create.Button>
                    </div>
                </Flex>
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>
                        취소
                    </Create.Button>
                    <Create.Button onClick={onSubmit}>재고 출고</Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    );
};
