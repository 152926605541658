import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { ActionIcon, Paper, Title } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import dayjs from "dayjs";

interface WorkHeaderProps {
    dateType?: "default" | "range";
}

export const WorkHeader = ({ dateType }: WorkHeaderProps) => {
    const state = useWorkState();
    const actions = useWorkActions();
    const type = "default";

    const dateTitle = type === dateType ? dayjs(state.date[0]).format("YYYY-MM-DD")
        : dayjs(state.date[0]).format("YYYY-MM-DD") + " ~ " + dayjs(state.date[1] ?? new Date()).format("YYYY-MM-DD")

    const setPrevDate = () => {
        const newDate = dayjs(state.date[0]).subtract(1, 'day').startOf('day');
        actions.setDate([newDate.toDate(), newDate.toDate()]); // 하루 전으로 설정
    }

    const setNextDate = () => {
        const newDate = dayjs(state.date[0]).add(1, 'day').startOf('day');
        actions.setDate([newDate.toDate(), newDate.toDate()]); // 하루 후로 설정
    }

    return (
        <WorkHeaderWrapper withBorder>
            {
                type === dateType &&
                <ActionIcon color={theme.colors?.blue?.[6]} onClick={() => setPrevDate()} >
                    <IconChevronLeft />
                </ActionIcon>
            }
            <Title size="h1" weight={700}>
                {dateTitle}
            </Title>
            {
                type === dateType &&
                <ActionIcon color={theme.colors?.blue?.[6]} onClick={() => setNextDate()}>
                    <IconChevronRight />
                </ActionIcon>
            }
        </WorkHeaderWrapper>
    )
}

const WorkHeaderWrapper = styled<any>(Paper)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
`