import { QualityInstance } from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { QualityApiQualityDefectStatisticsDefectCodeGetRequest, QualityApiQualityDefectStatisticsGetRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const defectStatistics = createQueryKeys('defectStatistics', {
    all : null,
    defectStatistics : (params : QualityApiQualityDefectStatisticsGetRequest) => {
        return {
            queryKey : [params],
            queryFn : () => QualityInstance.qualityDefectStatisticsGet(params)
        }
    },
    defectStatisticsDetail : (params : QualityApiQualityDefectStatisticsDefectCodeGetRequest) => {
        return {
            queryKey : [params],
            queryFn : () => QualityInstance.qualityDefectStatisticsDefectCodeGet(params)
        }
    }
});