import { ItemsCreateForm } from "@/features/item/components/form/ItemsCreateForm";
import { ItemsTable } from "@/features/item/components/table/ItemsTable";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const ItemWrap = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    Wrapper: Wrapper,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    CreateForm: ItemsCreateForm,
});

export const ALCModelPage = () => {

    const { openModal } = useModal();

    const publish = usePub();
    return (
        <ItemWrap.Wrapper>
            <ItemWrap.ActionHeader>
                <ItemWrap.ActionButton
                    leftIcon={<IconPlus />}
                    onClick={() =>
                        openModal(<ItemWrap.CreateForm />, null, "품목 추가", true)
                    }
                >
                    추가
                </ItemWrap.ActionButton>
                <ItemWrap.ActionButton
                    color="red"
                    rightIcon={<IconTrash />}
                    onClick={() => publish("deleteSelectedItems")}
                >
                    선택된 행 삭제
                </ItemWrap.ActionButton>
            </ItemWrap.ActionHeader>
            <ItemWrap.Header>
                <ItemWrap.HeaderTitle>품목 리스트</ItemWrap.HeaderTitle>
                <ItemWrap.HeaderSubTitle>
                    {"우리 회사에서 다루는 원/부자재,제품,반제품 등에 대해 조회할 수 있는 페이지 입니다."}
                </ItemWrap.HeaderSubTitle>
            </ItemWrap.Header>
            <ItemsTable />
        </ItemWrap.Wrapper>
    );
};
