import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

interface PieChartProps {
    names : string[] | undefined;
    count : string[] | undefined;
}

export default function PieApexChart({ names, count }: PieChartProps) {

    const numericCount = count?.map(Number) ?? [];

    const options: ApexOptions = {
        labels: names ?? [],
        legend: {
            show: true,
            position: "bottom" as "bottom" // 명시적으로 타입 지정
        },
        colors: ["#00AB55", "#2D99FF", "#FFE700", "#826AF9"],
        tooltip: {
            y: { formatter: (value: any) => value + "개" }
        }
    };

    return (
        <Chart
            type="pie"
            series={numericCount ?? []}
            options={options}
            width="100%"
        />
    );
}