import { columnlist } from "@/constants/columnlist";
import { useModal } from "@/features/modal/ModalStackManager";
import { Calendar } from "@/features/ui/Calendar";
import { GridTable } from "@/features/ui/PMS/GridTable";
import { Button, ColorSwatch, Flex, Input, Select, Text } from "@mantine/core";
import { IconCalendar, IconSearch } from "@tabler/icons-react";
import { useState } from "react";
import { SelectColumn } from "react-data-grid";

type PageType = {
    current_page: number
    first?: true
    last?: true
    limit?: number
    total_page: number
}

export const MoldDailyStatusPage = () => {
    const { openModal } = useModal();

    const [content, setContent] = useState<any>([]);
    const [selectList, setSelectList] = useState<Set<number>>(new Set())
    const [page, setPage] = useState<PageType>({
        current_page: 1,
        total_page: 1
    })

    const [searchPage, setSearchPage] = useState<PageType>({
        current_page: 1,
        total_page: 1
    })
    const [opt, setOpt] = useState<number>(1);
    
    return (
        <Flex
            gap="md"
            direction={"column"}
        >
            <Flex
                align={"center"}
                justify={"space-between"}
            >
                <Text>금형 일상점검 일일현황</Text>
                <Flex gap="sm" align={'center'}>
                    <Flex
                        w={200}
                        justify={'space-between'}
                    >
                        <ColorSwatch color={'green'} size={20} /><Text>완료</Text>
                        <ColorSwatch color={'orange'} size={20} /><Text>관리자 확인요망</Text>
                    </Flex>
                    <Button
                        leftIcon={<IconCalendar />}
                        color="indigo"
                        onClick={() => {
                            openModal(<Calendar dates={[null, null]} type="range" />, null, '날짜 선택')
                        }}
                    >날짜선택</Button>
                    <Select
                        w={'8rem'}
                        defaultValue={'code'}
                        data={[
                            { label: 'CODE', value: 'code' },
                            { label: '금형명', value: 'name' },
                        ]}
                    />
                    <Input
                        w={'16rem'}
                        placeholder="검색어를 입력하세요"
                        rightSection={<IconSearch />}
                    />
                    <Button>검색</Button>
                    <Button>엑셀로 받기</Button>
                </Flex>
            </Flex>
            <GridTable
                headerList={[
                    SelectColumn,
                    ...columnlist.moldDaily_status
                ]}
                row={content}
                setRow={setContent}
                selectList={selectList}
                //@ts-ignore
                setSelectList={setSelectList}
                width={'100%'}
                height={750}
            // scrollEnd={(value) => {
            //     if(value){
            //         if(keyword !== undefined){
            //             if(searchPage.total_page > searchPage.current_page){
            //                 getPressDailyStatusSearchApi(keyword, searchPage.current_page+ 1)
            //             }
            //         }else{
            //             if(page.total_page > page.current_page){
            //                 getPressDailyStatusApi(page.current_page+ 1)
            //             }
            //         }
            //     }
            // }}
            />
        </Flex>
    )
}