import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { EventHandler, ReactNode, forwardRef, useState } from "react";

import { useModal } from "@/features/modal/ModalStackManager";
import {
  AbstractItemOption,
  useAbstractItemsAutoComplete,
  useAbstractItemsCode,
} from "@/features/ui/autoComplete/abstractItem/auto-useGetAbstractItemsQuery";
import styled from "@emotion/styled";

import { mutateAbstractltems } from "@/api/abstractItems/useAbstractltemsQuery";
import { AbstractItemCreateForm } from "@/features/abstractItem/form/createForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { DefaultApiAbstractItemsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import customAlert from "../../alert/alert";

interface AbstractItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export interface AbstractItemAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (AbstractItemCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
}

// 입력값: value (AbstractItem 모델의 code)
// 출력값: onChange (AbstractItem 모델의 code)

export const AbstractItemAutoComplete = (
  params: AbstractItemAutoCompleteProps
) => {
  const {
    value: AbstractItemCode,
    onChange,
    maxDropdownHeight,
    width,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useAbstractItemsAutoComplete(
    focused,
    searchKeyword
  );

  const { data: initialOptions } = useAbstractItemsCode(
    !!AbstractItemCode,
    AbstractItemCode ?? null
  );

  let selectedAbstractItem = initialOptions?.find(
    (AbstractItem) => AbstractItem.value === AbstractItemCode
  );
  const onChangeHandler = (e: string | null) => {
    selectedAbstractItem = options?.find(
      (AbstractItem) => AbstractItem.value === e
    );
    onChange && onChange(e);
  };

  const SelectAbstractItem = forwardRef<HTMLDivElement, AbstractItemProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs">(품목군 코드: {code})</Text>
          </Flex>
        </Flex>
      </div>
    )
  );

  return (
    <AbstractItemSelect
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedAbstractItem?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "10px",
              }}
            >
              {"code: " + selectedAbstractItem?.value}
            </div>
          )}
        </div>
      )}
      value={AbstractItemCode}
      itemComponent={SelectAbstractItem}
      searchValue={searchKeyword}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: AbstractItemProps[], option: AbstractItemProps) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={AbstractItemInfo({
        AbstractItem: selectedAbstractItem as AbstractItemOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewAbstractItem({
        AbstractCode: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const AbstractItemInfo = (params: {
  AbstractItem?: AbstractItemOption;
  onChange: (AbstractItemCode: string | null) => void;
}) => {
  const { AbstractItem, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return AbstractItem?.value ? (
    <AbstractItemInfoLabel>
      {/* ({AbstractItem.value})  */}
      <IconX size="1rem" onClick={clearHandler} />{" "}
    </AbstractItemInfoLabel>
  ) : null;
};

const AddNewAbstractItem = (params: {
  AbstractCode: string;
  onChange: (AbstractItemCode: string) => void;
}) => {
  const { AbstractCode, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: postMutate } = useMutation(
    (params: DefaultApiAbstractItemsPostRequest) =>
      mutateAbstractltems.create(params).mutationFn(params as DefaultApiAbstractItemsPostRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['abstractItems']);
        closeModal(res.data);
        customAlert(
          "품목군 생성에 성공하였습니다.",
          "생성 성공",
          "green"
        )
      },
      onError: (error) => {
        customAlert(handleErrorResponse({ error, errorContext: '품목군코드' }), '품목군 생성 실패', 'red')
      }
    }
  );

  const onCloseHandler: EventHandler<any> = (values) => {
    postMutate(
      {
        abstractItemsGetRequest: {
          code: values.code!,
        },
      },
    );
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <AbstractItemCreateForm code={AbstractCode} autoCompleteOnSubmit={onCloseHandler} />,
      null,
      "신규 품목군 등록",
      true
    ).then((result) => {
      onChange(result.code);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 품목군({AbstractCode}) 등록
    </Button>
  );
};
const AbstractItemInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  position: absolute;
`;

const AbstractItemSelect = styled(Select)<{value : string | null | undefined}>`
  .mantine-Select-input {
    font-size: 0.8rem;
    padding-bottom: ${(props) => (props.value ? '0.8rem !important' : '')};

    &.item-dirty {
      color: cadetblue !important;
      border-color: cadetblue;
    }
  }
  .mantine-Select-error {
    font-size: 11px;
    color: red;
  }
`
