import styled from '@emotion/styled'
import React, { CSSProperties } from 'react'
import { FrequentlyChartLegendShapeType } from './Frequently'
import FrequentlyChartLegendShapeCircle from './FrequentlyChartLegendShapeCircle'



interface Props {
  type: FrequentlyChartLegendShapeType
}

const Container = styled.div`
    width: 24;
    height: 0;
    margin-right: 5;
`


const FrequentlyChartLegendShape: React.FunctionComponent<Props> = ({ type }) => {
  const styles = React.useMemo(() => {
    let style: CSSProperties = {}

    if (type.type === 'box') {
      style.border = `5px solid ${type.color}`
    } else if (type.type === 'circle') {
      style = {
        width: 8,
        height: 8,
        borderRadius: 8,
        background: type.color
      }
    } else {
      style.border = `1px solid ${type.color}`
    }

    return style
  }, [ type ])

  return (
    <React.Fragment>
      <Container style={styles}/>
      {
        type.type === 'lineInCircle' && <FrequentlyChartLegendShapeCircle color={type.color}/>
      }
    </React.Fragment>
  )
}

export default React.memo(FrequentlyChartLegendShape)
