import { equipments } from "@/api/equipment/useEquipmentQuery";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { EquipmentWorksTabs } from "../tabs/EquipmentWorksTab";
import { EquipmentDetailInForm } from "./EquipmentDetailInForm";

const Detail = Object.assign(Main, {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
})

export interface DetailEquipmentProps {
    equipmentCode: string | undefined;
};

export const EquipmentDetailForm = (params: DetailEquipmentProps) => {

    const { equipmentCode } = params;

    const { data: equipmentData } = useQuery(equipments.get({
        query: { $and: [{ "code": { "$eq": equipmentCode } }] },
        populate: ["location", "works"]
    }))

    return (
        <>
            <Detail.Container>
                <Detail.Wrapper>
                    <Detail.Content>
                        <EquipmentDetailInForm data={equipmentData} />
                        <Tabs
                            defaultValue="equipmentWorks"
                        >
                            <LeftSpace />
                            <Tabs.List>
                                <Tabs.Tab value="equipmentWorks">작업 목록</Tabs.Tab>
                            </Tabs.List>
                            <Tabs.Panel value="equipmentWorks" p={20}>
                                <Title order={3}>작업 목록</Title>
                                <Text>설비에 대한 작업 목록을 보여줍니다.</Text>
                                <EquipmentWorksTabs equipmentCode={equipmentCode} />
                            </Tabs.Panel>
                        </Tabs>
                    </Detail.Content>
                </Detail.Wrapper>
            </Detail.Container>
        </>
    )
};

const LeftSpace = styled.div`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`