import { MasterInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiDepartmentsDepartmentIdDeleteRequest, MasterApiDepartmentsDepartmentIdGetRequest, MasterApiDepartmentsDepartmentIdPutRequest, MasterApiDepartmentsGetRequest, MasterApiDepartmentsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";



export const DEPARTMENT_QUERY_KEY = 'department';


export const department = createQueryKeys(DEPARTMENT_QUERY_KEY, {
    all: null,
    get: (params: MasterApiDepartmentsGetRequest) => {
        const query = { ...params };
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => MasterInstance.departmentsGet(query)
        }
    },
    detail: (params: MasterApiDepartmentsDepartmentIdGetRequest) => {
        return {
            queryKey: [params.departmentId],
            queryFn: () => MasterInstance.departmentsDepartmentIdGet(params)
        }
    }
})

export const mutateDepartment = createMutationKeys(DEPARTMENT_QUERY_KEY, {
    create: (params: MasterApiDepartmentsPostRequest) => ({
        mutationKey: [params.departmentsGetRequest],
        mutationFn: () => MasterInstance.departmentsPost(params),
    }),
    update: (params: MasterApiDepartmentsDepartmentIdPutRequest) => ({
        mutationKey: [params.departmentsGetRequest],
        mutationFn: () => MasterInstance.departmentsDepartmentIdPut(params),
    }),
    delete: (params: MasterApiDepartmentsDepartmentIdDeleteRequest) => ({
        mutationKey: [params.departmentId],
        mutationFn: () => MasterInstance.departmentsDepartmentIdDelete(params),
    })


})

export const wmsLogQuery = mergeQueryKeys(department, mutateDepartment);