import { useModal } from "@/features/modal/ModalStackManager"
import FullTimeInput from "@/features/ui/PMS/fullTimeInput"
import styled from "@emotion/styled"
import { Box, Checkbox, Flex, Input } from "@mantine/core"
import { IconSearch } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useState } from "react"
import { MachineSearchBox } from "../machineSearchBox"

interface LunchProps {
    created: string
    hour: number
    id: number
    isActive: boolean
    minute: number
    modified: string
    second: number
    subType: string
    type: string
    isChange?: boolean
}

export const SettingBox = (initValue: any) => {

    const { openModal } = useModal();
    const [startLunchTime, setStartLunchTime] = useState<LunchProps>({
        created: '',
        hour: 0,
        id: 0,
        isActive: false,
        minute: 0,
        modified: '',
        second: 0,
        subType: '',
        type: ''
    })

    const [endLunchTime, setEndLunchTime] = useState<LunchProps>({
        created: '',
        hour: 0,
        id: 0,
        isActive: false,
        minute: 0,
        modified: '',
        second: 0,
        subType: '',
        type: ''
    })

    const [intervalTime, setIntervalTime] = useState<LunchProps>({
        created: '',
        hour: 0,
        id: 0,
        isActive: false,
        minute: 0,
        modified: '',
        second: 0,
        subType: '',
        type: ''
    })

    const [selected, setSelected] = useState<any>({
        machine: initValue,
        material: 0,
        date: {
            from: dayjs().format('YYYY-MM-DD'),
            to: dayjs().format('YYYY-MM-DD')
        }
    })


    return (
        <>
            <Box h="auto" w="auto">
                <UnderLineDiv>
                    <Flex
                        align='center'
                        h={10}
                    >
                        <Checkbox
                            label="점심 시간 설정"
                            checked={startLunchTime.isActive && endLunchTime.isActive}
                            onChange={() => {
                                setStartLunchTime({ ...startLunchTime, isActive: !startLunchTime.isActive, isChange: true })
                                setEndLunchTime({ ...endLunchTime, isActive: !endLunchTime.isActive, isChange: true })
                            }} />
                    </Flex>
                    <TimeContainer active={startLunchTime.isActive && endLunchTime.isActive}>
                        <FullTimeInput title={'시작 시간'}
                            active={startLunchTime.isActive && endLunchTime.isActive}
                            times={startLunchTime}
                            setTimes={setStartLunchTime}
                        />
                        {'~'}
                        <FullTimeInput title={'종료 시간'}
                            active={startLunchTime.isActive && endLunchTime.isActive}
                            times={endLunchTime}
                            setTimes={setEndLunchTime}
                        />
                    </TimeContainer>
                </UnderLineDiv>
                <UnderLineDiv>
                    <Flex
                        align='center'
                        mb={10}
                    >
                        <div style={{ width: 83 }}>
                            <LabelP>{'기계 선택'}</LabelP>
                        </div>
                        <Input
                            defaultValue={selected.machine?.name}
                            value={selected.machine?.name}
                            onClick={() => {
                                openModal(<MachineSearchBox />, null, '기계 검색')
                            }}
                            placeholder="기계를 선택해 주세요"
                            rightSection={
                                <IconSearch size="1rem" style={{ display: 'block', opacity: 0.5 }} />
                            }
                        />
                    </Flex>
                    <Flex>
                        <Checkbox
                            label="인터벌 시간 설정"
                            checked={intervalTime.isActive}
                            onChange={() => {
                                if (selected?.machine?.machine_id) {
                                    setIntervalTime({ ...intervalTime, isActive: !intervalTime.isActive, isChange: true })
                                }
                            }} />
                    </Flex>
                    <TimeContainer active={intervalTime.isActive}>
                        <FullTimeInput title={'간격'}
                            active={intervalTime.isActive}
                            times={intervalTime}
                            setTimes={setIntervalTime}
                        />
                    </TimeContainer>
                </UnderLineDiv>
            </Box>
        </>
    )
}

const UnderLineDiv = styled.div`
    display : flex;
    height: 149px;
    border-bottom: ridge;
    padding: 10px 0;
    flex-direction: column;
`

const TimeContainer = styled.div<any>`
    margin-left : 10;
    color : ${props => props.active ? 'black' : 'gray'}
`

const LabelP = styled.p`
    font-size : 15px;
    font-weight : bold;
`