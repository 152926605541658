import { partners } from "@/api/partner/usePartnerQuery";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useQuery } from "@tanstack/react-query";
import { PartnerDetailHeader } from "../header/partnerDetailHeader";

const Detail = Object.assign({}, Main, {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
})

interface PartnerDetailFormProps {
    partnerCode: string | undefined;
}
export const PartnerDetailForm = (params: PartnerDetailFormProps) => {

    const { partnerCode } = params;

    const { data: partnerData } = useQuery(partners.get({
        query: { $and: [{ "code": { "$eq": partnerCode } }] }
    }))

    return (
        <>
            <Detail.Container>
                <Detail.Wrapper>
                    <Detail.Content>
                        <PartnerDetailHeader data={partnerData} />
                    </Detail.Content>
                </Detail.Wrapper>
            </Detail.Container>
        </>
    )
}