
type StatusObjType = {
    [key: string]: string;
}

// 수발주 상태
export const purchaseOrderStatusColor = {
    "DRAFT" : "dark.1",
    "IN_PROGRESS" : "indigo.6",
    "DONE" : "teal.9", 
    "CANCELED" : "orange.4"
}

export const purchaseOrderStatus: StatusObjType = {
    DRAFT : "대기",
    IN_PROGRESS : "진행중",
    DONE : "완료",
    CANCELED : "취소됨"
}

export const setPurchaseOrderStatus = (status: string) => {
    return purchaseOrderStatus[status];
}

// 배차 상태
export const transportationStatusColor = {
    "SCHEDULED" : "indigo.6",
    "LOADING" : "orange.4",
    "DONE" : "teal.9",
    "ON_ROAD" : "green.7",
    "ERROR" : "red.6"
}

export const transportationStatus: StatusObjType = {
    SCHEDULED : "예약확인중",
    LOADING : "지연중",
    DONE : "완료",
    ON_ROAD : "운행중",
    ERROR : "사고"
}

export const setTransportationStatus = (status: string) => {
    return transportationStatus[status];
}

// 협력사 상태
export const partnerStatusColor = {
    "SALES" : "indigo.6",
    "PURCHASE" : "orange.4",
    "BI_DIRECTION" : "green.7"
}

export const partnerStatus: StatusObjType = {
    SALES : "판매",
    PURCHASE : "구매",
    BI_DIRECTION : "양방향"
}

export const setPartnerStatus = (status: string) => {
    return partnerStatus[status];
}