import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { setTransportationStatus } from "@/utils/scmDataStatus";
import timeUtil from "@/utils/timeUtil";
import { trackingStatusColor } from "@/utils/workStatus";
import { Checkbox, Space } from "@mantine/core";
import { TransportationGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

const DispatchingDetail = Object.assign(DetailHeader, {
    Title: DetailTitle,
    Inform: DetailInformTitle,
    Field: DetailField,
    Label: DetailLabel,
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
    Content: DetailContent,
    CheckBox: Checkbox,
});

export interface TransportationDetailHeaderProps {
    data: AxiosResponse<TransportationGet200ResponseRowsInner, any> | undefined
}

export const TransportationDetailHeader = (params: TransportationDetailHeaderProps) => {
    const { data } = params;
    const { t } = useTranslation(); 

    return (
        <DispatchingDetail>
            <DispatchingDetail.Title>{`[배차]`}</DispatchingDetail.Title>
            <Space h={8} />
            <DispatchingDetail.Field>
                <DispatchingDetail.Label>
                    {"계획일시"}
                </DispatchingDetail.Label>
                <DispatchingDetail.Content>
                    {timeUtil(data?.data?.scheduledAt ?? '')}
                </DispatchingDetail.Content>
            </DispatchingDetail.Field>
            <DispatchingDetail.Field>
                <DispatchingDetail.Label>
                    {"차량번호"}
                </DispatchingDetail.Label>
                <DispatchingDetail.Content>
                    {data?.data?.creatorUserCode}
                </DispatchingDetail.Content>
            </DispatchingDetail.Field>
            <DispatchingDetail.Field>
                <DispatchingDetail.Label>
                    {"협력사"}
                </DispatchingDetail.Label>
                <DispatchingDetail.Content>
                    {data?.data?.partnerCode}
                </DispatchingDetail.Content>
            </DispatchingDetail.Field>
            <DispatchingDetail.Field>
                <DispatchingDetail.Label>
                    {"상태"}
                </DispatchingDetail.Label>
                <DispatchingDetail.Content>
                    <DispatchingDetail.Badge 
                        variant="filled"
                        color={trackingStatusColor[data?.data?.status as keyof typeof trackingStatusColor]}
                    >
                        {setTransportationStatus(data?.data?.status ?? '')}
                    </DispatchingDetail.Badge>
                </DispatchingDetail.Content>
            </DispatchingDetail.Field>
            <DispatchingDetail.Field>
                <DispatchingDetail.Label>
                    {"방향"}
                </DispatchingDetail.Label>
                <DispatchingDetail.Content>
                    <DispatchingDetail.Badge color="blue">
                        {t(data?.data?.direction ?? '')}
                    </DispatchingDetail.Badge>
                </DispatchingDetail.Content>
            </DispatchingDetail.Field>
            <DispatchingDetail.Field>
                <DispatchingDetail.Label>
                    {"비고"}
                </DispatchingDetail.Label>
                <DispatchingDetail.Content>

                </DispatchingDetail.Content>
            </DispatchingDetail.Field>
            <DispatchingDetail.Field>
                <DispatchingDetail.Label>
                    {"접수일시"}
                </DispatchingDetail.Label>
                <DispatchingDetail.Content>
                    {data?.data?.creatorUserCode} {timeUtil(data?.data?.createdAt ?? '')}
                </DispatchingDetail.Content>
            </DispatchingDetail.Field>
            <DispatchingDetail.Field>
                <DispatchingDetail.Label>
                    {"수정정보"}
                </DispatchingDetail.Label>
                <DispatchingDetail.Content>
                    {data?.data?.updaterUserCode} {data?.data?.updatedAt ? timeUtil(data?.data?.updatedAt ?? '') : ''}
                </DispatchingDetail.Content>
            </DispatchingDetail.Field>
        </DispatchingDetail>
    )
}