import { ProductionInstance } from "@/instance/axios";
import { ProductionPlansWithWorksPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const PRODUCTION_PLAN_QUERY_KEY = "/getWorkByProductionPlan";
export const WORK_BY_EQUIPMENT_QUERY_KEY = "/workByEquipment";
export const WORKS_GET_KEY = "/works";

const createPlansWithWorks = (params: ProductionPlansWithWorksPostRequest) => {
  return ProductionInstance.productionPlansWithWorksPost({
    productionPlansWithWorksPostRequest: params,
  });
};

const usePlansWithWorksMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: ProductionPlansWithWorksPostRequest) =>
      createPlansWithWorks(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PRODUCTION_PLAN_QUERY_KEY, WORK_BY_EQUIPMENT_QUERY_KEY, WORKS_GET_KEY, 'purchaseOrderItems']);
      },
    }
  );
};

export default usePlansWithWorksMutation;
