import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Badge, Button, Flex, Pagination, useMantineColorScheme } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

import { mutateTransportation, transportations } from "@/api/transportation/useTransportationQuery";
import { purchaseOrderItemsHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { useModal } from "@/features/modal/ModalStackManager";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { useSub } from "@/hooks";
import useQueryString from "@/hooks/useQueryString";
import useUpdateUrlParams from "@/hooks/useUpdateUrlParams";
import { theme } from "@/styles/theme";
import { setTransportationStatus, transportationStatusColor } from "@/utils/scmDataStatus";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { ScmApiTransportationTransportationIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner, TransportationGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models/src/model";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { TransportationDetailForm } from "../form/transportationDetailForm";
import { TransportationUpdateForm } from "../form/updateForm";
interface ColorThemeProps {
    isDarkMode: boolean;
}

export const TransportationTable = ({ onRowSelect }: any) => {
    const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const isDarkMode: boolean = colorScheme === "light";
    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const [selectedRows, setSelectedRows] = useState(
        (): ReadonlySet<any> => new Set()
    );
    const [selectedRowsInform, setSelectedRowsInform] = useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]>([]);
    const { t } = useTranslation();
    const [formatterPropsData, setFormatterPropsData] = useState<any>([]);
    const [activePage, setPage] = useState(initialQueryString.page);
    const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
    const [sort, setSort] = useState(initialQueryString.sort);
    const [search, setSearch] = useState<SearchProps>({
        search: initialQueryString.search,
        searchFields: initialQueryString.searchFields,
        pageSize: initialQueryString.pageSize,
    });

    const updateUrlParams = useUpdateUrlParams();

    const { openModal, closeModal } = useModal();
    const { mutate: deleteMutate } = useMutation(
        (params: ScmApiTransportationTransportationIdDeleteRequest) =>
            mutateTransportation.delete(params).mutationFn(params as any | ScmApiTransportationTransportationIdDeleteRequest),
        {
            onSuccess: () => {
                refetch();
                customAlert("배차 삭제에 성공하였습니다.", "삭제 성공", "green");
                closeModal(null);
                setSelectedRows(new Set());
                selectedRowsRef.current = new Set();
            },
            onError: () => {
                customAlert("배차 삭제에 실패하였습니다.", "삭제 실패", "red");
            }
        }
    );

    const searchFieldsHeader = purchaseOrderItemsHeader.filter(
        (purchaseOrderItems) => purchaseOrderItems.category === "text"
    );

    const { data: transportationData, refetch } = useQuery(transportations.get({
        query: query,
        search: search.search,
        searchFields: search.searchFields.length
            ? search.searchFields
            : searchFieldsHeader.map((purchaseOrderItems) => purchaseOrderItems.value),
        page: activePage,
        pageSize: Number(search.pageSize),
        sort: sort.length ? sort.join(",") : "-id",
    }));

    // 새로운 데이터가 로드될 때, 현재 페이지가 유효하지 않으면 1페이지로 설정
    useEffect(() => {
        if (transportationData && activePage > (transportationData?.data?.totalPages ?? 0)) {
            setPage(1);
            setQueryString((prev) => ({
                ...prev,
                page: 1,
            }));
        }
    }, [transportationData, activePage, setQueryString]);

    // 페이지 Query String으로 공정 이동
    useEffect(() => {
        updateUrlParams({
            page: activePage,
            pageSize: search.pageSize,
            search: search.search,
            searchFields: search.searchFields,
            query: JSON.stringify(query),
            sort: sort
        }, queryDefaultValues);

        setSelectedRows(new Set());
        selectedRowsRef.current = new Set();
    }, [activePage, search, query, sort, updateUrlParams]);

    useEffect(() => {
        const selectedRowId = selectedRows.values().next().value;
        if (selectedRowId) {
            const selectedRow = rows.find((row) => row.id === selectedRowId);

            setFormatterPropsData(selectedRow);
        } else {
            setFormatterPropsData([]);
        }
    }, [selectedRows]);

    useEffect(() => {
        // 부모에게 선택된 row를 넘겨주기 위한 함수
        if (formatterPropsData) {
            onRowSelect(selectedRows, formatterPropsData, selectedRowsRef, selectedRowsInform);
        }
    }, [selectedRows, formatterPropsData]);

    const rows: readonly TransportationGet200ResponseRowsInner[] =
        transportationData?.data.rows ?? [];
    const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

    const columns: readonly Column<TransportationGet200ResponseRowsInner>[] = [
        {
            ...SelectColumn,
            width: 70,
            maxWidth: 500,
            resizable: true,
            headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
            cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
        },
        {
            key: "scheduledAt",
            name: "예정일시",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <div>{timeUtil(formatterProps.row.scheduledAt ?? '')}</div>
                )
            }
        },
        {
            key: "partnerCode",
            name: "파트너",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <DetailLink
                        onClick={() => openModal(
                            <PartnerDetailForm partnerCode={formatterProps.row.partnerCode} />,
                            null,
                            "협력사 상세"
                        )}
                    >{formatterProps.row.partnerCode}</DetailLink>
                )
            }
        },
        {
            key: "direction",
            name: "방향",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <Badge>{t(formatterProps.row.direction ?? '')}</Badge>
                )
            }
        },
        {
            key: "status",
            name: "상태",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <Badge
                        variant="filled"
                        color={transportationStatusColor[formatterProps?.row?.status as keyof typeof transportationStatusColor]}
                    >
                        {setTransportationStatus(formatterProps?.row?.status ?? '')}
                    </Badge>
                )
            },
        },
        {
            key: "quantityOrdered",
            name: "차량번호",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <>
                        {(formatterProps.row.createdAt)}
                    </>
                )
            },
        },
        {
            key: "id",
            name: "배차",
            sortable: true,
            resizable: true,
            cellClass: css`
        justify-content: flex-end;
      `,
            formatter: (formatterProps) => {
                return (
                    <DetailLink onClick={() => {
                        openModal(
                            <TransportationDetailForm transportationID={formatterProps.row.id} />, null, ''
                        )
                    }}>
                        {setToLocaleString(formatterProps.row.id)}
                    </DetailLink>
                )
            },
        },
        {
            key: "creatorUserCode",
            name: "적재품목 갯수",
            sortable: true,
            resizable: true,
            cellClass: css`
        justify-content: flex-end;
      `,
            formatter: (formatterProps) => {
                return (
                    <>
                        {setToLocaleString(formatterProps.row.creatorUserCode)}
                    </>
                )
            },
        },
        {
            key: "options",
            name: "액션",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <OptionBox>
                        <OptionBtn
                            variant="subtle"
                            leftIcon={<IconEdit />}
                            color="yellow"
                            onClick={() => openModal(
                                <TransportationUpdateForm formatterProps={formatterProps.row} />,
                                null,
                                "배차 수정"
                            )
                            }
                        ></OptionBtn>
                        <OptionBtn
                            variant="subtle"
                            leftIcon={<IconTrash />}
                            color="red"
                            onClick={() => {
                                openModal(
                                    <ConfirmForm
                                        message="정말로 삭제하시겠습니까?"
                                        yesCallback={() =>
                                            deleteMutate({ transportationId: formatterProps?.row?.id as number })
                                        }
                                        noCallback={() => { closeModal(null) }}
                                    />,
                                    null,
                                    "배차 삭제"
                                )
                            }}
                        ></OptionBtn>
                    </OptionBox>
                );
            },
        },
    ];

    useSub("deleteSelectedItems", () => {
        const rows = Array.from(selectedRowsRef.current);
        if (!rows.length) return;
        const deleteRows: number[] = Array.from(rows[rows.length - 1].values());
        openModal(
            <ConfirmForm
                message="선택한 행을 삭제하시겠습니까?"
                yesCallback={() =>
                    deleteMutate({ transportationId: deleteRows[deleteRows.length - 1] })
                }
                noCallback={() => { closeModal(null) }}
            />,
            null,
            "배차 삭제"
        )
    });

    return (
        <DispatchingTableWrapper>
            <GridWrapper isDarkMode={isDarkMode}>
                <TableWrapper>
                    <RetrieveWrapper>
                        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
                            <CustomFilter filterType={purchaseOrderItemsHeader} setQuery={setQuery} query={query} />
                            <CustomSorter sorterType={purchaseOrderItemsHeader} setSort={setSort} sort={sort} />
                        </Flex>
                        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
                    </RetrieveWrapper>
                    <DataGrid
                        columns={columns ?? []}
                        rows={rows as any}
                        rowHeight={40}
                        rowKeyGetter={(row: AuthSignupPost201ResponseEquipmentPurchaseOrderItem) => row.id}
                        sortColumns={sortColumns}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={(e) => {
                            setSelectedRows(e);
                            const newSelectedRows = new Set(selectedRowsRef.current);
                            newSelectedRows.add(e);
                            selectedRowsRef.current = newSelectedRows;
                            setSelectedRowsInform(selectedRowsInform);
                        }}
                    />
                </TableWrapper>
                <PaginationWrapper>
                    <Pagination
                        onChange={(page) => {
                            setSelectedRows(new Set());
                            setSelectedRowsInform([])
                            setPage(page)
                        }}
                        value={activePage}
                        total={transportationData?.data?.totalPages ?? 0}
                        size="lg"
                        radius="sm"
                    />
                </PaginationWrapper>
            </GridWrapper>
        </DispatchingTableWrapper>
    );
};

const DispatchingTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
        props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
        props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;
const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;