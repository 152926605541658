import React from 'react'
import ReactApexChart from 'react-apexcharts'

import { FrequentlyDateTypeAndDate } from '@/features/PMS/frequently/Frequently'
import styled from '@emotion/styled'
import dayjs from 'dayjs'

interface Props {
  data: any
  all?: boolean
  updateDate?: (date: Record<string, string>) => void
}

interface SeriesProps {
  x: string,
  y: Array<number>,
  fillColor: string,
  time: string,
  elapsed: string
}

interface chartProps {
  series: SeriesProps[]
  rangeMin: number,
  rangeMax: number
}

// height: 448,
const Container = styled.div<any>`

  width: 100%;
  padding-top: 20;

  & .apexcharts-yaxis-label:nth-child(1) > tspan {
    font-size : 12.5px; 
    fill : #D2FF3E !important;
    font-weight: bold;
  }

  & .apexcharts-yaxis-label:nth-child(2) > tspan {
    font-size : 12.5px; 
    fill : #DCECC9 !important;
    font-weight: bold;
  }

  & .apexcharts-yaxis-label:nth-child(3) > tspan {
    font-size : 12.5px; 
    fill : #19B9DF !important;
    font-weight: bold;
  }

  & .apexcharts-yaxis-label:nth-child(4) > tspan {
    font-size : 12.5px; 
    fill : #7D30CB !important;
    font-weight: bold;
  }

  & .apexcharts-yaxis-label:nth-child(5) > tspan {
    font-size : 12.5px; 
    fill : #2C786C !important;
    font-weight: bold;
  }

`

const DatePickerContainer = styled.div<any>(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  right: 0,
  paddingBottom: 32,
  paddingRight: 67,
  paddingTop: 10
}))


const INITIAL_DATE = new Date()
INITIAL_DATE.setDate(INITIAL_DATE.getDate() - 1)

const workTimeColor = ['#D2FF3E', '#DCECC9', '#19B9DF', '#7D30CB', '#2C786C']

const WorkTimeChart: React.FunctionComponent<Props> = ({ data, updateDate }) => {

  const [date, setDate] = React.useState<FrequentlyDateTypeAndDate>({
    type: 'day',
    date: `${dayjs(INITIAL_DATE).format('YYYY-MM-DD')}`,
    tableDate: ''
  })

  const [chartData, setChartData] = React.useState<chartProps>({

    series: [],
    rangeMin: 0,
    rangeMax: 0

  })

  const [options, setOptions] = React.useState<any>({

    series: [],
    options: {
      chart: {
        height: 100,
        type: 'rangeBar'
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '30%'
        }
      },
      fill: {
        type: 'solid',
      },
      grid: {
        borderColor: 'rgba(53, 59, 72, .46)',
        columns: {
          colors: ['#f3f4f5', '#fff'],
          opacity: 1
        },
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          top: 24
        }
      },
    }
  })

  const applyFillColor = (data: any) => {

    const result = data.series.map((v: any, i: any) => {

      let color;
      if (v.x === '기계 가동 시간') {
        color = workTimeColor[0]
      } else if (v.x === '모터 가동 시간') {
        color = workTimeColor[1]
      } else if (v.x === '작업시간') {
        color = workTimeColor[2]
      } else if (v.x === '비작업시간') {
        color = workTimeColor[3]
      } else if (v.x === 'QDC') {
        color = workTimeColor[4]
      }

      return { ...v, fillColor: color }

    })

    setChartData({ ...data, series: result })

  }


  React.useEffect(() => {

    // tooltip은 리랜더링 되어도 처음 실행값으로 동작
    if (chartData.series[0]?.fillColor) {
      setOptions({
        ...options,
        series: [
          {
            data: chartData.series
          },
        ],
        options: {
          ...options.options,
          xaxis: {
            type: 'datetime',
            min: chartData.rangeMin,
            max: chartData.rangeMax,
            labels: {
              format: 'HH:mm:ss',
              datetimeUTC: false
            }
          },
          title: {
            text: '(시간 : 시)',
            align: 'right',
            offsetX: 10,
            offsetY: 453,
            floating: true,
            style: {
              fontSize: '12px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: 'white'
            },
          },
          tooltip: {
            custom: function ({ dataPointIndex }: any) {

              return (
                `<div class="arrow_box">
                    <div class="arrow_box_text_grid">
                      <div class="arrow_box_container"> 
                        <div><span class="arrow_box_title" style="color: ${chartData.series[dataPointIndex].fillColor};">${chartData.series[dataPointIndex].x}</span></div> 
                      </div>
                      <div class="arrow_box_container"> 
                          <div><span class="arrow_box_title" style="color: #FFFFF;">시간:</span></div> 
                          <div><span class="arrow_box_title">${chartData.series[dataPointIndex].time}</span></div>
                      </div>
                      <div class="arrow_box_container">
                          <div><span class="arrow_box_title" style="color: #FFFFF;">경과 시간:</span></div>
                          <div><span class="arrow_box_title">${chartData.series[dataPointIndex].elapsed}</span></div>
                      </div>
                    </div>  
                  </div>`
              )
            }
          }
        }
      })
    } else {
      setOptions({
        ...options,
        series: [
          {
            data: chartData.series.length === 0 && [
              {
                x: '전원 ON 시간',
                y: [0, 0]
              },
              {
                x: '모터 가동 시간',
                y: [0, 0]
              },
              {
                x: '작업 시간',
                y: [0, 0]
              },
              {
                x: '비작업시간',
                y: [0, 0]
              },
              {
                x: 'QDC',
                y: [0, 0]
              }
            ]
          },
        ],
        options: {
          ...options.options,
          xaxis: {
            type: 'datetime',
            min: chartData.rangeMin,
            max: chartData.rangeMax,
            labels: {
              format: 'HH:mm:ss',
              datetimeUTC: false
            }
          },
          title: {
            text: '(시간 : 시)',
            align: 'right',
            offsetX: 10,
            offsetY: 453,
            floating: true,
            style: {
              fontSize: '12px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: 'white'
            },
          },
        }
      })
    }




  }, [chartData])


  React.useEffect(() => {

    if (data?.series) {
      if (data?.series.length > 0) {
        applyFillColor(data)
      } else {
        setChartData(data)
      }
    }

  }, [data])

  const onChangeDate = React.useCallback((selectedDate: FrequentlyDateTypeAndDate) => {

    setDate({
      type: selectedDate.type,
      date: dayjs(selectedDate.date).format('YYYY-MM-DD'),
      tableDate: date.tableDate
    })

    updateDate && updateDate({
      from: dayjs(selectedDate.date).format('YYYY-MM-DD'),
      to: dayjs(selectedDate.date).format('YYYY-MM-DD')
    })
  }, [date, updateDate])



  return (
    <Container>
      <DatePickerContainer>
        {/* <FrequentlyDatePicker type={date.type} 
                              onChange={onChangeDate}
                              selectDate={date.date}
                              viewType={'custom'}
                              placeholder={'날짜 선택'}
                              buttons={[]}
                              calendarContainers={{
                                top: 15,
                                right: 55
                              }}/> */}
      </DatePickerContainer>
      <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center', position: 'relative' }}>
        <ReactApexChart options={options.options} series={options.series} type="rangeBar" height={470} width={1000} />
      </div>
    </Container>
  )
}



export default WorkTimeChart
