import styled from "@emotion/styled";
import { Flex, Table, Text, Title } from "@mantine/core";
import { ItemsOneStepCreatePostRequestRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { RoutingBomRow } from "./RoutingBomRow";

interface RoutingBomListProps {
  formData: ItemsOneStepCreatePostRequestRoutingsInner[] | undefined
}

export const RoutingBomList = ({ formData }: RoutingBomListProps) => {
  return (
    <Flex w="100%" p="md" direction="column" gap="xs">
      <Title order={2}>
        라우팅
      </Title>
      <Text fz="xs">
        품목을 생산하기 위한 라우팅과 라우팅에 필요한 BOM들을 보여줍니다.
      </Text>
      <Table withBorder withColumnBorders>
        <thead>
          <tr>
            <Th width={5}>&nbsp;</Th>
            <Th width={5}>시퀀스</Th>
            <Th width={30}>공정명</Th>
            <Th width={30}>라우팅코드</Th>
            <Th width={10}>라우팅타입</Th>
            <Th width={10}>소모 시점</Th>
          </tr>
        </thead>
        <tbody>
          {
            formData &&
            formData.map((data: ItemsOneStepCreatePostRequestRoutingsInner, i: number) => {
              return (
                <RoutingBomRow bomRow={data} key={data.code ?? "" + i} rowIndex={i} />
              );
            })
          }
          {/* {rows &&
              rows.map(
                (
                  data: ItemsGet200ResponseRowsInnerRoutingsInner,
                  i: number
                ) => {
                  return (
                    data && (
                      <RoutingRow data={data} key={data.id}>
                        {data?.routingBoms?.length &&
                          Object.keys(data.routingBoms[0]).length && (
                            <RoutingBomRow
                              routingCode={data.code}
                              routingBoms={data.routingBoms ?? []}
                            />
                          )}
                      </RoutingRow>
                    )
                  );
                }
              )} */}
        </tbody>
      </Table>
    </Flex>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
`;