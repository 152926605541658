import { useQuery } from "@tanstack/react-query";

import DefaultInstance, { MasterInstance } from "@/instance/axios";
import {
  AuthSignupPost201ResponseEquipment,
  EquipmentsGet200Response,
} from "@sizlcorp/sizl-api-document/dist/models";

export type EquipmentOption = {
  value: string;
  label: string;
  equipment: AuthSignupPost201ResponseEquipment;
};

export const useEquipmentsAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: EquipmentOption[]) => void;
  }
) => {
  return useQuery(
    ["equipments-get-autocomplete-keyword", keyword],
    () =>
      MasterInstance.equipmentsFindPost({
        search: keyword,
        searchFields: ["name", "code"],
      }).then((res) => res.data),
    {
      enabled: focus,
      select: (data: AuthSignupPost201ResponseEquipment[]): EquipmentOption[] =>
        data.map((equipment) => {
          return {
            value: equipment.code,
            label: equipment.name as string,
            equipment: equipment as AuthSignupPost201ResponseEquipment
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};

const getEquipmentByCode = (code: string | null) =>
  DefaultInstance.equipmentsGet({
    ...(code && { search: code }),
    searchFields: ["code"],
  }).then((res) => res.data);

export const useEquipmentsCode = (
  enabled: boolean = false,
  code: string | null,
  options?: {
    onSuccess: (data?: EquipmentOption[]) => void;
  }
) => {
  return useQuery(
    ["equipments-get-code", code],
    () => getEquipmentByCode(code),
    {
      enabled,
      select: (data: EquipmentsGet200Response) =>
        data?.rows?.map((equipment) => {
          return {
            value: equipment.code as string,
            label: equipment.name as string,
            equipment: equipment as AuthSignupPost201ResponseEquipment
          };
        }),
      onSuccess: options?.onSuccess,
    }
  );
};
