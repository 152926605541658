import { ColumnHeaderProps } from "@/constants/columnHeader";
import { useQueryString } from "@/features/standardLayout/useQueryString";
import { useResetQueryStrings } from "@/features/standardLayout/useResetQueryStrings";
import { useQuery } from "@tanstack/react-query";
import { Dayjs } from "dayjs";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

export type QueryType = Record<string, any>;

// Context에서 사용할 상태 타입 정의
interface StandardLayoutContextProps<TData> {
    search: string;
    setSearch: (value: string) => void;
    searchFields: string[];
    setSearchFields: (fields: string[]) => void;
    page: number;
    setPage: (value: number) => void;
    pageSize: number;
    setPageSize: (value: number) => void;
    query: QueryType[];
    setQuery: Dispatch<SetStateAction<QueryType[]>>; // (query: QueryType[]) => void;
    sort: string[];
    setSort: Dispatch<SetStateAction<string[]>>; // (sort: string[]) => void;
    startDate: Dayjs | null;
    setStartDate: (date: Dayjs | null) => void;
    endDate: Dayjs | null;
    setEndDate: (date: Dayjs | null) => void;
    data: TData | undefined;
    isLoading: boolean;
    error: unknown;
    refetch: () => void;
    searchFieldsHeader: ColumnHeaderProps[];
    resetQueryStrings: () => void;
    selectedRows: Set<any>;
    setSelectedRows: Dispatch<SetStateAction<Set<any>>>;
    populate: string[] | undefined
}

// Context 생성
const StandardLayoutContext = createContext<StandardLayoutContextProps<any> | undefined>(undefined);

interface ProviderProps {
    children: ReactNode;
    queryKeyFactory: (params: any) => any;
    searchFieldsHeader: ColumnHeaderProps[];
    populate: string[] | undefined
}

export const StandardLayoutContextProvider = <TData,>({ children, queryKeyFactory, searchFieldsHeader, populate }: ProviderProps) => {
    // QueryString을 통한 상태 관리
    const [search, setSearch] = useQueryString<string>("search", "");
    const [searchFields, setSearchFields] = useQueryString<string[]>("searchFields", []);
    const [page, setPage] = useQueryString<number>("page", 1);
    const [pageSize, setPageSize] = useQueryString<number>("pageSize", 10);
    const [query, setQuery] = useQueryString<QueryType[]>("query", []);
    const [sort, setSort] = useQueryString<string[]>("sort", []);
    const [startDate, setStartDate] = useQueryString<Dayjs | null>("startDate", null);
    const [endDate, setEndDate] = useQueryString<Dayjs | null>("endDate", null);
    const [selectedRows, setSelectedRows] = useState(
        (): Set<any> => new Set()
    );

    const resetQueryStrings = useResetQueryStrings({
        setSearch,
        setSearchFields,
        setPage,
        setPageSize,
        setQuery,
        setSort,
        setStartDate,
        setEndDate
    })

    // queryKeyFactory를 통한 queryKey 생성 및 options 생성
    const queryKey = queryKeyFactory({
        query, //: JSON.stringify(query),
        search,
        searchFields: searchFields.length ? searchFields : searchFieldsHeader.filter(
            (item) => item.category === "text" && !item.isEnum
        ).map((item) => item.value),
        page,
        pageSize: Number(pageSize),
        ...(sort.length ? { sort: sort.join(",") } : {}),
        populate: populate,
    });

    // useQuery를 통해 데이터를 가져옴
    const { data, isLoading, error, refetch } = useQuery<TData>(queryKey);

    // Context에 제공할 값 정의
    const value: StandardLayoutContextProps<TData> = {
        search,
        setSearch,
        searchFields,
        setSearchFields,
        page,
        setPage,
        pageSize,
        setPageSize,
        query,
        setQuery,
        sort,
        setSort,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        data,
        isLoading,
        error,
        refetch,
        searchFieldsHeader,
        resetQueryStrings,
        selectedRows,
        setSelectedRows,
        populate
    };

    return (
        <StandardLayoutContext.Provider value={value}>
            {children}
        </StandardLayoutContext.Provider>
    );
};

// Context의 값을 쉽게 사용할 수 있는 커스텀 훅
export const useStandardLayout = <TData,>(): StandardLayoutContextProps<TData> => {
    const context = useContext(StandardLayoutContext);
    if (!context) {
        throw new Error("useStandardLayout must be used within a StandardLayoutProvider");
    }
    return context as StandardLayoutContextProps<TData>;
};
