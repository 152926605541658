import { Flex, MultiSelect, MultiSelectProps, Text } from "@mantine/core";
import { forwardRef, useState } from "react";

import { itemType } from "@/constants/items";
import { useTranslation } from "react-i18next";

interface ItemTypeMultiProps extends React.ComponentPropsWithoutRef<"div"> {
    label: string;
    value: string;
}

export interface ItemTypesMultiAutoCompleteProps
    extends Partial<MultiSelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
    value: string[];
    onChange?: (e: string[]) => void; // (value: string[]) => React.Dispatch<React.SetStateAction<string>>;
    maxDropdownHeight?: number;
    width?: string;
}

export const ItemTypesMultiAutoComplete = (
    params: ItemTypesMultiAutoCompleteProps
) => {
    const {
        value,
        onChange,
        maxDropdownHeight,
        width,
        ...etcParams
    } = params;

    const { t } = useTranslation();
    const translatedItems = itemType.map(item => ({ ...item, label: t(item.label) }));

    const [searchKeyword, setSearchKeyword] = useState<string>("");

    const onChangeHandler = (e: string[]) => {
        onChange && onChange(e);
    };

    const SelectItemType = forwardRef<HTMLDivElement, ItemTypeMultiProps>(
        ({ label: name, value: code, ...others }, ref) => {
            const { t } = useTranslation();
            return (
                <div ref={ref} {...others}>
                    <Flex direction="row" justify="space-between" align="center">
                        <Flex align={"center"}>
                            <Text>{t(name)}</Text>
                            <Text fz="xs">(품목유형: {code})</Text>
                        </Flex>
                    </Flex>
                </div>
            );
        }
    );

    return (
        <MultiSelect
            clearable
            data={translatedItems}
            onChange={onChangeHandler}
            searchable
            itemComponent={SelectItemType}
            searchValue={searchKeyword}
            withinPortal
            maxDropdownHeight={maxDropdownHeight ?? 150}
            onSearchChange={setSearchKeyword}
            nothingFound="No Options"
            {...etcParams}
        />
    );
};


