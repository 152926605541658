import styled from "@emotion/styled";

interface FormButtonBoxProps {
  children: React.ReactNode;
}

export const FormButtonBox = ({ children }: FormButtonBoxProps) => {
  return <ButtonBox>{children}</ButtonBox>;
};

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
