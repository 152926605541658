import { useModal } from "@/features/modal/ModalStackManager";
import { Box, Button, Flex, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

export const OrderMailFrom = () => {

    const { closeModal } = useModal();

    const form = useForm({
        initialValues: {
            sender: '',
            receiver: '',
            title: '',
            content: '',
        },
        validate: {
            sender: (value) => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? null : '이메일 형식이 맞지 않습니다.'),
            receiver: (value) => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? null : '이메일 형식이 맞지 않습니다'),
        },
    })

    const handleSubmit = (values: { sender: string; receiver: string }) => {
        // 이메일 전송 로직
        console.log('Form values:', values);
    };

    return (
        <Box>
            <Flex
                direction="column"
                gap="md"
                w="40rem"
                h="auto"
            >
                <TextInput
                    label="보내는 사람"
                    placeholder="보내는 사람"
                    withAsterisk
                    type="email"
                    {...form.getInputProps("sender")}
                />
                <TextInput
                    label="받는 사람"
                    placeholder="받는 사람"
                    withAsterisk
                    type="email"
                    {...form.getInputProps("receiver")}
                />
                <TextInput
                    label="제목"
                    placeholder="제목"
                    withAsterisk
                    {...form.getInputProps("title")}
                />
                <Textarea
                    label="내용"
                    placeholder="내용"
                    withAsterisk
                    {...form.getInputProps("content")}
                    autosize
                    minRows={10}
                />
                <Button
                    onClick={() => {
                        form.validate();
                        handleSubmit(form.values);
                        // closeModal(form.values);
                    }}
                >전송</Button>
            </Flex>
        </Box>
    )
}