import { AbstractItemsMultiAutoComplete } from "@/features/ui/multiAutoComplete/abstractItem/abstractItem-multiAutoComplete";
import { EquipmentsMultiAutoComplete } from "@/features/ui/multiAutoComplete/equipment/equipment-multiAutoComplete";
import { ItemsMultiAutoComplete } from "@/features/ui/multiAutoComplete/item/item-multiAutoComplete";
import { ItemCategoriesMultiAutoComplete } from "@/features/ui/multiAutoComplete/itemCategory/itemCategory-multiAutoComplete";
import { ItemTypesMultiAutoComplete } from "@/features/ui/multiAutoComplete/itemType/itemType-multiAutoComplete";
import { LocationsMultiAutoComplete } from "@/features/ui/multiAutoComplete/location/location-multiAutoComplete";
import { MoldsMultiAutoComplete } from "@/features/ui/multiAutoComplete/mold/mold-multiAutoComplete";
import { OperationsMultiAutoComplete } from "@/features/ui/multiAutoComplete/operation/operation-multiAutoComplete";
import { RoutingsMultiAutoComplete } from "@/features/ui/multiAutoComplete/routing/routing-multiAutoComplete";
import { RoutingTypesMultiAutoComplete } from "@/features/ui/multiAutoComplete/routingType/routingType-multiAutoComplete";
import { SitesMultiAutoComplete } from "@/features/ui/multiAutoComplete/site/site-multiAutoComplete";
import { UsersMultiAutoComplete } from "@/features/ui/multiAutoComplete/user/user-multiAutoComplete";
import { SetStateAction } from "react";

interface MultiAutoCompleteSelectorProps {
    multiFieldName: string;
    selectedMultiField: string[];
    setSelectedMultiField: (value: SetStateAction<string[]>) => void
}

export const MultiAutoCompleteSelector = ({ multiFieldName, selectedMultiField, setSelectedMultiField }: MultiAutoCompleteSelectorProps) => {

    const renderComponent = (fieldName: string) => {
        switch (fieldName) {
            // 다른 필드에 대한 컴포넌트를 조건부로 렌더링할 경우, 여기에 추가
            case 'abstractItemCode':
                return (
                    <AbstractItemsMultiAutoComplete
                        value={selectedMultiField}
                        onChange={(e) => setSelectedMultiField(e)}
                    />
                );
            case 'equipmentCode':
                return (
                    <EquipmentsMultiAutoComplete
                        value={selectedMultiField}
                        onChange={(e) => setSelectedMultiField(e)}
                    />
                );
            case 'itemCode':
                return (
                    <ItemsMultiAutoComplete
                        value={selectedMultiField}
                        onChange={(e) => setSelectedMultiField(e)}
                    />
                );
            case 'itemCategoryName':
                return (
                    <ItemCategoriesMultiAutoComplete
                        value={selectedMultiField}
                        onChange={(e) => setSelectedMultiField(e)}
                    />
                );
            case 'locationCode':
            case 'fromLocationCode':
            case 'toLocationCode':
                return (
                    <LocationsMultiAutoComplete
                        value={selectedMultiField}
                        onChange={(e) => setSelectedMultiField(e)}
                    />
                );
            case 'moldCode':
                return (
                    <MoldsMultiAutoComplete
                        value={selectedMultiField}
                        onChange={(e) => setSelectedMultiField(e)}
                    />
                );
            case 'routingCode':
                return (
                    <RoutingsMultiAutoComplete
                        value={selectedMultiField}
                        onChange={(e) => setSelectedMultiField(e)}
                    />
                );
            case 'operationCode':
                return (
                    <OperationsMultiAutoComplete
                        value={selectedMultiField}
                        onChange={(e) => setSelectedMultiField(e)}
                    />
                );
            case 'siteCode':
                return (
                    <SitesMultiAutoComplete
                        value={selectedMultiField}
                        onChange={(e) => setSelectedMultiField(e)}
                    />
                );
            case 'userCode':
                return (
                    <UsersMultiAutoComplete
                        value={selectedMultiField}
                        onChange={(e) => setSelectedMultiField(e)}
                    />
                );
            case 'itemType':
                return (
                    <ItemTypesMultiAutoComplete
                        value={selectedMultiField}
                        onChange={(e) => setSelectedMultiField(e)}
                    />
                );
            case 'routingType':
                return (
                    <RoutingTypesMultiAutoComplete
                        value={selectedMultiField}
                        onChange={(e) => setSelectedMultiField(e)}
                    />
                );
            default:
                return null;
        }
    };

    return renderComponent(multiFieldName);
}