import { useModal } from "@/features/modal/ModalStackManager";
import { Calendar } from "@/features/ui/Calendar";
import { ClutchAndBrakeSetting } from "@/features/ui/PMS/clutchAndBrake/ClutchAndBrakeSetting";
import { MachineSearchBox } from "@/features/ui/PMS/machineSearchBox";
import CustomTable from "@/features/ui/table/CustomTable";
import { ActionIcon, Button, Flex, Text } from "@mantine/core";
import { IconCalendar, IconReportSearch } from "@tabler/icons-react";
import { useState } from "react";

interface ClutchProps {
    spm: number
    slipAngle: number
    overRun: number
    date: string | Date
}

export const PressClutchAndBrakePage = () => {
    const [prevClutch, setPrevClutch] = useState<ClutchProps>({
        spm: 0,
        slipAngle: 0,
        overRun: 0,
        date: ''
    })
    const [nextClutch, setNextClutch] = useState<ClutchProps>({
        spm: 0,
        slipAngle: 0,
        overRun: 0,
        date: ''
    })

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { openModal } = useModal();

    const Header = {
        equipment: ['기계 제조사', '기계 이름', '제조 연월일', '제조 번호'],
        clutch: ['평균 SPM', '평균 밀림각', '오버런 발생 횟수', '날짜']
    }

    const BodyData = {
        equipment: [{ '기계 제조사': '제조사1', '기계 이름': '이름1', '제조 연월일': '2022-01-01', '제조 번호': '1234' }],
        clutch: [{ '평균 SPM': '1', '평균 밀림각': '1', '오버런 발생 횟수': '1', '날짜': '2022-01-01' }]
    }

    return (
        <>
            <Flex
                gap='md'
                direction='column'
            >
                <Flex justify='space-between'>
                    <Text size="xl">클러치 & 브레이크</Text>
                    <Button color="gray"
                        onClick={() => {
                            openModal(<ClutchAndBrakeSetting />, null, '클러치 & 브레이크(밀림각 세팅)')
                        }}
                    >밀림각 세팅</Button>
                </Flex>
                <Flex justify="right">
                    <ActionIcon
                        onClick={() => {
                            openModal(<MachineSearchBox />, null, '기계 검색')
                        }}
                    >
                        <IconReportSearch />
                    </ActionIcon>
                </Flex>
                <CustomTable
                    headers={Header.equipment}
                    data={BodyData.equipment}
                />
                <Flex justify='right'>
                    <Button
                        leftIcon={<IconCalendar />}
                        color="lime"
                        onClick={() => {
                            openModal(<Calendar dates={[null, null]} type="default" />, null, '날짜 선택')
                        }}
                    >날짜선택</Button>
                </Flex>
                <CustomTable
                    headers={Header.clutch}
                    data={BodyData.clutch}
                />
                <Button
                    style={isOpen ? { display: 'none' } : undefined}
                    onClick={() => setIsOpen(true)}
                >비교하기</Button>
                {
                    isOpen && (
                        <Flex gap='md' direction='column'>
                            <Flex justify='right' gap="sm">
                                <Button
                                    onClick={() => setIsOpen(false)}
                                    color="gray"
                                >비교취소</Button>
                                <Button
                                    leftIcon={<IconCalendar />}
                                    color="lime"
                                    onClick={() => {
                                        openModal(<Calendar dates={[null, null]} type="default" />, null, '날짜 선택')
                                    }}
                                >날짜선택</Button>
                            </Flex>
                            <CustomTable
                                headers={Header.clutch}
                                data={BodyData.clutch}
                            />
                        </Flex>
                    )
                }
            </Flex>
        </>
    )
}