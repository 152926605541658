import { items } from "@/api/items/useItemQuery";
import { KPI } from "@/api/kpi/useKPIQuery";
import { columnlist } from "@/constants/columnlist";
import { useModal } from "@/features/modal/ModalStackManager";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { GridTable } from "@/features/ui/PMS/GridTable";
import CustomTable from "@/features/ui/table/CustomTable";
import { DatetimeUtilMinute, getEndOfDate, getStartOfDate } from "@/utils/dateTimeUtil";
import timeUtil from "@/utils/timeUtil";
import { ActionIcon, Flex, Space, Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerItemTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const KPIManhour = Object.assign({}, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    CustomTable: CustomTable,
})

export const KPIManhourPage = () => {
    const today = dayjs().subtract(1, 'day').startOf('day').toDate();
    const oneWeekAgo = dayjs().subtract(7, 'day').startOf('day').toDate();

    const [content, setContent] = useState<any>([]);
    const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
    const [item, setItem] = useState<string | null>('');
    const [averageManHourCost, setAverageManHourCost] = useState<string>('');
    const [selectList, setSelectList] = useState<Set<number>>(new Set());
    const { openModal } = useModal();

    const { data } = useQuery({
        ...items.get({
            query: {
                $and: [
                    { code: item }
                ]
            }
        }),
        enabled: !!item
    })

    const itemData = data?.data?.rows && data?.data?.rows[0]

    const { data: manhourData } = useQuery({
        ...KPI.manhour({
            itemCode: item ?? '',
            scheduledAtFrom: getStartOfDate(stateDate[0]),
            scheduledAtTo: getEndOfDate(stateDate[1])
        }),
        enabled: !!item
    })

    useEffect(() => {
        if (manhourData?.data) {
            const transformedData = manhourData.data.map((item: any) => ({
                id: item.id,
                productionPlanId: item.productionPlanId,
                scheduledAt: item.scheduledAt,
                operationName: item.operationName,
                equipmentName: item.equipmentName,
                itemCode: item.itemCode,
                itemSpec: item.itemSpec,
                workerName: item.workerName,
                workStartTime: DatetimeUtilMinute(item.workStartTime),
                workEndTime: DatetimeUtilMinute(item.workEndTime),
                pauseDuration: item.pauseDuration,
                totalQuantity: `${item.totalQuantity} ${itemData?.unitText}`,
                goodQuantity: `${item.goodQuantity} ${itemData?.unitText}`,
                defectQuantity: `${item.defectQuantity} ${itemData?.unitText}`,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                manHourCost: item.manHourCost,
            }));

            const totalManHourCost = manhourData?.data?.reduce((sum: number, item: any) => sum + parseFloat(item.manHourCost), 0) ?? 0;
            const averageManHourCost = (totalManHourCost / manhourData.data.length).toFixed(2);
            setAverageManHourCost(averageManHourCost);
            setContent(transformedData);
        } else {
            setContent([]);
        }
    }, [manhourData])

    const TableHeader = {
        machine: ['품목코드', '품명', '규격', '평균 작업공수 (시간)'],
    }

    const BodyData = {
        machine: [{ '품목코드': itemData?.code ?? '-', '품명': itemData?.name, '규격': itemData?.spec ?? '-', '평균 작업공수 (시간)': averageManHourCost === "NaN" ? '-' : averageManHourCost }],
    }

    return (
        <Flex gap="md" direction={'column'}>
            <KPIManhour.Header>
                <KPIManhour.HeaderTitle>작업공수(C)</KPIManhour.HeaderTitle>
                <KPIManhour.HeaderSubTitle>작업지시에 대해 작업시작부터 작업종료까지 걸리는 시간 (단위: 시간)</KPIManhour.HeaderSubTitle>
            </KPIManhour.Header>
            <Flex justify={'space-between'} align={'center'}>
                <Space miw={'22rem'} />
                <Flex align={'center'}>
                    <Flex align="center" gap="sm" onClick={() => {
                        openModal(<Calendar dates={stateDate} type="range" isExclude={true} />, null, '날짜 선택')
                            .then((value) => {
                                if (isCalendarDate(value)) {
                                    setStateDate(value);
                                }
                            });
                    }}>
                        <Text size="2rem" fw="bold" style={{ cursor: "pointer" }}>
                            {timeUtil(stateDate[0]?.toISOString() ?? '')} ~ {timeUtil(stateDate[1]?.toISOString() ?? '')}
                        </Text>
                        <ActionIcon>
                            <IconCalendar size="2rem" />
                        </ActionIcon>
                    </Flex>
                </Flex>
                <ItemAutoComplete
                    placeholder="품목을 선택해주세요"
                    w="22rem"
                    size="lg"
                    getItemType={[ItemsGet200ResponseRowsInnerItemTypeEnum.PRODUCT, ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT]}
                    onChange={(value) => setItem(value)}
                />
            </Flex>

            <KPIManhour.CustomTable
                headers={TableHeader.machine}
                data={BodyData.machine}
            />
            <GridTable
                headerList={[
                    ...columnlist.manhourTable
                ]}
                row={content}
                setRow={setContent}
                selectList={selectList}
                //@ts-ignore
                setSelectList={setSelectList}
                width={'100%'}
                height={750}
                resizable={true}
            // scrollEnd={(value) => {
            //     if(value){
            //         if(keyword !== undefined){
            //             if(searchPage.total_page > searchPage.current_page){
            //                 getPressDailyStatusSearchApi(keyword, searchPage.current_page+ 1)
            //             }
            //         }else{
            //             if(page.total_page > page.current_page){
            //                 getPressDailyStatusApi(page.current_page+ 1)
            //             }
            //         }
            //     }
            // }}
            />
        </Flex>
    )
}