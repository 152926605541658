import { mutateConsumeSubsidiary } from "@/api/consumeSubsidiary/useConsumeSubsidiary";
import { inventories } from "@/api/inventories/useInventoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { InventoriesAutoComplete } from "@/features/ui/autoComplete/inventoryLot/inventory-autoComplete";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";

import { Button, Flex, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ItemsGet200ResponseRowsInnerItemTypeEnum, ProductionActionApiWorksWorkIdConsumeSubsidiaryPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery } from "@tanstack/react-query";

const PigmentInput = Object.assign({}, FormMain, {
    Button: Button,
    Input: TextInput,
    Wrapper: FormWrapper,
    ItemAutoComplete: ItemAutoComplete,
    LocationAutoComplete: LocationAutoComplete,
    InventoriesAutoComplete: InventoriesAutoComplete
});

interface PigmentInputFormProps {
    workId: number | undefined;
}

export const PigmentInputForm = ({ workId }: PigmentInputFormProps) => {

    const { closeModal } = useModal();

    const { mutate: subsidiaryMutate } = useMutation(
        (params: ProductionActionApiWorksWorkIdConsumeSubsidiaryPutRequest) =>
            mutateConsumeSubsidiary.consumeSubsidiary(params).mutationFn(params as ProductionActionApiWorksWorkIdConsumeSubsidiaryPutRequest | any),
    )

    const formData = useForm({
        initialValues: {
            itemCode: '',
            lotId: '',
            locationId: '',
            value: ''
        }
    });

    const { data: inventoriesData } = useQuery({
        ...inventories.find({
            query: {
                $and: [{
                    "itemCode": formData.values.itemCode
                        ? { "$eq": formData.values.itemCode } : undefined
                }]
            },
            populate: ["lot", "item", 'location'],
        }), enabled: !!formData.values.itemCode
    });

    const locationData = inventoriesData?.data?.map((inventory) => (
        { value: inventory.locationCode, label: inventory.locationName }
    ))

    const uniqueLocationData = locationData?.filter((location, index, self) => (
        index === self.findIndex((t) => (t.value === location.value))
    ))

    const mutateRequest = (warnInsufficiency: boolean, formData: any, workId: number | undefined) => {
        subsidiaryMutate({
            workId: workId as number,
            worksWorkIdConsumeSubsidiaryPutRequest: {
                lotId: Number(formData.values.lotId),
                quantity: formData.values.value,
                sourceLocationCode: formData.values.locationId,
                warnInsufficiency
            }
        }, {
            onSuccess: () => {
                closeModal({});
                customAlert(
                    "안료 투입에 성공하였습니다.",
                    "투입 성공",
                    "green"
                )
            },
            onError: (error: any) => {
                const isOk = window.confirm(`${error.response.data.message} 그럼에도 투입 하시겠습니까?`);
                if (isOk) {
                    mutateRequest(false, formData, workId);
                }
            }
        });
    };

    const onSave = () => {
        mutateRequest(true, formData, workId);
    };


    return (
        <PigmentInput.Wrapper>
            <Flex direction={'column'} gap={30}>
                <PigmentInput.ItemAutoComplete
                    label="품목"
                    getItemType={[ItemsGet200ResponseRowsInnerItemTypeEnum.MATERIAL, ItemsGet200ResponseRowsInnerItemTypeEnum.SUBSIDIARY]}
                    withAsterisk
                    {...formData.getInputProps(`itemCode`)}
                    onChange={(value) => {
                        if (value === null) formData.reset();
                        else formData.setFieldValue('itemCode', value);
                    }}
                />
                <PigmentInput.InventoriesAutoComplete
                    label="로트명"
                    disabled={!formData.values.itemCode}
                    itemCodes={formData.values.itemCode}
                    {...formData.getInputProps(`lotId`)}
                />
                <PigmentInput.LocationAutoComplete
                    label="출고로케이션코드"
                    withAsterisk
                    data={uniqueLocationData as any}
                    disabled={!formData.values.itemCode}
                    {...formData.getInputProps(`locationId`)}
                />
                <PigmentInput.Input
                    label="수량"
                    withAsterisk
                    disabled={!formData.values.itemCode}
                    type="number"
                    {...formData.getInputProps(`value`)}
                />
                <Flex justify={'flex-end'} >
                    <PigmentInput.Button
                        disabled={!formData.values.itemCode || !formData.values.locationId || !formData.values.value || !formData.values.lotId}
                        onClick={onSave}
                    >
                        저장
                    </PigmentInput.Button>
                </Flex>
            </Flex>

        </PigmentInput.Wrapper>
    );
};