import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { SiteDetailForm } from "@/features/site/components/form/SiteDetailForm";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Checkbox, Space } from "@mantine/core";
import { AuthSignupPost201ResponseEquipment } from "@sizlcorp/sizl-api-document/dist/models";

const DetailEquipment = Object.assign(DetailHeader, {
    Title: DetailTitle,
    Inform: DetailInformTitle,
    Field: DetailField,
    Label: DetailLabel,
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
    Content: DetailContent,
    CheckBox: Checkbox,
});


export interface DetailEquipmentInformProps {
    data: AuthSignupPost201ResponseEquipment | any;
}

export const EquipmentDetailInForm = (params: DetailEquipmentInformProps) => {
    const { data } = params;
    const equipment = data?.data?.rows[0];
    const { openModal } = useModal();

    return (
        <>
            <DetailEquipment>
                <DetailEquipment.Title>{`[설비 상세]`}</DetailEquipment.Title>
                <Space h={8} />
                <DetailEquipment.Field>
                    <DetailEquipment.Label>
                        {"설비코드"}
                    </DetailEquipment.Label>
                    <DetailEquipment.Content>
                        {equipment?.code}
                    </DetailEquipment.Content>
                </DetailEquipment.Field>
                <DetailEquipment.Field>
                    <DetailEquipment.Label>
                        {"설비명"}
                    </DetailEquipment.Label>
                    <DetailEquipment.Content>
                        {equipment?.name}
                    </DetailEquipment.Content>
                </DetailEquipment.Field>
                <DetailEquipment.Field>
                    <DetailEquipment.Label>
                        {"설비유형"}
                    </DetailEquipment.Label>
                    <DetailEquipment.Content>
                        {equipment?.equipmentType}
                    </DetailEquipment.Content>
                </DetailEquipment.Field>
                <DetailEquipment.Field>
                    <DetailEquipment.Label>
                        {"설비전압"}
                    </DetailEquipment.Label>
                    <DetailEquipment.Content>
                        {equipment?.volt}
                    </DetailEquipment.Content>
                </DetailEquipment.Field>
                <DetailEquipment.Field>
                    <DetailEquipment.Label>
                        {"사업장코드"}
                    </DetailEquipment.Label>
                    <DetailEquipment.Link
                        onClick={() => openModal(<SiteDetailForm siteCode={equipment?.siteCode} />,
                            null,
                            "")}
                    >
                        {equipment?.siteCode}
                    </DetailEquipment.Link>
                </DetailEquipment.Field>
                <DetailEquipment.Field>
                    <DetailEquipment.Label>
                        {"시퀀스 (순서)"}
                    </DetailEquipment.Label>
                    <DetailEquipment.Content>
                        {equipment?.seq}
                    </DetailEquipment.Content>
                </DetailEquipment.Field>
                <DetailEquipment.Field>
                    <DetailEquipment.Label>
                        {"입고 로케이션코드"}
                    </DetailEquipment.Label>
                    <DetailEquipment.Link
                        onClick={() => openModal(<LocationDetailForm locationCode={equipment?.toLocationCode} />,
                            null,
                            "")}
                    >
                        {equipment?.toLocationCode}
                    </DetailEquipment.Link>
                </DetailEquipment.Field>
                <DetailEquipment.Field>
                    <DetailEquipment.Label>
                        {"입고 로케이션이름"}
                    </DetailEquipment.Label>
                    <DetailEquipment.Content>
                        {equipment?.toLocationName}
                    </DetailEquipment.Content>
                </DetailEquipment.Field>
                <DetailEquipment.Field>
                    <DetailEquipment.Label>
                        {"품목 생산 로케이션 코드"}
                    </DetailEquipment.Label>
                    <DetailEquipment.Link
                        onClick={() => openModal(<LocationDetailForm locationCode={equipment?.targetLocationCode} />,
                            null,
                            "")}
                    >
                        {equipment?.targetLocationCode}
                    </DetailEquipment.Link>
                </DetailEquipment.Field>
                <DetailEquipment.Field>
                    <DetailEquipment.Label>
                        {"품목 생산 로케이션 이름"}
                    </DetailEquipment.Label>
                    <DetailEquipment.Content>
                        {equipment?.targetLocationName}
                    </DetailEquipment.Content>
                </DetailEquipment.Field>
            </DetailEquipment>
        </>
    )
};
