import styled from "@emotion/styled";
import { Alert } from "@mantine/core";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

interface CustomAlertProps {
  message: string;
  color: string;
  title: string;
  onConfirm: () => void;
}

const CustomAlert = ({ message, color, title, onConfirm }: CustomAlertProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleConfirm = () => {
    setShowModal(false);
    setTimeout(() => onConfirm(), 1);
  };

  useEffect(() => {
    setShowModal(true);
    // 3초 후 알림창 자동 닫기
    const timer = setTimeout(() => {
      setShowModal(false);
      setTimeout(() => onConfirm(), 1);
    }, 3000);

    return () => clearTimeout(timer); // 컴포넌트가 언마운트될 때 타이머 정리
  }, []);

  return (
    <>
      <AlertModal.Container showModal={showModal} onClick={handleConfirm}>
        <AlertModal.Content>
          <Alert withCloseButton w={400} title={title} color={color} style={{ border: '2px solid #000' }}>
            {message}
          </Alert>
        </AlertModal.Content>
      </AlertModal.Container>
    </>
  );
};

const customAlert = (message: string, title: string, color: string) => {
  const handleConfirm = () => {
    const modalRoot = document.getElementById("modal-alert-portal-wrapper");
    if (modalRoot) modalRoot.remove();
  };
  if (typeof window !== "undefined") {
    const subDiv = document.createElement("div");
    subDiv.id = "modal-alert-portal-wrapper";
    document.body.appendChild(subDiv);

    const root = createRoot(subDiv);
    root.render(
      <CustomAlert
        title={title}
        color={color}
        message={message}
        onConfirm={handleConfirm}
      />
    );
  }
};

export default customAlert;

const AlertModal = {
  Container: styled.div<{ showModal: boolean }>`
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    display: ${({ showModal }) => (showModal ? "flex" : "none")}; /* showModal이 false일 때 숨기기 */
    flex-direction: column;
    align-items: center;
    opacity: ${({ showModal }) => (showModal ? 1 : 0)};
    pointer-events: ${({ showModal }) => (showModal ? "auto" : "none")};
    transition: opacity 0.1s ease-in-out;
    cursor: default;
    z-index: 9999;
  `,

  Content: styled.div`
    margin-top: 20px;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
    width: 100%;
    height: 7rem;
    position: relative;
  `,

  Message: styled.div`
    font-size: 1.2rem;
  `,
};