import { MenuTemplate } from "@/features/ui/Menu";
import { useLogout } from "@/hooks";
import {
  ActionIcon,
  Button,
  Flex,
  Group,
  Header,
  Title,
  useMantineColorScheme
} from "@mantine/core";
import { IconMoonStars, IconSun } from "@tabler/icons-react";


export function HeaderMenu() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";
  const logout = useLogout();

  return (
    <Header height="3.5rem">
      <Flex w="100%" h="100%" px="0.625rem" justify="space-between" align="center" gap="xs">
        <Title order={3}>SIZL MESv3</Title>

        <Group spacing="md">
          <MenuTemplate />
          <Button onClick={logout} variant="subtle" color="gray">
            Logout
          </Button>
          <ActionIcon
            variant="outline"
            color={dark ? "yellow" : "blue"}
            onClick={() => toggleColorScheme()}
            title="Toggle color scheme"
          >
            {dark ? (
              <IconSun size="1.1rem" />
            ) : (
              <IconMoonStars size="1.1rem" />
            )}
          </ActionIcon>
        </Group>
        {/* <Burger opened={opened} onClick={toggle} size="sm" /> */}
      </Flex>
    </Header>
  );
}
