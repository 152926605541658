import { WorkInputForm } from "@/features/inventory/components/Form/WorkInputForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { WorkUpdateForm } from "@/features/workByManagement/WorkUpdateForm";
import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit } from "@tabler/icons-react";
import { WorkContentProps } from "./WorkContent";

export const ActionBar = ({ workData }: WorkContentProps) => {
  const { openModal, closeModal } = useModal();
  return (
    <ActionBarContainer>
      <LeftActionBar>
        <Button>실적 입력</Button>
        <Button variant="outline" onClick={() => {
          openModal(
            <WorkInputForm<'plan'>
              workRowData={workData as ProductionPlansGet200ResponseRowsInnerWorksInner}
            />,
            null,
            "원/부자재 투입",
            true
          );
        }}>원/부자재 투입</Button>
      </LeftActionBar>

      <RightActionBar>
        <Button rightIcon={<IconEdit />} color="orange" onClick={() => {
          openModal(
            <WorkUpdateForm<'plan'>
              workRowData={[workData as ProductionPlansGet200ResponseRowsInnerWorksInner]}
            />,
            null,
            "작업 수정",
            true
          )
        }}>
          수정
        </Button>
      </RightActionBar>
    </ActionBarContainer>
  );
};

export const ActionBarContainer = styled.div`
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  background: inherit;
`;

export const LeftActionBar = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const RightActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
`;
