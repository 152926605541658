import { setERPOutsourcingDeleteRecord } from "@/api/works/useWorkRecordQuery";
import { customFunctions } from "@/config/customFunction";
import { useCheckboxContext } from "@/features/workByManagement/context/CheckboxProvider";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Button, Checkbox, Table, TextInput } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerAllLotsSummaryInner, WorksItemGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useModal } from "../modal/ModalStackManager";
import { OperationOutsourceWorkByRowView } from "../operationOutsourceWorkByRow/components/OperationOutsourceWorkByRowView";
import { useWorkActions } from "./hooks/useWorkActions";
import { useWorkState } from "./hooks/useWorkState";
import { OperationOutsourceNotFound } from "./WorkNotFound";
import { Td } from "./WorkTableRow";

interface WorkOperationTableRowInRowProps {
    rowData: WorksItemGet200ResponseRowsInner;
    rowIndex: number
}

const useableErpCancelButton = customFunctions.ADD_OUTSOURCING_WORK_ERP_CANCEL_BUTTON;

export const WorkOperationTableRowInRow = ({ rowData, rowIndex }: WorkOperationTableRowInRowProps) => {

    const allLotsSummary = (rowData as WorksItemGet200ResponseRowsInner).allLotsSummary;
    const { openModal } = useModal();
    const state = useWorkState();
    const actions = useWorkActions();

    const { checkedStates, setCheckedState } = useCheckboxContext();

    const [erpCancelWorkLogIds, setErpCancelWorkLogIds] = useState<{ erpSync: string | undefined }>({
        erpSync: ''
    })


    const queryClient = useQueryClient();

    useEffect(() => {
        if (erpCancelWorkLogIds.erpSync) {
            handleErpOutsourcingCancel();
        }
    }, [erpCancelWorkLogIds]);

    // 외주 실적 취소하기
    const handleErpOutsourcingCancel = async () => {

        // response 받아서 처리
        if (erpCancelWorkLogIds) {

            const result = await setERPOutsourcingDeleteRecord({
                erpSyncPushCancelSingleOutsourcingRecordPostRequest: {
                    erpSync: erpCancelWorkLogIds.erpSync
                }
            });

            setErpCancelWorkLogIds({
                erpSync: ''
            })
            const { data } = result;
            queryClient.invalidateQueries(['workPage']);

        }
    }

    return (
        <WorkRowTr>
            <Td colSpan={9}>
                <Table withColumnBorders withBorder>
                    <thead>
                        <WorkRowTr>
                            <th>연동</th>
                            <th>로트이름</th>
                            <th>양품수량</th>
                            <th>불량수량</th>
                            <th>작업자</th>
                            <th>작업날짜</th>
                            <th>연동여부</th>
                            {
                                useableErpCancelButton && <th>연동취소</th>
                            }
                        </WorkRowTr>
                    </thead>
                    <tbody>
                        {allLotsSummary && allLotsSummary.length ? allLotsSummary && allLotsSummary?.map((lot, index) => (
                            <WorkRowTr
                                key={index}
                                onClick={() => openModal(<OperationOutsourceWorkByRowView data={(lot as ProductionPlansGet200ResponseRowsInnerWorksInnerAllLotsSummaryInner)?.inputInfo} />, null, '')}
                                style={{ cursor: "pointer" }}
                            >
                                <Td minWidth={3} width={2} onClick={(e) => e.stopPropagation()}>
                                    <Checkbox
                                        onChange={(e) => {
                                            if (e.target.checked) {

                                                setCheckedState(rowData.id, lot, rowIndex, index, e.target.checked)

                                                actions.setErpWorkData(prev => ({
                                                    ...prev,
                                                    startWorkLogId: [
                                                        ...(prev.startWorkLogId || []),
                                                        (lot as ProductionPlansGet200ResponseRowsInnerWorksInnerAllLotsSummaryInner)?.startWorkLogId ?? 0
                                                    ].filter(id => id !== undefined) as number[],
                                                    endWorkLogId: [
                                                        ...(prev.endWorkLogId || []),
                                                        (lot as ProductionPlansGet200ResponseRowsInnerWorksInnerAllLotsSummaryInner)?.endWorkLogId ?? 0
                                                    ].filter(id => id !== undefined) as number[],
                                                    workIds: [
                                                        ...(prev.workIds || []),
                                                        (rowData as WorksItemGet200ResponseRowsInner)?.id ?? 0
                                                    ].filter(id => id !== undefined) as number[],
                                                    OSPDelvDate: [
                                                        ...(prev.OSPDelvDate || []),
                                                        (lot as ProductionPlansGet200ResponseRowsInnerWorksInnerAllLotsSummaryInner)?.OSPDelvDate ?? ''
                                                    ].filter(date => date !== undefined)
                                                }));
                                            } else {

                                                setCheckedState(rowData.id, lot, rowIndex, index, e.target.checked)

                                                actions.setErpWorkData(prev => ({
                                                    ...prev,
                                                    startWorkLogId: (prev.startWorkLogId || []).filter((_, i) => i !== index),
                                                    endWorkLogId: (prev.endWorkLogId || []).filter((_, i) => i !== index),
                                                    workIds: (prev.workIds || []).filter((_, i) => i !== index),
                                                    OSPDelvDate: (prev.OSPDelvDate || []).filter((_, i) => i !== index)
                                                }));
                                            }
                                        }}
                                        checked={!!checkedStates[rowIndex]?.[index]?.isChecked}
                                        disabled={!!lot?.erpSync}
                                        ml="4px"
                                    />
                                </Td>
                                <Td minWidth={8} width={16}>{lot?.LotNo}</Td>
                                <Td minWidth={8} width={12} style={{ textAlign: 'right' }}>{lot?.DelvQty}</Td>
                                <Td minWidth={8} width={12} style={{ textAlign: 'right' }}>{lot?.badQty}</Td>
                                <Td minWidth={8} width={12}>{lot?.EmpName}</Td>
                                <Td minWidth={8} width={14}>
                                    <TextInput
                                        onClick={(e) => e.stopPropagation()}
                                        defaultValue={lot?.OSPDelvDate ?? state.erpWorkData.OSPDelvDate?.[index]}
                                        onChange={(e) => {
                                            const newValue = e.currentTarget.value;
                                            actions.setErpWorkData((prev) => {
                                                const updatedOSPDelvDate = [...(prev.OSPDelvDate || [])];
                                                const rowIndex = prev.workIds?.findIndex(id => id === (rowData as WorksItemGet200ResponseRowsInner)?.id);
                                                if (rowIndex !== -1) {
                                                    updatedOSPDelvDate[rowIndex ?? 0] = newValue !== undefined && newValue !== '' ? newValue : '';
                                                }
                                                return { ...prev, OSPDelvDate: updatedOSPDelvDate };
                                            });
                                        }}
                                    />
                                </Td>
                                <Td minWidth={8} width={14}>
                                    {lot?.erpSync ? '연동완료' : ''}
                                </Td>
                                {
                                    useableErpCancelButton &&
                                    <Td minWidth={8} width={14}>
                                        <Button
                                            w="100%"
                                            color="orange.7"
                                            disabled={!lot?.erpSync}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setErpCancelWorkLogIds({
                                                    erpSync: lot?.erpSync
                                                });
                                            }}
                                        >취소하기</Button>
                                    </Td>
                                }
                            </WorkRowTr>
                        )) : <OperationOutsourceNotFound />}
                    </tbody>
                </Table>
            </Td>
        </WorkRowTr>
    )
}

// WorkRow 영역
const WorkRowTr = styled.tr`
    .mantine-1r8slib>thead>tr>th {
        background-color: ${theme.colors?.gray?.[0]};
        color: ${theme.colors?.gray?.[9]};
        font-weight: bold;
        text-align: center;
        padding: 0.5rem;
    }
    td {
       background-color: ${(props) => props.color};
    }
`