import { sites } from "@/api/sites/useSitesQuery";
import { SiteDetailHeader } from "@/features/site/components/header/SiteDetailHeader";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useQuery } from "@tanstack/react-query";

const Detail = Object.assign(Main, {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
})

interface DetailSiteFormProps {
    siteCode: string | undefined;
}

export const SiteDetailForm = (params: DetailSiteFormProps) => {
    const { siteCode } = params;

    const { data: siteData } = useQuery(sites.get({
        query: { $and: [{ "code": { "$eq": siteCode } }] },
    }));

    return (
        <>
            <Detail.Container>
                <Detail.Wrapper>
                    <Detail.Content>
                        <SiteDetailHeader data={siteData} />
                    </Detail.Content>
                </Detail.Wrapper>
            </Detail.Container>
        </>
    )
};