import styled from "@emotion/styled";

interface ParentsBoxTextDivProps {
    children: React.ReactNode;
    flex?: number;
}

export const ParentsBoxTextDiv = ({ children, flex }: ParentsBoxTextDivProps) => {
    return (
        <Content style={{ flex: flex }}>{children}</Content>
    )
}
const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
`