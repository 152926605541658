import { useModal } from "@/features/modal/ModalStackManager"
import { ActionButtonBox } from "@/features/standard/ActionButtonBox"
import { ActionHeader } from "@/features/standard/ActionHeader"
import { Header } from "@/features/standard/Header"
import { Main } from "@/features/standard/Main"
import { HeaderSubTitle } from "@/features/standard/SubTitle"
import { HeaderTitle } from "@/features/standard/Title"
import { Wrapper } from "@/features/standard/Wrapper"
import { TransportationCreateForm } from "@/features/transportation/form/createForm"
import { TransportationStatementForm } from "@/features/transportation/form/statementForm"
import { TransportationStatusForm } from "@/features/transportation/form/statusForm"
import { TransportationTable } from "@/features/transportation/table"
import { usePub } from "@/hooks"
import { Button } from "@mantine/core"
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models"
import { IconPlus, IconPrinter, IconRefresh, IconTrash } from "@tabler/icons-react"
import { useState } from "react"

const Transportation = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    Wrapper: Wrapper,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    ActionButtonBox: ActionButtonBox,
    TransportationTable: TransportationTable,
})


export const TransportationPage = () => {
    const publish = usePub();
    const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
    const [selectedRowsInform, setSelectedRowsInform] = useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]>([]);
    const [data, setData] = useState<any>([]);
    const { openModal } = useModal();
    
    const handleRowSelect = (
        selectedRows: any,
        formatterPropsData: any,
    ) => {
        setIsRowBtnActive(selectedRows.size === 1);
        setData(formatterPropsData);
        setSelectedRowsInform(selectedRowsInform);
    }

    return (
        <>
            <Transportation.Wrapper>
                <Transportation.ActionHeader>
                    <Transportation.ActionButtonBox>
                        <Transportation.ActionButton
                            leftIcon={<IconPlus />}
                            onClick={()=> openModal(
                                <TransportationCreateForm />,
                                null,
                                "배차 생성"
                            )}
                        >
                            추가
                        </Transportation.ActionButton>
                        <Transportation.ActionButton
                            leftIcon={<IconRefresh />}
                            color="orange"
                            disabled={!isRowBtnActive}
                            onClick={() => openModal(
                                <TransportationStatusForm formatterProps={data} />,
                                null,
                                "배차 상태 변경"
                            )}
                        >
                            상태변경
                        </Transportation.ActionButton>
                        <Transportation.ActionButton
                            leftIcon={<IconPrinter />}
                            color="orange"
                            disabled={!isRowBtnActive || data?.direction !== "OUTBOUND"}
                            onClick={() => openModal(
                                <TransportationStatementForm data={data} />,
                                null,
                                ""
                            )}
                        >
                            입고명세서 출력
                        </Transportation.ActionButton>
                        <Transportation.ActionButton
                            leftIcon={<IconPrinter />}
                            color="green"
                            disabled={!isRowBtnActive || data?.direction !== "INBOUND"}
                            onClick={() => openModal(
                                <TransportationStatementForm data={data} />,
                                null,
                                ""
                            )}
                        >
                            출고명세서 출력
                        </Transportation.ActionButton>
                    </Transportation.ActionButtonBox>
                    <Transportation.ActionButton
                        color="red"
                        rightIcon={<IconTrash />}
                        onClick={() => publish("deleteSelectedItems")}
                    >
                        선택된 행 삭제
                    </Transportation.ActionButton>
                </Transportation.ActionHeader>
                <Transportation.Header>
                    <Transportation.HeaderTitle>배차</Transportation.HeaderTitle>
                    <Transportation.HeaderSubTitle>
                        입/출고하는 차량 리스트
                    </Transportation.HeaderSubTitle>
                </Transportation.Header>
                <Transportation.TransportationTable onRowSelect={handleRowSelect} />
            </Transportation.Wrapper>
        </>
    )
}