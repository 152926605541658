import styled from "@emotion/styled";

interface WorkBoxProps {
    children: React.ReactNode;
};

export const WorkBox = ({ children }: WorkBoxProps) => {
    return (
        <Content>{children}</Content>
    )
}

const Content = styled.div`
    width : 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.5rem;
`