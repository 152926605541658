import { ChartLogic } from "@/features/PMS/output/service/chartLogic";
import styled from "@emotion/styled";
import { Flex, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export type SeriesType = {
    name: string;
    data: number[];
}

export type ChartDataType = {
    series: SeriesType[];
    options: any;
}

export const AnalysisTotalOutputPage = () => {
    const [dataByTime, setDataByTime] = useState<ChartDataType>({ series: [], options: {} });
    const [dataByMachine, setDataByMachine] = useState<ChartDataType>({ series: [], options: {} });
    const [totalCount, setTotalCount] = useState<number>(0);
    const [data, setData] = useState<any>();

    const chartLogic = new ChartLogic();

    useEffect(() => {
        if (data) {
            setTotalCount(data?.totalCount);
            setDataByTime(
                chartLogic.setChartLogic({
                    series: data?.groupByMachines.filter((machines : any) => (!!machines.counts.length)).map((machine : any) => {
                        return { name: machine.machineName, data: machine.counts };
                    }),
                    xaxis: data ? data.times : [],
                    options: {
                        title: {
                            text: '시간별 총 생산량',
                            style: {
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#ffffff'
                            }
                        }
                    },
                })
            );
            setDataByMachine(
                chartLogic.setChartLogic({
                    series: data ? [{ name: "Total", data: data?.groupByMachines.map((machine : any) => machine.totalCountByMachine) }] : [],
                    xaxis: data ? data.groupByMachines.map((machine : any) => machine.machineName) : [],
                    options: {
                        tooltip: {
                            enabled: true,
                            style: {
                                fontSize: '12px',
                                fontFamily: undefined,
                            },
                            x: {
                                show: false,
                            },
                            y: {
                                title: {
                                    formatter: function (value : any, { series, seriesIndex, dataPointIndex, w } : any) {
                                        return `${data?.groupByMachines[dataPointIndex].machineName} : `;
                                    }
                                }
                            },
                        },
                        plotOptions: {
                            bar: {
                                barHeight: '100%',
                                distributed: true,
                                horizontal: true,
                                dataLabels: {
                                    position: 'bottom'
                                },
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            textAnchor: 'start',
                            formatter: function (val : any, opt : any) {
                                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
                            },
                        },
                        yaxis: {
                            labels: {
                                show: false
                            }
                        },
                        title: {
                            text: '기계별 총 생산량',
                            offsetX: 15,
                            offsetY: 10,
                            style: {
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#ffffff'
                            }
                        },
                    }
                })
            );
        }
    }, [data]);

    return (
        <>
            <Flex
                gap={'md'}
                direction={'column'}
            >
                <Text size="xl">총 생산량</Text>
                <ReactApexChart options={dataByTime.options} series={dataByTime.series} type="bar" height={350} />
                <ApexChartContainer>
                    <ReactApexChart options={dataByMachine.options} series={dataByMachine.series} type="bar" height={400} />
                    <TotalDiv>총 생산량 : {totalCount}</TotalDiv>
                </ApexChartContainer>
            </Flex>
        </>
    );
}

const ApexChartContainer = styled.div`
    position: relative;
    width: 100%;
    background: black;
    .apexcharts-tooltip {
        color: black;
    }
`;

const TotalDiv = styled.div`
    position: absolute;
    top: 14px;
    right: 40px;
    font-weight: bold;
`;
