import styled from "@emotion/styled";

interface ParentsBoxProps {
    children: React.ReactNode;
}

export const ParentsBox = ({ children }: ParentsBoxProps) => {
    return <Content>{children}</Content>
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    background-color: gainsboro;
    padding: 8px;
    width : 44rem;
    height : 20rem;
    border-radius : 4px;
`