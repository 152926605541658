import { useModal } from "@/features/modal/ModalStackManager";
import WorkTimeChart from "@/features/ui/PMS/chart/workTimeChart";
import { MachineSearchBox } from "@/features/ui/PMS/machineSearchBox";
import { SettingBox } from "@/features/ui/PMS/settingBox";
import CustomTable from "@/features/ui/table/CustomTable";
import { ActionIcon, Button, Flex, Grid, Text } from "@mantine/core";
import { IconReportSearch } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useCallback, useState } from "react";

export const AnalysisWorkTimePage = () => {
    const INITIAL_DATE = new Date()
    INITIAL_DATE.setDate(INITIAL_DATE.getDate() - 1)

    const [selected, setSelected] = useState<any>({
        machine: undefined,
        material: undefined,
        date: {
            from: dayjs(INITIAL_DATE).format('YYYY-MM-DD'),
            to: dayjs(INITIAL_DATE).format('YYYY-MM-DD')
        }
    })
    const [chart, setChart] = useState({
        series: [],
        rangeMin: 0,
        rangeMax: 0
    })

    const { openModal } = useModal();

    const updateDate = useCallback((date: Record<string, string>) => {
        setSelected({
            ...selected,
            date
        })
    }, [selected])

    const header = {
        machine: ['기계 제조사', '기계 이름', '제조 연월일', '제조 번호'],
        item : ['모델', 'CODE', '품명', 'SPEC'],
        on : ['전원 ON 시간'],
        mortor : ['모터 가동 시간'],
        workTime : ['총 작업시간'],
        idleTIme : ["총 비작업시간"],
        statistice : ['데이터 통계 내용']
    }

    const BodyData = {
        machine: [{ '기계 제조사': '제조사1', '기계 이름': '이름1', '제조 연월일': '2022-01-01', '제조 번호': '1234' }],
        item : [{ '모델' : '모델1', 'CODE' : 'CODE1', '품명' : '품명1', 'SPEC' : 'SPEC1' }],
        on : [{ '전원 ON 시간' : '1 hour' }],
        mortor : [{ '모터 가동 시간' : '1 hour' }],
        workTime : [{ '총 작업시간' : '1 hour' }],
        idleTIme : [{ '총 비작업시간' : '1 hour' }],
        statistice : [{'데이터 통계 내용' : '-'}]
    }

    return (
        <>
            <Flex
                gap='md'
                direction='column'
            >
                <Flex justify='space-between'>
                    <Text size="xl">작업시간</Text>
                    <Button
                        onClick={() => {
                            openModal(<SettingBox initValue={selected.machine} />, null, '작업 시간 설정')
                        }}
                    >설정</Button>
                </Flex>
                <Flex justify='right'>
                    <ActionIcon
                        onClick={() => {
                            openModal(<MachineSearchBox />, null, '기계 검색')
                        }}
                    >
                        <IconReportSearch />
                    </ActionIcon>
                </Flex>
                <CustomTable 
                    headers={header.machine}
                    data={BodyData.machine}
                />
                <Flex gap="md" w="100%">
                    <CustomTable 
                        headers={header.item}
                        data={BodyData.item}
                        width={'25%'}
                    />
                    <WorkTimeChart data={chart} all={selected.material === 0} updateDate={updateDate} />
                </Flex>
                <Flex gap="md">
                    <Grid>
                        <Grid.Col span={6}>
                            <CustomTable 
                                headers={header.on}
                                data={BodyData.on}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <CustomTable 
                                headers={header.mortor}
                                data={BodyData.mortor}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <CustomTable 
                                headers={header.workTime}
                                data={BodyData.workTime}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <CustomTable 
                                headers={header.idleTIme}
                                data={BodyData.idleTIme}
                            />
                        </Grid.Col>
                    </Grid>
                    <CustomTable 
                        headers={header.statistice}
                        data={BodyData.statistice}
                    />
                </Flex>
            </Flex>
        </>
    )
}