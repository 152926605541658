import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import { Button, Checkbox, FileInput, Flex, NumberInput, Select, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Checkbox,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
});

export const PeripheralUpdateForm = (params: any) => {
    const { formatterProps } = params;
    const { closeModal } = useModal();

    const form = useForm({
        initialValues: {
            manufacturedAt: formatterProps.manufacturedAt ?? undefined,
            manufactureCompany: formatterProps.manufactureCompany ?? undefined,
            modelName: formatterProps.modelName ?? undefined,
            modelType: formatterProps.modelType ?? undefined,
            manufactureId: formatterProps.manufactureId ?? 0,
            userName: formatterProps.userName ?? undefined,
            modelPicture: formatterProps.modelPicture ?? undefined,
            specNamePlatePicture: formatterProps.specNamePlatePicture ?? undefined,
            functionNamePlatePicture: formatterProps.functionNamePlatePicture ?? undefined,
            manual: formatterProps.manual ?? undefined,
            factoryName: formatterProps.factoryName ?? undefined,
            factoryDetailName: formatterProps.factoryDetailName ?? undefined,
        }
    })

    const onSubmit = async () => {
        try {
            closeModal(form.values);

            customAlert("주변장치 수정에 실패하였습니다.", '수정 실패', 'red')
        } catch (e) {
            customAlert(
                "주변장치 수정에 실패하였습니다.",
                "수정 실패",
                "red"
            )
        }
    };

    return (
        <Create.Wrapper>
            <DateInput
                withAsterisk
                label="제조 연월일"
                placeholder="제조 연월일"
                {...form.getInputProps("manufacturedAt")}
            />
            <Create.Input
                withAsterisk
                label="장치 제조사"
                placeholder="장치 제조사"
                {...form.getInputProps("manufactureCompany")}
            />
            <Create.Input
                withAsterisk
                label="장치명"
                placeholder="장치명"
                {...form.getInputProps("modelName")}
            />
            <Select
                withAsterisk
                data={["미스피드 검출장치", "하사점 검출장치"]}
                label="장치 종류"
                placeholder="장치 종류"
                {...form.getInputProps("modelType")}
            />
            <NumberInput
                withAsterisk
                label="제조번호"
                placeholder="제조번호"
                {...form.getInputProps("manufactureId")}
            />
            <Create.Input
                withAsterisk
                label="담당자"
                placeholder="담당자"
                {...form.getInputProps("userName")}
            />
            <FileInput
                label="장치 사진"
                {...form.getInputProps("modelPicture")}
            />
            <FileInput
                label="스펙 명판 사진"
                {...form.getInputProps("specNamePlatePicture")}
            />
            <FileInput
                label="능력 명판 사진"
                {...form.getInputProps("functionNamePlatePicture")}
            />
            <FileInput
                label="사용 설명서"
                {...form.getInputProps("manual")}
            />
            <Create.Input
                withAsterisk
                label="공장명"
                placeholder="공장명"
                {...form.getInputProps("factoryName")}
            />
            <Create.Input
                withAsterisk
                label="공장 세분화명"
                placeholder="공장 세분화명"
                {...form.getInputProps("factoryDetailName")}
            />
            <Create.ButtonBox>
                <Create.Button color="gray" onClick={closeModal}>취소</Create.Button>
                <Create.Button
                    disabled={validateFormValues({
                        pageType: "peripheral",
                        values: form.values,
                    })}
                    onClick={onSubmit}
                >
                    주변장치 수정
                </Create.Button>
            </Create.ButtonBox>
        </Create.Wrapper>
    )
};