import { mutateDefect } from "@/api/defect/useDefectsQuery";
import { DefectCreateForm } from "@/features/defect/components/form/DefectCreateForm";
import { DefectDetailForm } from "@/features/defect/components/form/DefectDetailForm";
import { DefectUpdateForm } from "@/features/defect/components/form/DefectUpdateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { OperationDetailForm } from "@/features/operations/components/form/operationDetailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import { flexGrowable, flexStable, StandardLayoutTableWrapper } from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import { DefaultApiDefectsDefectCodeDeleteRequest, DefectsGet200Response, DefectsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";

export const Defects = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiDefectsDefectCodeDeleteRequest) =>
      mutateDefect.delete(params).mutationFn(params as DefaultApiDefectsDefectCodeDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          "불량 삭제에 성공하였습니다.",
          "삭제 성공",
          "green"
        );
      },
      onError: () => {
        customAlert(
          "불량 삭제에 실패하였습니다.",
          "삭제 실패",
          "red"
        );
      }
    }
  );

  const columns: readonly Column<DefectsGet200ResponseRowsInner>[] = useMemo(() => [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "code",
      name: "불량코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<DefectDetailForm defectCode={formatterProps.row.code} />, null, "")}>{formatterProps.row.code}</LinkAnchor>
        )
      },
    },
    {
      key: "name",
      name: "불량명",
      sortable: true,
      resizable: true,
    },
    {
      key: "operationCode",
      name: "공정코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<OperationDetailForm operationCode={formatterProps.row.operationCode} />, null, "")}>{formatterProps.row.operationCode}</LinkAnchor>
        )
      },
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updateDefectActionView = () => {
          openModal(
            <DefectUpdateForm formatterProps={formatterProps?.row} />,
            null,
            "설비 수정",
            true
          );
        };

        // DefectsGet200ResponseRowsInner 타입에서 defectCode를 뜻하는 code가 선택적 매개변수로 정의되어 있어, string으로 타입 캐스팅
        const deleteDefectAction = () => {
          openModal(
            <ConfirmForm
              message="선택한 행을 삭제하시겠습니까?"
              yesCallback={() => deleteMutate({ defectCode: formatterProps?.row.code as string })}
              noCallback={() => { }}
              commonCallback={() => closeModal(null)}
            />,
            null,
            "설비 삭제",
          )
        }

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon
              variant="subtle"
              onClick={updateDefectActionView}
              color="yellow"
            >
              <IconEdit />
            </ActionIcon>
            <ActionIcon
              variant="subtle"
              onClick={() => deleteDefectAction()}
              color="red"
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ], [deleteMutate, openModal, closeModal]);

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 불량을 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ defectCode: row });
          });

          selectedRows.clear();
        }
        }
        noCallback={() => { }}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "불량 삭제",
    )
  }

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader createFn={() => openModal(<DefectCreateForm />, null, "불량 추가", true)} createDataTitle="불량" deleteFn={() => handleDelete()} />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>불량 리스트</Text>
        <Text fz="xs">우리 회사에서 다루는 불량 유형에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={"사용자 정의 필터입니다. \"필터 추가\" 또는 \"정렬 추가\" 버튼을 클릭하여 조건을 입력합니다."}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<DefectsGet200Response, DefectsGet200ResponseRowsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<DefectsGet200Response> />
      </Flex>
    </Flex>
  )
};
