import { Box, Button, Flex } from "@mantine/core"
import { useState } from "react"
import SlipDropDown from "./SlipDropDown"

type DegreeType = {
    id : number
    pressCode : string
    pressName : string
    pressId : number
    normal : number
    caution :number
    isChange : boolean
}

export const ClutchAndBrakeSetting = () => {

    const [ clutchDegree , setClutchDegree ] = useState<DegreeType[]>([]) 

    const onChangeEvent = (data : any) => {

        const newClutch = clutchDegree.map((clutch) => {

            if (clutch.pressId === data.pressId) {
                return data
            } else {
                return clutch
            }
        })

        setClutchDegree(newClutch)
    }

    return (
        <Box h="auto" w="auto">
            <Flex justify='right' gap="md">
                <Button color="gray">초기화 하기</Button>
                <Button>저장하기</Button>
            </Flex>
            {/* map 돌려서 기계별로 나오게 */}
            <SlipDropDown name={'slip.pressName'} code={'slip.pressCode'} data={'clutchDegree[index]'} onChange={onChangeEvent} />
        </Box>
    )
}