import { Box, Button, Flex, Select, Table, TextInput } from "@mantine/core"

// TO BE : 기계 검색 후 검색내용 화면에 바인딩
export const MachineSearchBox = () => {
    return (
        <Box h="auto" w="auto">
            <Flex
                gap="md"
                direction={"column"}
            >
                <Flex
                    gap="md"
                >
                    {/* 검색 항목 */}
                    <Select 
                        data={['전체', '기계1', '기계2']}
                        w="8rem"
                    />
                    <TextInput 
                        placeholder="검색어를 입력하세요."
                        w="40rem"
                    />
                    {/* 검색기능 */}
                    <Button>검색</Button>
                </Flex>
                <Table
                    striped
                    withBorder
                    withColumnBorders
                >
                    <thead>
                        <tr>
                            <th>기계 제조사</th>
                            <th>기계 이름</th>
                            <th>기계 종류</th>
                            <th>용접 종류</th>
                            <th>제조 연월일</th>
                            <th>제조 번호</th>
                        </tr>
                    </thead>
                    {/* 데이터 받아오기 */}
                    <tbody>
                        <tr>
                            <td>제조사1</td>
                            <td>기계1</td>
                            <td>기계</td>
                            <td>용접</td>
                            <td>2022-01-01</td>
                            <td>1234</td>
                        </tr>
                    </tbody>
                </Table>
                <Flex
                    gap="sm"
                >
                    <Button
                        w="50%"
                        color="gray"
                    >취소</Button>
                    {/* 선택 시 데이터 들어가게 */}
                    <Button
                        w="50%"
                    >선택</Button>
                </Flex>
            </Flex>
        </Box>
    )
}