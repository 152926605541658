import React, { useEffect, useState } from 'react';
// @ts-ignore
import DataGrid from 'react-data-grid';
// import {RequestMethod} from "../../common/RequestFunctions";
import { IExcelHeaderType } from '@/features/PMS/pm_v2';
import styled from '@emotion/styled';


interface IProps {
  headerList: any
  setHeaderList?: (value: Array<IExcelHeaderType>) => void
  row: any
  width?: number | string
  maxWidth?: number | string
  rowHeight?: number
  height?: number | string
  maxHeight?: number
  editable?: boolean
  resizable?: boolean
  selectable?: boolean
  setRow: (row: Array<any>) => void
  setSelectRow?: (index: number) => void
  setSelectList?: (selectedRows: ReadonlySet<number>) => void
  selectList?: ReadonlySet<number>
  type?: 'searchModal' | 'monitoring'
  disableVirtualization?: boolean
  overflow?: "hidden"
  setActive?: (data: any) => void
  isInfiniteScroll?: boolean
  styles?: any
  scrollOnOff?: boolean
  scrollEnd?: (value: boolean) => void
}

export const GridTable = ({ headerList, setHeaderList, row, width, maxWidth, rowHeight, height, maxHeight, editable, resizable, selectable, setRow, setSelectRow, selectList, setSelectList, type, disableVirtualization, overflow, setActive, isInfiniteScroll, styles, scrollOnOff, scrollEnd }: IProps) => {

  const [selectedRows, setSelectedRows] = useState<ReadonlySet<number>>(selectList ?? new Set());
  const [isLoading, setIsLoading] = useState(false);
  // const [selectPage, setSelectPage] = useState<number>(1);
  const rowKeyGetter = (row: any) => {
    return row.id;
  }

  const scrollState = () => {
    if (overflow) {
      return "0px"
    } else {
      return "8px"
    }
  }

  let tempData: any[] = [];

  useEffect(() => {
    setSelectList && setSelectList(selectedRows)
  }, [selectedRows])

  function isAtBottom({ currentTarget }: React.UIEvent<HTMLDivElement>): boolean {
    const { scrollTop, scrollHeight, clientHeight } = currentTarget

    return scrollHeight !== clientHeight && scrollTop >= scrollHeight - clientHeight;

  }

  const EmptyRowsRenderer = () => {
    return (
      <EmptyRowsContainer>
        <span lang="kor">
          데이터가 존재 하지 않습니다.
        </span>
      </EmptyRowsContainer>
    );
  }

  const showDataGrid = () => {

    return (
      <React.Fragment>
        <HighlightClassname theme={3}>
          <DataGridTable
            headerRowHeight={40}
            rowKeyGetter={rowKeyGetter}
            className={'cell'}
            columns={headerList}
            rows={row}
            rowHeight={rowHeight ?? 40}
            //@ts-ignore
            defaultColumnOptions={{
              resizable: resizable,
              // editable: editable,
            }}
            onRowsChange={setRow}
            //@ts-ignore
            emptyRowsView={() => <div>empty</div>}
            onSelectedRows={(e: any) => {
              console.log('select', e)
            }}
            //@ts-ignore
            onSelectedRowsChange={setSelectedRows}
            selectedRows={selectedRows}
            onRowChange={(e: any) => {
              setSelectedRows(e)
            }}
            onRowClick={(i: any, r) => {
              setSelectRow && setSelectRow(i)
            }}
            style={{
              ...styles,
              border: "none",
              overflow: scrollOnOff ? "hidden" : "auto",
              width: width ?? '100%',
              maxWidth: maxWidth,
              height: height ?? 760,
              maxHeight: maxHeight,
              backgroundColor: '#00000000',
              textAlign: 'center',
            }}
            theme={scrollState}
            state={type}
            enableVirtualization={!disableVirtualization}
            //@ts-ignore
            onScroll={(e) => {
              scrollEnd && scrollEnd(isAtBottom(e))
            }}
            emptyRowsRenderer={EmptyRowsRenderer}
            rowClass={(row: any) => (
              row.needsCheck
                ? 'oneLine' :
                row.onClicked
                && 'clickLine'
            )}
          />
        </HighlightClassname>
      </React.Fragment>
    )
  }

  if (type === 'searchModal') {
    return <SearchModalStyle>
      {showDataGrid()}
    </SearchModalStyle>
  } else {
    return showDataGrid()
    // <ExcelDataStyle>
    // </ExcelDataStyle>
  }

}

const SearchModalStyle = styled.div`

.rdg{
    border:none;
    
}
.rdg-cell{
    border:none;
    margin-bottom:1px;
}

.rdg-header-row{
    background:#F4F6FA;
    color:black;
    text-align: left;
    border: 0.5px solid #b3b3b3;
    padding: 0 8px;
}

.rdg-row {
    border:none;
    background:none;
    grid-gap:1px;
    &:hover{
        background:none;
    }
    
}
.rdg-row > .rdg-cell{
    background:#F4F6FA;
    border: 0.5px solid #b3b3b3;
    color:black;
    text-align: left;
    padding: 0 8px;
    border-top: none
    // &:hover{
    //     background:red;
    // }
}

.rdg-row[aria-selected=true]{
    background: blue;
    &:hover{
        background:none;
        // background-color:none;
    }
}

.rdg-row[aria-selected=true]{
    background: blue;
}


.rdg-checkbox-input:checked + div{
    width:20px;
    height:20px;
    box-shadow:none;
    border:none;
    background-size: 20px 20px;
}
.editDropdown > option {
  background:#484848;
}
.c1wupbe700-canary49 {
  padding: 0;
}

div .cell .rdg-row {
background-color: #353B48;
}

.editCell {
background-color: #00000000;
color: white;
width: 100%;
height: 100%;
}

.editDropdown {
background-color: #00000000;
color: white;
width: 100%;
height: 100%;
}

`
const DataGridTable = styled(DataGrid)`
  ::-webkit-scrollbar{
    display:block;
    width:${(props: any) => props.theme};
    height:8px;
  }

  ::-webkit-scrollbar-thumb{
    background:#484848;

  }

  ::-webkit-scrollbar-track{
    background:none;
  }

  ::-webkit-scrollbar-corner{
    display:none;
  }

  .right-align {
    text-align: right !important;
  }
  ${(props: any) => props.state === "searchModal" ? "" : props.state === 'monitoring' ?
    `
    .rdg{
        border:none;
        
    }
    .rdg-cell{
        border-width: 1px;
        border-color: transparent;
    }

    .rdg-header-row{
        background:#202E4A;
        color:white;
        // grid-gap:1px;
        border:none;
        // grid-gap:1px;
    }
  
    .c1wupbe700-canary49{
      padding : 0 2px;
    }

    .rdg-row {
        border:none;
        background:none;
        background-color:none;
        color:white;
        // grid-gap:1px;        
    }
  `
    :

    `
    .rdg{
        border:none;
        
    }
    .rdg-cell{
        border-width: 1px;
        border-color: white;
        text-align: center;
        border : 1px solid #b3b3b3;
    }
    
    .rdg-header-row{
        background:white;
        // grid-gap:1px;
        border:none;
        // grid-gap:1px;
    }
    
    .rdg-row {
        border:none;
        background:white;
        background-color:none;
        color:white;
        // grid-gap:1px;        
    }
    .rdg-row > .rdg-cell{
        color: #353B48;
        
        &:hover{
          // opacity:50%;
          background-color: rgba(53,59,72,0.5);
          // background-color: #353B48;
        }
    }
    
    .rdg-row[aria-selected=true]{
        background:none;
        &:hover{
            background:none;
            background-color:none;
        }
    }
    
    .editDropdown > option {
      background:#484848;
    }
   
    .rdg-row[aria-selected=true]{
        background:none;
    }

    .rdg-checkbox-input:checked + div{
        width:15px;
        height:15px;
        box-shadow:none;
        border:none;
        background-size: 15px 15px;
    }
    
    .c1wupbe700-canary49 {
      padding: 0;
      
    }

    .cc79ydj700-canary49{
      width : 15px;
      height : 15px;
    }
    
  div .cell .rdg-row {
    background-color: #353B48;
  }
  
  .editCell {
    background-color: #00000000;
    color: white;
    width: 100%;
    height: 100%;
  }
  
  .editDropdown {
    background-color: #00000000;
    color: white;
    width: 100%;
    height: 100%;
  }
  .editDropdown > option {
    background:#484848;
  }
  `
  }
  
`;

const EmptyRowsContainer = styled.div<any>`

  display : flex;
  justify-content :center;
  align-items : center;
  color : white;
  margin-top : 20px

`

const HighlightClassname = styled.div`

    .oneLine {
      background: #FE9C00;
      color: white;
    }

    .oneLine > .rdg-cell {

      background: #FE9C00;
      
    }
    
    


`;

