import CustomTable from '@/features/ui/table/CustomTable'
import styled from '@emotion/styled'
import React from 'react'
import { PRESS_STATICS_READY_PRESS_INFORMATION } from '../pm_v2'




const Container = styled.div(() => ({
  display: 'flex',
  width: '100%'
}))

interface Props {
  cnc?: boolean
  data: PRESS_STATICS_READY_PRESS_INFORMATION | undefined | any
  date: string | undefined
}

const AnalysisReadyTimeTable: React.FunctionComponent<Props> = ({ cnc, data, date }) => {

  const Header = {
    detail: ['프레스 이름', '날짜', '생산량', 'UPH', '시작 시간', '종료 시간', '총 작동 시간', '가동 시간', '비 가동 시간', '전원 off'],
    subDetail: cnc ? ['대기 시간', '에러 시간'] : ['대기 시간', '금형교체 시간', '에러 시간']
  }

  const BodyData = {
    detail : [
      {'프레스 이름' : '-', '날짜' : '-', '생산량' : '-', 'UPH' : '-', '시작 시간' : '-', '종료 시간' : '-', '총 작동 시간' : '-', '가동 시간' : '-', '비 가동 시간' : '-', '전원 off' : '-'},
    ],
    subDetail : cnc ? [
      {'대기 시간' : '-', '에러 시간' : '-'},
    ] : [
      {'대기 시간' : '-', '금형교체 시간' : '-', '에러 시간' : '-'},
    ]
  }

  return (
    <Container>
      <div style={{ marginRight: 18, width: '80%' }}>
        <CustomTable 
          headers={Header.detail}
          data={BodyData.detail}
        />
      </div>
      <div style={{ width: '20%' }}>
        <CustomTable 
          headers={Header.subDetail}
          data={BodyData.subDetail}
        />
      </div>
    </Container>
  )
}

export default React.memo(AnalysisReadyTimeTable)
