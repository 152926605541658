import { mutateRoutings } from "@/api/routing/useRoutingQuery";
import { consumeTypeColor, routingTypeColor } from "@/constants/routings";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { MoldDetailForm } from "@/features/mold/form/moldDetailForm";
import { OperationDetailForm } from "@/features/operations/components/form/operationDetailForm";
import { RoutingCreateForm } from "@/features/routing/components/form/RoutingCreateForm";
import { RoutingDetailForm } from "@/features/routing/components/form/RoutingDetailForm";
import { RoutingEditForm } from "@/features/routing/components/form/RoutingUpdateForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import { flexGrowable, flexStable, StandardLayoutTableWrapper } from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { css } from "@emotion/css";
import { ActionIcon, Avatar, Badge, Flex, Text, Tooltip } from "@mantine/core";
import { DefaultApiRoutingsRoutingIdDeleteRequest, ItemsGet200ResponseRowsInnerRoutingsInner, RoutingsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconSearch, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const Routing = () => {
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const { selectedRows, refetch } = useStandardLayout();
  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiRoutingsRoutingIdDeleteRequest) =>
      mutateRoutings.delete(params).mutationFn(params as DefaultApiRoutingsRoutingIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          "라우팅 삭제에 성공하였습니다.",
          "삭제 성공",
          "green"
        );
      },
      onError: () => {
        customAlert(
          "라우팅 삭제에 실패하였습니다.",
          "삭제 실패",
          "red"
        );
      }
    }
  );

  const columns: readonly Column<ItemsGet200ResponseRowsInnerRoutingsInner>[] = useMemo(() => [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "itemName",
      name: "품목명",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <LinkAnchor onClick={() => openModal(<ItemsDetailForm itemCode={formatterProps.row.itemCode ?? ""} />, null, "품목 상세")}>{formatterProps.row.item?.name ?? '-'}</LinkAnchor>;
      },
    },
    {
      key: "itemCode",
      name: "품목코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "spec",
      name: "규격",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <Flex>{formatterProps.row.item?.spec}</Flex>;
      },
    },
    {
      key: "operationCode",
      name: "공정코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<OperationDetailForm operationCode={formatterProps.row.operationCode} />, null, "")}>{formatterProps.row.operationCode}</LinkAnchor>
        );
      },
    },
    {
      key: "code",
      name: "라우팅코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "routingType",
      name: "라우팅유형",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Badge size="lg" color={routingTypeColor[formatterProps.row?.routingType!]}>{t(formatterProps.row?.routingType!)}</Badge>
        );
      }
    },
    {
      key: "moldCode",
      name: "금형코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<MoldDetailForm moldCode={formatterProps.row.moldCode} />, null, "")}>{formatterProps.row.moldCode}</LinkAnchor>
        )
      },
    },
    {
      key: "consumeType",
      name: "소모 시점",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => <Badge size="lg" color={consumeTypeColor[formatterProps.row?.consumeType!]}>{t(formatterProps.row?.consumeType!)}</Badge>,
    },
    {
      key: "seq",
      name: "라우팅순서",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: right;
      `,
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const detailRoutingActionView = () => {
          openModal(
            <RoutingDetailForm routingCode={formatterProps.row?.code} />,
            null,
            "라우팅 상세 정보"
          );
        };

        const updateRoutingActionView = () => {
          openModal(
            <RoutingEditForm formatterProps={formatterProps?.row} />,
            null,
            "라우팅 수정",
            true
          );
        }

        const deleteRoutingAction = () => {
          openModal(
            <ConfirmForm
              message="정말로 삭제하시겠습니까?"
              yesCallback={() => { deleteMutate({ routingId: formatterProps.row?.id as number }) }}
              noCallback={() => { }}
              commonCallback={() => closeModal(null)}
            />,
            null,
            "라우팅 삭제",
          )
        }

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon
              variant="subtle"
              onClick={detailRoutingActionView}
              color="blue"
            >
              <IconSearch />
            </ActionIcon>
            <ActionIcon
              variant="subtle"
              onClick={updateRoutingActionView}
              color="yellow"
            >
              <IconEdit />
            </ActionIcon>
            <ActionIcon
              variant="subtle"
              onClick={() => deleteRoutingAction()}
              color="red"
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        )
      },
    },
  ], [t, closeModal, deleteMutate, openModal]);

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 라우팅을 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ routingId: row });
          });

          selectedRows.clear();
        }
        }
        noCallback={() => { }}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "라우팅 삭제",
    );
  }

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader createFn={() => openModal(<RoutingCreateForm />, null, "라우팅 추가", true)} createDataTitle="라우팅" deleteFn={() => handleDelete()} />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>라우팅 리스트</Text>
        <Text fz="xs">우리 회사에서 다루는 라우팅에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={"사용자 정의 필터입니다. \"필터 추가\" 또는 \"정렬 추가\" 버튼을 클릭하여 조건을 입력합니다."}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<RoutingsGet200Response, ItemsGet200ResponseRowsInnerRoutingsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<RoutingsGet200Response> />
      </Flex>
    </Flex>
  );
};
