import { useCallback, useState } from "react";

export const useDragAndDropList = (initialItems: any) => {
    const [items, setItems] = useState(initialItems);

    const onDragEnd = useCallback((result: any) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(reorderedItems);
    }, [items]);

    return {
        items,
        onDragEnd,
    };
};

const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};