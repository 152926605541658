import { MesInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { MesApiLoginLogsFindPostRequest, MesApiLoginLogsGetRequest, MesApiLoginLogsLoginLogIdGetRequest, MesApiLoginLogsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const USER_LOGS = 'userLogs';

export const userLogs = createQueryKeys(USER_LOGS, {
    all: null,
    get: (params: MesApiLoginLogsGetRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.

        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => MesInstance.loginLogsGet(query)
        }
    },
    find: (params: MesApiLoginLogsFindPostRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.

        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => MesInstance.loginLogsFindPost(query)
        }
    },
    detail: (params: MesApiLoginLogsLoginLogIdGetRequest) => {
        return {
            queryKey: [params.loginLogId],
            queryFn: () => MesInstance.loginLogsLoginLogIdGet(params)
        }
    }
});


export const mutateUserLogs = createMutationKeys(USER_LOGS, {
    create: (params: MesApiLoginLogsPostRequest) => ({
        mutationKey: [params.loginLogsGetRequest],
        mutationFn: () => MesInstance.loginLogsPost(params),
    }),

});

export const usersQueryKeys = mergeQueryKeys(userLogs);