import { useQuery } from "@tanstack/react-query";

import DefaultInstance from "@/instance/axios";
import { AbstractItemsGet200Response, AbstractItemsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";

export type AbstractItemOption = {
    value: string;
    label: string;
};

export const useAbstractItemsMultiAutoComplete = (
    focus = false,
    keyword = "",
    options?: {
        onSuccess: (data: AbstractItemOption[]) => void;
    }
) => {
    return useQuery(
        ["abstract-items-get-multi-autocomplete-keyword", keyword],
        () =>
            DefaultInstance.abstractItemsFindPost({
                search: keyword,
                searchFields: ["name"],
            }).then((res) => res.data),
        {
            enabled: focus,
            select: (
                data: AbstractItemsGet200ResponseRowsInner[]
            ): AbstractItemOption[] =>
                data.map((AbstractItem) => {
                    return {
                        value: String(AbstractItem.code),
                        label: String(AbstractItem.code),
                    };
                }) ?? [],
            onSuccess: options?.onSuccess,
        }
    );
};

const getAbstractItemByName = (name: string | null) =>
    DefaultInstance.abstractItemsGet({
        ...(name && { search: name }),
        searchFields: ["name"],
    }).then((res) => res.data);

export const useAbstractItemsMultiCode = (
    enabled: boolean = false,
    name: string | null,
    options?: {
        onSuccess: (data?: AbstractItemOption[]) => void;
    }
) => {
    return useQuery(
        ["abstract-items-get-multi-name", name],
        () => getAbstractItemByName(name),
        {
            enabled,
            select: (data: AbstractItemsGet200Response) =>
                data?.rows?.map((AbstractItem) => {
                    return {
                        value: AbstractItem.code as string,
                        label: AbstractItem.code as string,
                    };
                }),
            onSuccess: options?.onSuccess,
        }
    );
};
