import { useModal } from "@/features/modal/ModalStackManager"
import { ActionButtonBox } from "@/features/standard/ActionButtonBox"
import { ActionHeader } from "@/features/standard/ActionHeader"
import { Header } from "@/features/standard/Header"
import { Main } from "@/features/standard/Main"
import { HeaderSubTitle } from "@/features/standard/SubTitle"
import { HeaderTitle } from "@/features/standard/Title"
import { Wrapper } from "@/features/standard/Wrapper"
import { StockPlanCreateForm } from "@/features/stockPlan/form/createForm"
import { StockPlanIncomingForm } from "@/features/stockPlan/form/stockPlanIncomingForm"
import { StockPlanOutgoingForm } from "@/features/stockPlan/form/stockPlanOutgoingForm"
import { StockPlanTransportationForm } from "@/features/stockPlan/form/transportationForm"
import { StockPlanTable } from "@/features/stockPlan/table"
import { usePub } from "@/hooks"
import { Button } from "@mantine/core"
import { IconCodePlus, IconMinus, IconPlus, IconTrash } from "@tabler/icons-react"
import { useState } from "react"

const StockPlan = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    Wrapper: Wrapper,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    ActionButtonBox: ActionButtonBox,
    StockPlanTable: StockPlanTable,
})


export const StockPlanPage = () => {
    const publish = usePub();
    const { openModal } = useModal();
    const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
    const [selectedRowsInform, setSelectedRowsInform] = useState<number[]>([]);
    const [data, setData] = useState<any>([]);
    
    const handleRowSelect = (
        selectedRows: any,
        formatterPropsData: any,
    ) => {
        setIsRowBtnActive(selectedRows.size === 1);
        setData(formatterPropsData);
        setSelectedRowsInform(Array.from(selectedRows.values()));
    }


    return (
        <>
            <StockPlan.Wrapper>
                <StockPlan.ActionHeader>
                    <StockPlan.ActionButtonBox>
                        <StockPlan.ActionButton
                            leftIcon={<IconPlus />}
                            onClick={() => openModal(
                                <StockPlanCreateForm />,
                                null,
                                "재고계획 추가",
                                true
                            )}
                        >
                            추가
                        </StockPlan.ActionButton>
                        <StockPlan.ActionButton
                            leftIcon={<IconPlus />}
                            color="orange"
                            disabled={!isRowBtnActive || data.targetLocationCode === null}
                            onClick={() => openModal(
                                <StockPlanIncomingForm formatterProps={data} />,
                                null,
                                "입고",
                            )}
                        >
                            입고
                        </StockPlan.ActionButton>
                        <StockPlan.ActionButton
                            leftIcon={<IconMinus />}
                            disabled={!isRowBtnActive || data.sourceLocationCode === null}
                            color="orange"
                            onClick={() => openModal(
                                <StockPlanOutgoingForm formatterProps={data} />,
                                null,
                                "출고",
                            )}
                        >
                            출고
                        </StockPlan.ActionButton>
                        <StockPlan.ActionButton
                            leftIcon={<IconCodePlus />}
                            color="green"
                            disabled={!selectedRowsInform.length}
                            onClick={() => openModal(
                                <StockPlanTransportationForm formatterProps={data} stockPlanId={selectedRowsInform} />,
                                null,
                                "배차 추가",
                                true
                            )}
                        >
                            배차 일괄 추가
                        </StockPlan.ActionButton>
                    </StockPlan.ActionButtonBox>
                    <StockPlan.ActionButton
                        color="red"
                        rightIcon={<IconTrash />}
                        onClick={() => publish("deleteSelectedItems")}
                    >
                        선택된 행 삭제
                    </StockPlan.ActionButton>
                </StockPlan.ActionHeader>
                <StockPlan.Header>
                    <StockPlan.HeaderTitle>재고계획</StockPlan.HeaderTitle>
                    <StockPlan.HeaderSubTitle>
                        고객으로부터의 주문
                    </StockPlan.HeaderSubTitle>
                </StockPlan.Header>
                <StockPlan.StockPlanTable onRowSelect={handleRowSelect} />
            </StockPlan.Wrapper>
        </>
    )
}