import { EtcInstance } from "@/instance/axios";
import { createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { EtcApiAcLoaderListGetRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const DATA_DISPLAY_QUERY_KEY = 'dataDisplay';

export const dataDisplay = createQueryKeys(DATA_DISPLAY_QUERY_KEY, {
    all: null,
    get: (params?: EtcApiAcLoaderListGetRequest) => {
        return {
            queryKey: [params?.page, params?.pageSize],
            queryFn: () => EtcInstance.acLoaderListGet(params),
        }
    },

})


export const wmsLogQuery = mergeQueryKeys(dataDisplay);