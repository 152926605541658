import { useModal } from "@/features/modal/ModalStackManager";
import { Button } from "@mantine/core";
import { DailyRegisterModal } from "../modal";

export const DailyRegisterButton = () => {
    const { openModal } = useModal();
    
    return (
        <Button
            color="blue"
            size="sm"
            radius="md"
            variant="outline"
            w="100%"
            onClick={() => openModal(<DailyRegisterModal />, null, '일상 점검 리스트 양식 검토')}
        >
            일일 등록
        </Button>
    )
}