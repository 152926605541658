import { MasterInstance } from "@/instance/axios";
import { DepartmentsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export type DepartmentOption = {
  value: string;
  label: string;
  group: string;
};

const getAutoCompleteKeyword = () =>
  MasterInstance.departmentsFindPost().then(
    (res: AxiosResponse<DepartmentsGet200ResponseRowsInner[]>) => res.data
  );

export const useDepartmentsAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: DepartmentOption[]) => void;
  }
) => {
  return useQuery(
    ["Departments-get-autocomplete"],
    () => getAutoCompleteKeyword(),
    {
      enabled: focus,
      select: (data: DepartmentsGet200ResponseRowsInner[]) =>
        data?.map((Department) => {
          return {
            value: String(Department.code),
            label: String(Department.name),
            group: String(Department.name),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};

const getDepartmentsByCode = (code: string | null) =>
  MasterInstance.departmentsFindPost({
    ...(code && { search: code }),
    searchFields: ["code"],
  }).then((res: AxiosResponse<DepartmentsGet200ResponseRowsInner[]>) => res.data);

export const useDepartmentsCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: DepartmentOption[]) => void;
  }
) => {
  return useQuery(["Departments-get-code", code], () => getDepartmentsByCode(code), {
    enabled: enabled ?? false,
    select: (data: DepartmentsGet200ResponseRowsInner[]) =>
      data?.map((Department) => {
        return {
          value: String(Department.code),
          label: String(Department.name),
          group: String(Department.name),
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
