import { useModal } from "@/features/modal/ModalStackManager";
import { MoldStockCreateForm } from "@/features/moldStock/form/stockCreateForm";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { Calendar, CalendarDate, isCalendarDate } from "@/features/ui/Calendar";
import { WorkLogTable } from "@/features/workLog/table";
import { usePub } from "@/hooks";
import { ActionIcon, Button, Flex, Title } from "@mantine/core";
import { IconCalendar, IconFileSpreadsheet } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";

const WorkLog = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  WorkLogTable: WorkLogTable,
  MoldStockCreateForm: MoldStockCreateForm,
});

export const WorkLogPage = () => {
  const publish = usePub();
  const { openModal } = useModal();
  const [dateRange, setDateRange] = useState<CalendarDate>([dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()]);

  return (
    <>
      <WorkLog.Wrapper>
        <WorkLog.ActionHeader>
          <WorkLog.ActionButtonBox>
            <></>
          </WorkLog.ActionButtonBox>
          <WorkLog.ActionButtonBox>
            <WorkLog.ActionButton rightIcon={<IconFileSpreadsheet />}
              color="teal"
              onClick={() => {
                publish("excelDownLoad");
              }}>엑셀 다운로드
            </WorkLog.ActionButton>
          </WorkLog.ActionButtonBox>
        </WorkLog.ActionHeader>
        <Flex w="100%" p="xs" justify="center" gap="xs" align="center">
          <Title size="h1" weight={700}>
            {dayjs(dateRange[0]).format("YYYY-MM-DD")} ~ {dayjs(dateRange[1]).format("YYYY-MM-DD")}
          </Title>
          <ActionIcon onClick={() => openModal(<Calendar dates={dateRange} type="range" />, null, "날짜 선택").then((result) => { if (isCalendarDate(result)) { setDateRange(result) } })}>
            <IconCalendar size="2rem" />
          </ActionIcon>
        </Flex>
        <WorkLog.Header>
          <WorkLog.HeaderTitle>작업 일보 리스트</WorkLog.HeaderTitle>
          <WorkLog.HeaderSubTitle>
            우리회사에서 다루는 작업일보를 조회할 수 있는 페이지 입니다.
          </WorkLog.HeaderSubTitle>
        </WorkLog.Header>
        <WorkLog.WorkLogTable dates={[dayjs(dateRange[0]).startOf('day').toDate(), dayjs(dateRange[1]).endOf('day').toDate()]} />
      </WorkLog.Wrapper>
    </>
  );
};
