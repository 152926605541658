import { systemParameterHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { SystemParameterCreateForm } from "@/features/systemParameter/form/createForm";
import { SystemParameterUpdateForm } from "@/features/systemParameter/form/updateForm";
import customAlert from "@/features/ui/alert/alert";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import useQueryString from "@/hooks/useQueryString";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Checkbox, Flex, Pagination } from "@mantine/core";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import { useRef, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

const SystemWrap = {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    Wrapper: Wrapper,
    CreateForm: SystemParameterCreateForm,
    UpdateForm: SystemParameterUpdateForm,
};

export const SystemParameterPage = () => {
    const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

    const { openModal } = useModal();
    const [activePage, setPage] = useState(initialQueryString.page);
    const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
    const [sort, setSort] = useState(initialQueryString.sort);
    const [search, setSearch] = useState<SearchProps>({
        search: initialQueryString.search,
        searchFields: initialQueryString.searchFields,
        pageSize: initialQueryString.pageSize,
    });

    const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
    const [selectedRows, setSelectedRows] = useState(
        (): ReadonlySet<any> => new Set()
    );


    const searchFieldsHeader = systemParameterHeader.filter(
        (item) => item.category === "text" && !item.isEnum
    );

    const pageSize = 10;
    const totalRows = SystemParameterPageMockupData.length;

    // 페이지에 맞는 데이터 슬라이싱
    const paginatedRows = SystemParameterPageMockupData.slice(
        (activePage - 1) * pageSize,
        activePage * pageSize
    );

    const columns: readonly Column<any>[] = [
        {
            ...SelectColumn,
            width: 70,
            maxWidth: 500,
            resizable: true,
            headerCellClass: css`
              & > * {
                justify-content: flex-start;
                padding-left: 24px;
              }
            `,
            cellClass: css`
              .rdg-checkbox-label {
                padding-left: 24px;
              }
            `,
        },
        {
            key: "systemKey",
            name: "시스템 파라미터 키 (필수)",
            sortable: true,
            resizable: true,
        },
        {
            key: "systemValue",
            name: "시스템 파라미터 값 (필수)",
            sortable: true,
            resizable: true,
        },
        {
            key: "isActive",
            name: "비활성화 (필수)",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return <Checkbox checked={row.isActive} />;
            }
        },
        {
            key: "description",
            name: "비고",
            sortable: true,
            resizable: true,
        },
        {
            key: "options",
            name: "액션",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <OptionBox>
                        <OptionBtn
                            variant="subtle"
                            leftIcon={<IconEdit />}
                            color="yellow"
                            onClick={() => {
                                openModal(
                                    <SystemWrap.UpdateForm
                                        formatterProps={formatterProps.row}
                                    />,
                                    {},
                                    "시스템 파라미터 수정",
                                    true
                                );
                            }}
                        ></OptionBtn>
                        <OptionBtn
                            variant="subtle"
                            leftIcon={<IconTrash />}
                            color="red"
                            onClick={() => {
                                window.confirm("정말로 삭제하시겠습니까?") &&
                                    customAlert(
                                        "시스템 파라미터 삭제에 실패하였습니다.",
                                        "삭제 실패",
                                        "red"
                                    );
                            }}
                        ></OptionBtn>
                    </OptionBox>
                );
            },
        }
    ];

    const deleteSelectedItems = () => {
        window.confirm("정말로 삭제하시겠습니까?") &&
            customAlert(
                "선택된 행 삭제에 실패하였습니다.",
                "삭제 실패",
                "red"
            );
    }

    return (
        <SystemWrap.Wrapper>
            <SystemWrap.ActionHeader>
                <SystemWrap.ActionButton
                    leftIcon={<IconPlus />}
                    onClick={() =>
                        openModal(<SystemWrap.CreateForm />, null, "시스템 파라미터 추가", true)
                    }
                >
                    추가
                </SystemWrap.ActionButton>
                <SystemWrap.ActionButton
                    color="red"
                    rightIcon={<IconTrash />}
                    onClick={() => deleteSelectedItems()}
                >
                    선택된 행 삭제
                </SystemWrap.ActionButton>
            </SystemWrap.ActionHeader>
            <SystemWrap.Header>
                <SystemWrap.HeaderTitle>시스템 파라미터 관리</SystemWrap.HeaderTitle>
                <SystemWrap.HeaderSubTitle>
                    {"시스템의 동작에 영향을 미치는 다양한 설정값을 정의하고 관리하는 작업입니다. 이를 통해 시스템의 성능, 보안, 기능 동작 등을 세부적으로 조정할 수 있으며, 각 파라미터는 시스템 운영 환경에 맞춰 적절하게 설정되어야 합니다. 적절한 파라미터 관리는 시스템의 안정성과 효율성을 유지하는 데 필수적입니다.."}
                </SystemWrap.HeaderSubTitle>
            </SystemWrap.Header>

            <Flex w="100%" justify="space-between">
                <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
                    <CustomFilter filterType={systemParameterHeader} setQuery={setQuery} query={query} />
                    <CustomSorter sorterType={systemParameterHeader} setSort={setSort} sort={sort} />
                </Flex>
                <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
            </Flex>
            <Flex w="100%" h="100%">
                <GridWrapper>
                    <DataGrid
                        columns={columns ?? []}
                        rows={paginatedRows}
                        rowHeight={40}
                        rowKeyGetter={(row) => row.logDate}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={(e) => {
                            setSelectedRows(e);
                            const newSelectedRows = new Set(selectedRowsRef.current);
                            newSelectedRows.add(e);
                            selectedRowsRef.current = newSelectedRows;
                        }}
                    />
                    <Pagination
                        onChange={setPage}
                        value={activePage}
                        total={Math.ceil(totalRows / pageSize)}  // 전체 페이지 계산
                        size="lg"
                        radius="sm"
                        position="center"
                    />
                </GridWrapper>
            </Flex>
        </SystemWrap.Wrapper>
    )
}

const SystemParameterPageMockupData = [
    { systemKey: "PRINTER_KEY_1", systemValue: "Printer12", isActive: false, description: "" },
    { systemKey: "PRINTER_KEY_2", systemValue: "p_01", isActive: true, description: "사용자별로 프린터 매칭" },
    { systemKey: "PRINTER_KEY_3", systemValue: "ASVDAGVSD", isActive: false, description: "HH" },
    { systemKey: "PRINTER_KEY_4", systemValue: "test4printer", isActive: false, description: "" },
    { systemKey: "PRINTER_USER_1", systemValue: "Printer12User", isActive: true, description: "사용자별로 프린터 매칭" },
]

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: inherit;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
