import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { EventHandler, ReactNode, forwardRef, useState } from "react";

import { useModal } from "@/features/modal/ModalStackManager";
import styled from "@emotion/styled";

import { mutateTransportation } from "@/api/transportation/useTransportationQuery";
import { TransportationCreateForm } from "@/features/transportation/form/createForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ScmApiTransportationPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import customAlert from "../../alert/alert";
import { TransportationsOption, useTransportationCode, useTransportationsAutoComplete } from "./auto-useGetTransportationQuery";
interface SitesProps extends React.ComponentPropsWithoutRef<"div"> {
    // group: string;
    label: string;
    value: string;
}

export interface TransportationsCodesAutoCompleteProps
    extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
    width?: string;
    value?: string | null;
    onChange?: (transportationCode: string | null) => void;
    setSTransportationName?: (name: string) => void;
    maxDropdownHeight?: number;
}

// 입력값: value (Sites 모델의 code)
// 출력값: onChange (Sites 모델의 code)

export const TransportationsAutoComplete = (params: TransportationsCodesAutoCompleteProps) => {
    const {
        width,
        value: TransportationsCode,
        onChange,
        maxDropdownHeight,
        ...etcParams
    } = params;
    const [focused, setFocused] = useState<boolean>(true);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const { data: options } = useTransportationsAutoComplete(focused, searchKeyword);

    const { data: initialOptions } = useTransportationCode(!!TransportationsCode, TransportationsCode ?? null);

    let selectedSites = initialOptions?.find(
        (Sites) => Sites.value === TransportationsCode
    );
    const onChangeHandler = (e: string | null) => {
        selectedSites = options?.find((Sites) => Sites.value === e);
        onChange && onChange(e);
    };

    const SelectTransportations = forwardRef<HTMLDivElement, SitesProps>(
        ({ label: name, value: code, ...others }, ref) => (
            <div ref={ref} {...others}>
                <Flex direction="row" justify="space-between" align="center">
                    <Flex align={"center"}>
                        <Text>{name}</Text>
                        <Text fz="xs">(품번: {code})</Text>
                    </Flex>
                    {/* <Text fz="xs">{group}</Text> */}
                </Flex>
            </div>
        )
    );

    return (
        <Select
            styles={
                TransportationsCode
                    ? {
                        input: {
                            width: width,
                            fontSize: "0.8em",
                            paddingBottom: "0.5rem",
                        },
                    }
                    : { input: { width: width } }
            }
            withinPortal
            onDropdownOpen={() => setFocused(true)}
            onDropdownClose={() => setFocused(false)}
            clearable
            inputContainer={(children: ReactNode) => (
                <div style={{ position: "relative" }}>
                    {children}
                    {selectedSites?.value && (
                        <div
                            style={{
                                position: "absolute",
                                bottom: "-2px",
                                fontSize: "0.8em",
                                color: "#888",
                                left: "5px",
                            }}
                        >
                            {"Code: " + selectedSites?.value}
                        </div>
                    )}
                </div>
            )}
            value={TransportationsCode}
            itemComponent={SelectTransportations}
            searchValue={searchKeyword}
            data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
                (unique: SitesProps[], option: SitesProps) => {
                    return unique.some((u) => u.value === option.value)
                        ? unique
                        : [...unique, option];
                },
                []
            )}
            searchable
            maxDropdownHeight={maxDropdownHeight ?? 150}
            onChange={onChangeHandler}
            onSearchChange={setSearchKeyword}
            rightSection={TransportationsInfo({
                transportation: selectedSites as TransportationsOption,
                onChange: onChangeHandler,
            })}
            filter={(value, item) =>
                item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
                item?.value?.toLowerCase().includes(value.toLowerCase().trim())
            }
            nothingFound={AddNewSites({
                transportationsName: searchKeyword,
                onChange: onChangeHandler,
            })}
            {...etcParams}
        />
    );
};

const TransportationsInfo = (params: {
    transportation?: TransportationsOption;
    onChange: (transportationCode: string | null) => void;
}) => {
    const { transportation, onChange } = params;

    const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onChange(null);
    };

    return transportation?.value ? (
        <TransportationsInfoLabel>
            {/* ({Sites.value}) */}
            <IconX size="1rem" onClick={clearHandler} />
        </TransportationsInfoLabel>
    ) : null;
};

const AddNewSites = (params: {
    transportationsName: string;
    onChange: (transportationCode: string) => void;
}) => {
    const { transportationsName, onChange } = params;
    const { openModal, closeModal } = useModal();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
        (params: ScmApiTransportationPostRequest) =>
            mutateTransportation.create(params).mutationFn(params as ScmApiTransportationPostRequest | any),
        {
            onSuccess: (res) => {
                queryClient.invalidateQueries(['transportation']);
                customAlert(
                    "배차 등록에 성공하였습니다.",
                    "등록 성공",
                    "green"
                )
                closeModal(res.data);
            },
            onError: (error) => {
                customAlert(handleErrorResponse({ error, errorContext: '배차' }), '배차 등록 실패', 'red')
            }
        }
    );

    const onCloseHandler: EventHandler<any> = (values) => {
        mutate({
            transportationGetRequest: {
                partnerCode: values.partnerCode!,
                direction: values.direction!,
                status: values.status!,
                scheduledAt: values.scheduledAt!,
                additional: values.additional!,
            }
        });
    };
    const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
        e
    ) => {
        e.preventDefault();
        openModal(
            <TransportationCreateForm name={transportationsName} autoCompleteOnSubmit={onCloseHandler} />,
            null,
            "신규 배차 등록",
            true
        ).then((result) => {
            onChange(result?.code);
        });
    };

    return (
        <Button variant="subtle" onClick={addNewModalHandler}>
            신규 배차({transportationsName}) 등록
        </Button>
    );
};
const TransportationsInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;
