import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export const ButtonFormatter = ({ row, column }: { row: any, column: any }) => {

    const navigate = useNavigate();
    
    let handleClick;
    let disabled;
    if (column.key === 'repairRegister') {
        handleClick = () => {
            navigate('/mold/repairs');
        }
        disabled = false
    } else if (column.key === 'inspectionAlert') {
        handleClick = () => {}
        disabled = true
    }
    
    return (
        <Button onClick={handleClick} disabled={disabled}>
            {column.buttonText}
        </Button>
    );
};

