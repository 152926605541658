import { MasterInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiItemsGetRequest, MasterApiItemsItemIdDeleteRequest, MasterApiItemsItemIdPutRequest, MasterApiItemsOneStepCreatePostRequest, MasterApiItemsPostRequest, MasterApiItemsRemoveAllDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const items = createQueryKeys('items', {
    all: null,
    get: (params?: MasterApiItemsGetRequest) => {
        // const queryParams = { ...params };
        const queryParams = { ...params, query: JSON.stringify({ "$and": params?.query }) };

        return {
            queryKey: [queryParams, JSON.stringify(queryParams)],
            queryFn: () => MasterInstance.itemsGet(queryParams),
        }
    },
});

export const mutateItems = createMutationKeys('items', {
    create: (params: MasterApiItemsPostRequest) => ({
        mutationKey: [params.itemsGetRequest],
        mutationFn: () => MasterInstance.itemsPost(params),
    }),
    oneStep: (params: MasterApiItemsOneStepCreatePostRequest) => ({
        mutationKey: [params.itemsOneStepCreatePostRequest],
        mutationFn: () => MasterInstance.itemsOneStepCreatePost(params)
    }),
    update: (params: MasterApiItemsItemIdPutRequest) => ({
        mutationKey: [params.itemId, params.itemsGetRequest],
        mutationFn: () => MasterInstance.itemsItemIdPut(params)
    }),
    delete: (params: MasterApiItemsItemIdDeleteRequest) => ({
        mutationKey: [params.itemId],
        mutationFn: () => MasterInstance.itemsItemIdDelete(params)
    }),
    deleteAll: (prarams: MasterApiItemsRemoveAllDeleteRequest) => ({
        mutationKey: [prarams.id],
        mutationFn: () => MasterInstance.itemsRemoveAllDelete(prarams)
    })
});

export const itemsQueryKeys = mergeQueryKeys(items, mutateItems);