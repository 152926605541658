import { MasterInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiPartnersFindPostRequest, MasterApiPartnersGetRequest, MasterApiPartnersPartnerIdDeleteRequest, MasterApiPartnersPartnerIdGetRequest, MasterApiPartnersPartnerIdPutRequest, MasterApiPartnersPostRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const partners = createQueryKeys('partners', {
    all : null,
    get : (params : MasterApiPartnersGetRequest) => {
        const query = {...params}; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => MasterInstance.partnersGet(query),
        };
    },
    detail : (params : MasterApiPartnersPartnerIdGetRequest) => ({
        queryKey : [params.partnerId, params],
        queryFn : () => MasterInstance.partnersPartnerIdGet(params)
    }),
    find : (params : MasterApiPartnersFindPostRequest) => {
        const query = {...params}; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => MasterInstance.partnersFindPost(query),
        };
    }
});

export const mutatePartners = createMutationKeys('partners', {
    create : (params : MasterApiPartnersPostRequest) => ({
        mutationKey : [params.partnersGetRequest],
        mutationFn : () => MasterInstance.partnersPost(params),
    }),
    update : (params : MasterApiPartnersPartnerIdPutRequest) => ({
        mutationKey : [params.partnerId, params.partnersGetRequest],
        mutationFn : () => MasterInstance.partnersPartnerIdPut(params)
    }),
    delete : (params : MasterApiPartnersPartnerIdDeleteRequest) => ({
        mutationKey : [params.partnerId],
        mutationFn : () => MasterInstance.partnersPartnerIdDelete(params)
    }),
});