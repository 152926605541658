import { moldMaintenancesHeader } from "@/constants/columnHeader";
import { columnlist } from "@/constants/columnlist";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { useModal } from "@/features/modal/ModalStackManager";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { GridTable } from "@/features/ui/PMS/GridTable";
import useQueryString from "@/hooks/useQueryString";
import styled from "@emotion/styled";
import { Button, Flex, Text } from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import { useState } from "react";
import { SelectColumn } from "react-data-grid";
type MoldRepair = {
    code: string | undefined;
    name: string | undefined;
    cavity: string | undefined;
    spm: string | undefined;
    slideLocation: string | undefined;
    maxBats: string | undefined;
    inspectionBats: string | undefined;
    currentBats: string | undefined;
    order: string | undefined;
    requestData: Date | string | any;
    requestInfo: string | undefined;
    questionType: string | undefined;
    requestUser: string | undefined;
    requestCompleteDate: Date | string | any;
};

const rows: MoldRepair[] = [
    {
        code: '코일 금형 테스트',
        name: '코일 금형1',
        cavity: "1",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "10000",
        inspectionBats: "10000",
        currentBats: "508",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: '다리틀 금형2',
        name: '다리틀 금형2',
        cavity: "1",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "10000",
        inspectionBats: "10000",
        currentBats: "0",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: '다리틀',
        name: '다리틀',
        cavity: "1",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "10000",
        inspectionBats: "10000",
        currentBats: "1580",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'DOUBLE',
        name: '더블',
        cavity: "1",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "10000",
        inspectionBats: "1000",
        currentBats: "0",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'MOLD_P02_3_J',
        name: 'YJ-P09(3)',
        cavity: "0",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "1300000",
        inspectionBats: "120000",
        currentBats: "0",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'MOLD_P02_2_J',
        name: 'YJ-P09(2)',
        cavity: "0",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "1200000",
        inspectionBats: "120000",
        currentBats: "0",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'MOLD_P02_1_J',
        name: 'YJ-P09(1)',
        cavity: "0",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "1100000",
        inspectionBats: "120000",
        currentBats: "0",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'excel_up',
        name: '금형명',
        cavity: "1",
        spm: "2",
        slideLocation: "0.0",
        maxBats: "0",
        inspectionBats: "0",
        currentBats: "0",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: '44552',
        name: '222',
        cavity: "222",
        spm: "2",
        slideLocation: "22.0",
        maxBats: "2",
        inspectionBats: "2",
        currentBats: "2",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'zerotest2',
        name: '-',
        cavity: "0",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "0",
        inspectionBats: "0",
        currentBats: "0",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'zerotest',
        name: '-',
        cavity: "1",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "0",
        inspectionBats: "0",
        currentBats: "0",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'what?',
        name: 'nono',
        cavity: "1",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "0",
        inspectionBats: "0",
        currentBats: "0",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: '타수입력',
        name: '금형-04',
        cavity: "1",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "0",
        inspectionBats: "0",
        currentBats: "320",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'ccd',
        name: '-',
        cavity: "1",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "0",
        inspectionBats: "0",
        currentBats: "2147483647",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'excel-code',
        name: '금형명',
        cavity: "2",
        spm: "2",
        slideLocation: "3.0",
        maxBats: "4",
        inspectionBats: "5",
        currentBats: "4575",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: "김민수",
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'mold-03',
        name: '금형-03',
        cavity: "3",
        spm: "4",
        slideLocation: "5.0",
        maxBats: "65",
        inspectionBats: "7",
        currentBats: "84",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: "필수",
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'mold-02',
        name: '금형-02',
        cavity: "1",
        spm: "1",
        slideLocation: "2.0",
        maxBats: "100",
        inspectionBats: "2",
        currentBats: "102648",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    },
    {
        code: 'mold-01',
        name: '금형-01',
        cavity: "1",
        spm: "0",
        slideLocation: "0.0",
        maxBats: "0",
        inspectionBats: "0",
        currentBats: "26690",
        order: "일반",
        requestData: new Date().toISOString(),
        requestInfo: undefined,
        questionType: undefined,
        requestUser: undefined,
        requestCompleteDate: new Date().toISOString(),
    }
];


export const MoldCompleteAdminPage = () => {
    const { openModal } = useModal();
    const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

    const [search, setSearch] = useState<SearchProps>({
        search: initialQueryString.search,
        searchFields: initialQueryString.searchFields,
        pageSize: initialQueryString.pageSize,
    });

    const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

    return (
        <Flex gap="md" direction="column" w="100%" h="100%" align="center">
            <Flex gap="md" justify="space-between" w="100%">
                <Text size="xl">금형 수리 완료 리스트 (관리자)</Text>
                <Flex gap="md">
                    <Button variant="subtle" rightIcon={<IconCalendar color="black" />} onClick={() => {/* calendar open */ }}>
                        <Flex gap="xs">
                            <Text color="black">기간 선택</Text>
                            <Text color="black">2024.07.24 ~ 2024.07.31</Text>
                        </Flex>
                    </Button>
                    <SearchBox searchType={moldMaintenancesHeader} setSearch={setSearch} search={search} />
                </Flex>
            </Flex>
            <GridWrapper direction="column" w="100%">
                <GridTable
                    headerList={[
                        SelectColumn,
                        ...columnlist.moldCompleteAdmin
                    ]}
                    row={rows ?? []}
                    setRow={() => { }}
                />
            </GridWrapper>

        </Flex>
    );

}

const GridWrapper = styled(Flex)`
  & *[role="grid"] {
    height: inherit;
  }

  & *[role="columnheader"] {
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    box-shadow: none;
    display: flex;
    align-items: center;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    box-shadow: none;
    width: 100%;
  }
`;
