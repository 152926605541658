import { ContentTh, DateText, StyledTable, Title, TitleTh, Wrapper } from "@/features/transportation/form/statementForm";
import timeUtil from "@/utils/timeUtil";
import styled from "@emotion/styled";
import { Button, Flex } from "@mantine/core";
import { PurchaseOrdersGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPrinter } from "@tabler/icons-react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

interface OrderStatementFormProps {
    data: PurchaseOrdersGet200ResponseRowsInner;
}

export const OrderStatementForm = (params: OrderStatementFormProps) => {

    const { data } = params;

    const pruchaseCode = data?.code; // 발주 번호
    const date = timeUtil(data?.orderDate ?? ''); // 발주 일자
    const manager = data?.manager; // 발주 담당자
    const partner = data?.partner?.name; // 협력사 명
    const concat = data?.partner?.contact; // 협력사 연락처
    const purchaseOrderItemNum = data?.numberOfPurchaseOrderItems; // 발주 품목 개수
    const deadline = timeUtil(data?.deliveryDeadline ?? ''); // 납기일자
    const description = data?.description; // 비고
    const rows = Array.from({ length: 10 }, (_, index) => {
        const rowData = data.purchaseOrderItems && data.purchaseOrderItems[index] || {};
        return (
            <tr key={index}>
                <ContentTd>{index + 1}</ContentTd>
                <ContentTd colSpan={2}>{rowData.itemName || ""}</ContentTd>
                <ContentTd colSpan={2}>{rowData.itemCode || ""}</ContentTd>
                <ContentTd>{rowData.quantity || ""}</ContentTd>
                <ContentTd>{rowData.partnerCode || ""}</ContentTd>
                <ContentTd>{description || ""}</ContentTd>
            </tr>
        );
    });

    const today = new Date().getFullYear() + "년 " + (new Date().getMonth() + 1) + "월 " + new Date().getDate() + "일";

    const downloadPDF = () => {
        const input = document.getElementById("pdf-content");
        if (input) {
            html2canvas(input, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'mm',
                    format: 'a4'
                });
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
                pdf.save(`${partner}_발주서.pdf`);
            });
        }
    };

    return (
        <>
            <Wrapper>
                <Flex justify={'flex-end'}>
                    <Button
                        onClick={downloadPDF}
                        leftIcon={<IconPrinter />}
                        color="teal"
                        className="no-print"
                    >출력
                    </Button>
                </Flex>
                <div id="pdf-content"
                    style={{ padding : '14px' }}
                >
                    <Flex justify={'space-between'} mt="auto" py={'md'}>
                        <Flex justify={'center'} w="100%">
                            <Title>
                                발주서
                            </Title>
                        </Flex>
                        <StyledTable w="50%" withBorder withColumnBorders>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }} colSpan={4}>경남케미컬</th>
                                </tr>
                                <tr>
                                    <TitleTh>대표</TitleTh>
                                    <ContentTh>경남대표</ContentTh>
                                    <TitleTh>담당자</TitleTh>
                                    <ContentTh>경남담당자</ContentTh>
                                </tr>
                                <tr>
                                    <TitleTh>전화번호</TitleTh>
                                    <ContentTh>{concat}</ContentTh>
                                    <TitleTh>담당자 연락처</TitleTh>
                                    <ContentTh>경남 담당자연락처</ContentTh>
                                </tr>
                                <tr>
                                    <TitleTh>사업자번호</TitleTh>
                                    <ContentTh>사업자번호1</ContentTh>
                                    <TitleTh>주소</TitleTh>
                                    <ContentTh>address</ContentTh>
                                </tr>
                            </thead>
                        </StyledTable>
                    </Flex>
                    <Flex justify={'flex-end'} mt="auto">
                        <DateText>
                            작성일자 : {today}
                        </DateText>
                    </Flex>
                    <StyledTable withBorder withColumnBorders w="100%">
                        <thead>
                            <tr>
                                <TitleTh>발주 코드</TitleTh>
                                <ContentTh colSpan={2}>{pruchaseCode}</ContentTh>
                                <TitleTh>공급자 명</TitleTh>
                                <ContentTh colSpan={2}>{partner}</ContentTh>
                            </tr>
                            <tr>
                                <TitleTh>발주일자</TitleTh>
                                <ContentTh colSpan={2}>{date}</ContentTh>
                                <TitleTh>납기일자</TitleTh>
                                <ContentTh colSpan={2}>{deadline}</ContentTh>
                            </tr>
                        </thead>
                    </StyledTable>
                    <StyledTable withBorder withColumnBorders w="100%">
                        <thead>
                            <tr>
                                <TitleTh></TitleTh>
                                <TitleTh colSpan={2}>품목명</TitleTh>
                                <TitleTh colSpan={2}>품목코드</TitleTh>
                                <TitleTh>수량</TitleTh>
                                <TitleTh>단위</TitleTh>
                                <TitleTh>비고</TitleTh>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                        <tfoot>
                            <tr>
                                <TitleTh colSpan={2}>담당자</TitleTh>
                                <ContentTh colSpan={2}>{manager}</ContentTh>
                                <TitleTh colSpan={2}>발주 품목 개수</TitleTh>
                                <ContentTh colSpan={2}>{purchaseOrderItemNum} 개</ContentTh>
                            </tr>
                        </tfoot>
                    </StyledTable>
                </div>
            </Wrapper>
        </>
    )
}

export const ContentTd = styled.td`
    padding: 10px;
    height: 3rem;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #000;
    background-color: #fff;
`