import { css } from "@emotion/css";
import styled from "@emotion/styled";
import {
  Badge,
  Button,
  Flex,
  Pagination,
  useMantineColorScheme
} from "@mantine/core";
import { InventoriesBetweenGet200ResponseRowsInner, ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

import { inventories } from "@/api/inventories/useInventoriesQuery";
import { inventoriesHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { LedgerDetailForm } from "@/features/inventory/components/Form/detail/DetailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { CalendarDate } from "@/features/ui/Calendar";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { useSub } from "@/hooks";
import { useLoader } from "@/hooks/useLoader";
import useQueryString from "@/hooks/useQueryString";
import useUpdateUrlParams from "@/hooks/useUpdateUrlParams";
import DefaultInstance from "@/instance/axios";
import { theme } from "@/styles/theme";
import { dataStyle, excelDownLoad, headerStyle } from "@/utils/excelDownLoad";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";


interface ColorThemeProps {
  isDarkMode: boolean;
}

interface InventoryTableProps {
  onRowSelect: (
    selectedRows: ReadonlySet<any>,
    formatterPropsData: any,
    selectedRowsRef: React.MutableRefObject<ReadonlySet<any>>,
    selectedRowsInform: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]
  ) => void;
  dates: CalendarDate
}

export const InventoryTable = ({ onRowSelect, dates }: InventoryTableProps) => {

  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);
  const { setLoading, LoadingOverlay } = useLoader();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );

  const [selectedRowsInform, setSelectedRowsInform] = useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]>([]);
  const [formatterPropsData, setFormatterPropsData] = useState<any>([]);

  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const updateUrlParams = useUpdateUrlParams();

  const { openModal } = useModal();

  const searchFieldsHeader = inventoriesHeader.filter(
    (item) => item.category === "text"
  );

  const { data: inventory, refetch } = useQuery(
    inventories.betweenGet({
      query: JSON.stringify({
        ...query,
        createdAt: {
          $lte: dates[1]?.toISOString()
        },
      }),
      search: search.search,
      searchFields: search.searchFields.length
        ? search.searchFields
        : searchFieldsHeader.map((inventory) => inventory.value),
      page: activePage,
      pageSize: Number(search.pageSize),
      sort: sort.length ? sort.join(",") : "-createdAt",
      populate: ["lot", "location", "item"],

    }, {
      params: {
        targetDateString: dates[0]?.toISOString(),
        targetDateEndString: dates[1]?.toISOString()
      }
    })
  );

  // 새로운 데이터가 로드될 때, 현재 페이지가 유효하지 않으면 1페이지로 설정
  useEffect(() => {
    if (inventory && activePage > (inventory?.data?.totalPages ?? 0)) {
      setPage(1);
      setQueryString((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [inventory, activePage, setQueryString]);

  // 페이지 Query String으로 공정 이동
  useEffect(() => {
    updateUrlParams({
      page: activePage,
      pageSize: search.pageSize,
      search: search.search,
      searchFields: search.searchFields,
      query: JSON.stringify(query),
      sort: sort
    }, queryDefaultValues);
  }, [activePage, search, query, sort, updateUrlParams]);

  const rows: readonly ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[] =
    inventory?.data.rows ?? [];
  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

  // 해당 코드가 어떤 이유에서 사용되는건지 잘 모르겠다.
  // 다같이 확인해보고 필요 없다면 삭제 처리해도 될 듯..
  useEffect(() => {
    const selectedRowId = selectedRows.values().next().value;
    if (selectedRowId) {
      const selectedRow = rows.find((row) => row.id === selectedRowId);

      setFormatterPropsData(selectedRow);
    } else {
      setFormatterPropsData([]);
    }
  }, [selectedRows]);

  useEffect(() => {
    // 부모에게 선택된 row를 넘겨주기 위한 함수
    if (formatterPropsData) {
      onRowSelect(selectedRows, formatterPropsData, selectedRowsRef, selectedRowsInform);
    }
  }, [selectedRows, formatterPropsData]);

  const { t } = useTranslation();

  const itemTypeColor: { [key: string]: string } = {
    "PRODUCT": "green.7",
    "SEMI_PRODUCT": "blue.7",
    "MATERIAL": "yellow.7",
    "SUBSIDIARY": "red.7",
  };

  const columns: readonly Column<InventoriesBetweenGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "itemCode",
      name: "품목코드",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const detailItemAction = () => {
          openModal(
            <ItemsDetailForm itemCode={row.itemCode} />,
            null,
            "품목 상세"
          );
        };
        return <DetailLink onClick={detailItemAction}>{row.itemCode}</DetailLink>;
      },
    },
    {
      key: "itemName",
      name: "품목 명",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => <div>{row.itemName}</div>,
    },
    {
      key: "spec",
      name: "규격",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => <div>{row.spec}</div>,
    },
    {
      key: "itemType",
      name: "품목 타입",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Badge size="lg" color={itemTypeColor[row?.itemType as string]}>{t(row?.itemType as string)}</Badge>
        );
      },
    },
    {
      key: "lot.name",
      name: "로트명",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => <div>{row.lot?.name}</div>,
    },
    {
      key: "lot.expiration",
      name: "로트 유효기한",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => (
        <div>
          {row.lot?.expiration === null ? "" : timeUtil(row.lot?.expiration!)}
        </div>
      ),
    },
    {
      key: "locationCode",
      name: "창고",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const detailItemAction = () => {
          openModal(
            <LocationDetailForm locationCode={row.locationCode ?? ""} />,
            null,
            "창고 상세"
          );
        };
        return (
          <DetailLink onClick={detailItemAction}>{row.locationName}</DetailLink>
        );
      },
    },
    {
      key: "createdAt",
      name: "입고일",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <>{timeUtil(row.createdAt as string)}</>
        );
      },
    },
    {
      key: "closedQuantity",
      name: "마감 재고",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => (
        <span>{row.closedQuantity !== null ? (setToLocaleString(row?.closedQuantity) + " " + row.unitText) : ""}</span>
      ),
    },
    {
      key: "closedAt",
      name: "마감일시",
      sortable: true,
      resizable: true,
      width: 160,
      formatter: ({ row }) => {
        return (
          <span>
            {row.closedAt === null
              ? ""
              : dayjs(row.closedAt).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        );
      },
    },
    {
      key: "increaseQuantity",
      name: "추가된 재고",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => {
        const betweenIncreaseQuantity = row?.betweenIncreaseQuantity ?? 0;
        return <span>{setToLocaleString(betweenIncreaseQuantity)} {row.unitText}</span>;
      },
    },
    {
      key: "decreaseQuantity",
      name: "감소된 재고",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => {
        const betweenDecreaseQuantity = row?.betweenDecreaseQuantity ?? 0;
        return <span>{setToLocaleString(betweenDecreaseQuantity)} {row.unitText}</span>
      },
    },
    {
      key: "deltaQuantity",
      name: "재고 변화량",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => {
        const betweenDeltaQuantity = row?.betweenDeltaQuantity ?? 0;
        return <span>{setToLocaleString(betweenDeltaQuantity)} {row.unitText}</span>
      },
    },
    {
      key: "quantityAtStart",
      name: "이월 재고",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => {
        const quantityAtStart = row?.quantityAtStart ?? 0;
        return <span>{setToLocaleString(quantityAtStart)} {row.unitText}</span>
      },
    },
    {
      key: "quantity",
      name: "최종 재고",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => {
        const quantityAtEnd = row?.quantityAtEnd ?? 0;
        const detailItemAction = () => {
          openModal(<LedgerDetailForm data={row} />, null, "재고 상세");
        };
        return (
          <DetailLink
            onClick={detailItemAction}
          >
            {setToLocaleString(quantityAtEnd)} {row.unitText}
          </DetailLink>
        );
      },
    },
  ];

  useSub("excelDownLoad", async () => {
    try {
      setLoading(true);
      const response = await DefaultInstance.inventoriesFindPost({
        query: JSON.stringify(query),
        search: search.search,
        searchFields: search.searchFields.length
          ? search.searchFields
          : searchFieldsHeader.map((inventory) => inventory.value),
        sort: sort.length ? sort.join(",") : "-createdAt",
        populate: ["lot", "location", "item", "InventoryByPeriod"],
      }, {
        params: {
          start: dates[0]?.toISOString(),
          end: dates[1]?.toISOString()
        }
      });

      const data = response.data;

      const headerRow = [
        { v: '품목코드', t: "s", s: headerStyle },
        { v: '품목 명', t: "s", s: headerStyle },
        { v: '규격', t: "s", s: headerStyle },
        { v: '품목 타입', t: "s", s: headerStyle },
        { v: '로트명', t: "s", s: headerStyle },
        { v: '로트 유효기한', t: "s", s: headerStyle },
        { v: '위치', t: "s", s: headerStyle },
        { v: '마감 재고', t: "s", s: headerStyle },
        { v: '마감일시', t: "s", s: headerStyle },
        { v: '추가된 재고', t: "s", s: headerStyle },
        { v: '감소된 재고', t: "s", s: headerStyle },
        { v: '재고 변화량', t: "s", s: headerStyle },
        { v: '이월 재고', t: "s", s: headerStyle },
        { v: '최종 재고', t: "s", s: headerStyle },
      ];

      const dataRows = data.map((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => {
        const increaseQuantity = (item.inventoryByPeriod && item.inventoryByPeriod.length && item.inventoryByPeriod[0].increaseQuantity)?.toString() ?? "";
        const decreaseQuantity = (item.inventoryByPeriod && item.inventoryByPeriod.length && item.inventoryByPeriod[0].decreaseQuantity)?.toString() ?? "";
        const deltaQuantity = (item.inventoryByPeriod && item.inventoryByPeriod.length && item.inventoryByPeriod[0].deltaQuantity)?.toString() ?? "";
        const quantityAtStart = (item.inventoryByPeriod && item.inventoryByPeriod.length && item.inventoryByPeriod[0].quantityAtStart)?.toString() ?? "";
        const quantityAtEnd = (item.inventoryByPeriod && item.inventoryByPeriod.length && item.inventoryByPeriod[0].quantityAtEnd)?.toString() ?? "";

        return [
          { v: item.itemCode ?? "", t: "s", s: dataStyle },
          { v: item.itemName ?? "", t: "s", s: dataStyle },
          { v: item.spec ?? "", t: "s", s: dataStyle },
          { v: item.itemType ?? "", t: "s", s: dataStyle },
          { v: item.lot?.name ?? "", t: "s", s: dataStyle },
          { v: item.lot?.expiration ?? "", t: "s", s: dataStyle },
          { v: item.locationName ?? "", t: "s", s: dataStyle },
          { v: item.closedQuantity ?? "", t: "s", s: dataStyle },
          { v: item.closedAt ?? "", t: "s", s: dataStyle },
          { v: increaseQuantity, t: "s", s: dataStyle },
          { v: decreaseQuantity ?? "", t: "s", s: dataStyle },
          { v: deltaQuantity ?? "", t: "s", s: dataStyle },
          { v: quantityAtStart ?? "", t: "s", s: dataStyle },
          { v: quantityAtEnd ?? "", t: "s", s: dataStyle },
        ]
      }) ?? [];

      await excelDownLoad({
        headerRow,
        dataRows,
        colWidths: [120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120],
        fileName: '재고현황'
      });

    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  }, [JSON.stringify(query), search.search, search.searchFields, sort]);


  return (
    <InventoryTableWrapper>
      <LoadingOverlay />
      <GridWrapper isDarkMode={isDarkMode}>
        <TableWrapper>
          <RetrieveWrapper>
            <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
              <CustomFilter filterType={inventoriesHeader} setQuery={setQuery} query={query} />
              <CustomSorter sorterType={inventoriesHeader} setSort={setSort} sort={sort} />
            </Flex>
            <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
          </RetrieveWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rows as any}
            rowHeight={40}
            rowKeyGetter={(row) => row.id}
            sortColumns={sortColumns}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              selectedRowsRef.current = e;
              const selectedRowIds = Array.from(e);
              const selectedRowInform = rows.filter((row) => selectedRowIds.includes(row.id));
              setSelectedRowsInform(selectedRowInform);
            }}
          />
        </TableWrapper>
        <PaginationWrapper>
          <Pagination
            onChange={(page) => {
              setSelectedRows(new Set());
              setSelectedRowsInform([])
              setPage(page)
            }}
            value={activePage}
            total={inventory?.data?.totalPages ?? 0}
            size="lg"
            radius="sm"
          />
        </PaginationWrapper>
      </GridWrapper>
    </InventoryTableWrapper>
  );
};

const InventoryTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
