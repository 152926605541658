import { useModal } from "@/features/modal/ModalStackManager";
import { MachineSearchBox } from "@/features/ui/PMS/machineSearchBox";
import CustomTable from "@/features/ui/table/CustomTable";
import { ActionIcon, Button, Flex, Input, Select, Text } from "@mantine/core";
import { IconReportSearch, IconSearch } from "@tabler/icons-react";

export const PressCamViewPage = () => {

    const { openModal } = useModal();

    const Header = {
        machine: ['기계 제조사', '기계 이름', '제조 연월일', '제조 번호'],
        cam: ['CAM.NO', '명칭(KO)', '명칭(EN)', 'ANGLE', '90', '180', '270']
    }

    const BodyData = {
        machine: [{ '기계 제조사': '제조사1', '기계 이름': '이름1', '제조 연월일': '2022-01-01', '제조 번호': '1234' }],
        cam: [{ 'CAM.NO': '1', '명칭(KO)': '명칭1', '명칭(EN)': 'name1', 'ANGLE': '1', '90': '1', '180': '1', '270': '1' }]
    }

    return (
        <>
            <Flex gap="md" direction="column">
                <Flex justify="space-between">
                    <Text size="xl">캠 보기</Text>
                    <ActionIcon
                        onClick={() => {
                            openModal(<MachineSearchBox />, null, '기계 검색')
                        }}
                    >
                        <IconReportSearch />
                    </ActionIcon>
                </Flex>
                <CustomTable
                    headers={Header.machine}
                    data={BodyData.machine}
                />
                <Flex gap="sm" justify='right' align='center'>
                    <Select
                        w={'8rem'}
                        data={[
                            { label: '한글이름', value: 'all' },
                            { label: '번호', value: 'all' },
                            { label: '영어이름', value: 'all' },
                        ]}
                    />
                    <Input
                        w={'16rem'}
                        placeholder="검색어를 입력하세요"
                        rightSection={<IconSearch />}
                    />
                    <Button color="gray">저장하기</Button>
                </Flex>
                <CustomTable 
                    headers={Header.cam}
                    data={BodyData.cam}
                />
            </Flex>
        </>
    )
}