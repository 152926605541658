import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * useQueryString 훅 타입 정의
 * @param key 쿼리 파라미터의 키 이름
 * @param initialValue 초기값
 */
export const useQueryString = <T,>(
    key: string,
    initialValue: T
): [T, Dispatch<SetStateAction<T>>] => {
    const [searchParams, setSearchParams] = useSearchParams();

    const getValue = (): T => {
        const param = searchParams.get(key);
        if (param !== null) {
            try {
                return JSON.parse(param) as T;
            } catch {
                return param as T;
            }
        }
        return initialValue;
    };

    const [value, setValue] = useState<T>(getValue);

    useEffect(() => {
        const newParams = new URLSearchParams(searchParams);

        // 빈 배열 또는 null, 빈 문자열일 경우 해당 파라미터를 제거
        if ((Array.isArray(value) && value.length === 0) || value === null || value === "") {
            newParams.delete(key);
        } else {
            newParams.set(key, JSON.stringify(value));
        }

        // URL 파라미터 업데이트
        if (newParams.toString() !== searchParams.toString()) {
            setSearchParams(newParams);
        }
    }, [value, key, searchParams, setSearchParams]); // setSearchParams 의존성 추가

    // 상태 업데이트 함수가 이전 상태를 참조할 수 있도록 하는 래퍼 함수
    const setValueWrapper = (updater: SetStateAction<T>) => {
        setValue((prevValue) => {
            const newValue = typeof updater === 'function' ? (updater as (prev: T) => T)(prevValue) : updater;
            return newValue;
        });
    };

    return [value, setValueWrapper];
};