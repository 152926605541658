import { consumeTypeColor, routingTypeColor } from "@/constants/routings";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import styled from "@emotion/styled";
import { Badge, Flex, Table, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ItemsOneStepCreatePostRequestRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ItemsDetailForm } from "../ItemsDetailForm";

interface RoutingBomRowProps {
    bomRow: ItemsOneStepCreatePostRequestRoutingsInner;
    rowIndex: number;
}

export const RoutingBomRow = ({ bomRow, rowIndex }: RoutingBomRowProps) => {
    const { t } = useTranslation();
    const [opened, { toggle }] = useDisclosure(false);
    const { openModal } = useModal();

    return (
        <Fragment>
            <tr key={bomRow.code ?? "" + rowIndex}>
                <td>
                    <Flex justify="center" align="center">
                        {bomRow.routingBoms && bomRow.routingBoms.length && !opened ? (<IconPlus onClick={toggle} />) : (<IconMinus onClick={toggle} />)}
                    </Flex>
                </td>
                <td><Text ta="end">{bomRow.seq}</Text></td>
                <td>{bomRow.operationCode}</td>
                <td>{bomRow.code}</td>
                <td><Badge color={routingTypeColor[bomRow.routingType!]}>{t(bomRow.routingType ?? "")}</Badge></td>
                <td><Badge color={consumeTypeColor[bomRow.consumeType!]}>{t(bomRow.consumeType ?? "")}</Badge></td>
            </tr>
            {
                bomRow.routingBoms && opened && (
                    <tr style={{ backgroundColor: "#f3f0ff" }}>
                        <td colSpan={3}></td>
                        <td colSpan={3}>
                            <Table withColumnBorders>
                                <thead>
                                    <tr>
                                        <Th width={60}>원부자재 품목코드</Th>
                                        <Th width={40}>수량</Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        bomRow.routingBoms &&
                                        bomRow.routingBoms.map((bom, i) => {
                                            return (
                                                <tr key={bom.itemCode ?? "" + i}>
                                                    <td>
                                                        <Flex justify="flex-start">
                                                            <DetailLink onClick={() => openModal(
                                                                <ItemsDetailForm itemCode={bom.itemCode as string} />,
                                                                null,
                                                                "품목 상세"
                                                            )}>
                                                                {bom.itemCode}
                                                            </DetailLink>
                                                        </Flex>
                                                    </td>
                                                    <td>{bom.quantity}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                )
            }
        </Fragment>
    )
}

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
`;