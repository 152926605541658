import { authEnum } from "@/config/auth";
import { UserInstance } from "@/instance/axios";
import { theme } from "@/styles/theme";
import { Button, Flex, PasswordInput, Text, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconEyeCheck, IconEyeOff } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const form = useForm({
    initialValues: {
      id: "",
      password: "",
    },
    validate: {
      id: (value) => value ? null : "Please enter your ID",
      password: (value) => value ? null : "Please enter your password",
    },
  });

  const navigate = useNavigate();

  const onSignIn = async () => {
    const id = form.values.id;
    const passwordValue = form.values.password;

    const validate = id && passwordValue;

    if (!validate) {
      alert("please enter id, password");
      return;
    }

    try {

      const response = await UserInstance.authLoginPost({
        authLoginPostRequest: {
          identifier: id,
          password: passwordValue,
        },
      });

      const token = response.data?.token;
      localStorage.setItem(authEnum.authToken, token);
      localStorage.setItem('username', id)

      navigate("/dashboard");
    } catch (error: any) {
      alert(error?.response?.data?.message);
    }
  };

  return (
    <Flex w="100%" h="100%" justify="center" align="center" bg={theme?.colors?.gray?.[6]} pos="relative">
      <Flex w="40rem" h="30rem" direction="column" p="5rem" gap="lg" bg="black" style={{ borderRadius: "1rem" }}>
        <Title c="white">Sign In</Title>
        <TextInput
          label={<Text size="xl" color="white">ID</Text>}
          placeholder="ID"
          {...form.getInputProps("id")}
          size="lg"
        />
        <PasswordInput
          label={<Text size="xl" color="white">Password</Text>}
          placeholder="Password"
          {...form.getInputProps("password")}
          size="lg"
          visibilityToggleIcon={({ reveal, size }) =>
            reveal ? <IconEyeOff size={size} color="black" /> : <IconEyeCheck size={size} color="black" />
          }
        />
        <Button type="submit" size="lg" onClick={() => onSignIn()}>Sign in</Button>
      </Flex>
    </Flex>
  );
}

export default Login;
