import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";

const DetailRouting = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

export const RoutingBomInform = () => {
  return (
    <DetailRouting bg={"#FFFFFF"}>
      <DetailRouting.Title size={26}>
        {"라우팅 BOM(Bill of Materials)"}
      </DetailRouting.Title>
      <DetailRouting.Inform>
        {"선택된 라우팅이 1회 진행될 때 마다 소모될 원/부자재와 수량입니다"}
      </DetailRouting.Inform>
    </DetailRouting>
  );
};
