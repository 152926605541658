import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
});

export const AuthorityUpdateForm = (params: any) => {
    const { formatterProps } = params;
    const { closeModal } = useModal();

    const form = useForm({
        initialValues: {
            authorityName: formatterProps.authorityName,
        },
    });

    const onSubmit = async () => {
        try {
            customAlert(
                "권한 수정에 실패하였습니다.",
                "수정 실패",
                "red"
            )
        } catch (e) {
            customAlert(
                "권한 수정에 실패하였습니다.",
                "수정 실패",
                "red"
            )
        }
    };

    return (
        <Create.Wrapper>
            <Create.Input
                label="권한명"
                withAsterisk
                {...form.getInputProps("authorityName")}
            />
            <Create.ButtonBox>
                <Create.Button color="gray" onClick={closeModal}>
                    취소
                </Create.Button>
                <Create.Button
                    onClick={onSubmit}
                    disabled={validateFormValues({
                        pageType: "authority",
                        values: form.values,
                    })}
                >
                    권한 수정
                </Create.Button>
            </Create.ButtonBox>
        </Create.Wrapper>
    );
};
