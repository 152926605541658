import { useQuery } from "@tanstack/react-query";

import DefaultInstance from "@/instance/axios";
import { ItemsGet200ResponseRowsInnerRoutingsInner, RoutingsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";

export type RoutingOption = {
    value: string;
    label: string;
};

export const useRoutingsMultiAutoComplete = (
    focus = false,
    keyword = "",
    options?: {
        onSuccess: (data: RoutingOption[]) => void;
    }
) => {
    return useQuery(
        ["routings-get-multi-autocomplete-keyword", keyword],
        () =>
            DefaultInstance.routingsFindPost({
                search: keyword,
                searchFields: ["name"],
            }).then((res) => res.data),
        {
            enabled: focus,
            select: (
                data: ItemsGet200ResponseRowsInnerRoutingsInner[]
            ): RoutingOption[] =>
                data.map((Routing) => {
                    return {
                        value: String(Routing.code),
                        label: String(Routing.code),
                    };
                }) ?? [],
            onSuccess: options?.onSuccess,
        }
    );
};

const getRoutingByCode = (code: string | null) =>
    DefaultInstance.routingsGet({
        ...(code && { search: code }),
        searchFields: ["code"],
    }).then((res) => res.data);

export const useRoutingsMultiCode = (
    enabled: boolean = false,
    code: string | null,
    options?: {
        onSuccess: (data?: RoutingOption[]) => void;
    }
) => {
    return useQuery(
        ["routings-multi-get-code", code],
        () => getRoutingByCode(code),
        {
            enabled,
            select: (data: RoutingsGet200Response) =>
                data?.rows?.map((Routing) => {
                    return {
                        value: Routing.code as string,
                        label: Routing.code as string,
                    };
                }),
            onSuccess: options?.onSuccess,
        }
    );
};
