import DefaultInstance from "@/instance/axios";
import { createMutationKeys } from "@lukemorales/query-key-factory";
import { DefaultApiErpSyncDeleteErpSplitOutsourcingRecordPostRequest, DefaultApiErpSyncDeleteErpSplitWorkRecordPostRequest, DefaultApiErpSyncPushErpSplitOutsourcingRecordPostRequest, DefaultApiErpSyncPushErpSplitWorkRecordPostRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const mutatePalletErps = createMutationKeys('palletErp', {
    postERP : (params : DefaultApiErpSyncPushErpSplitWorkRecordPostRequest) => ({
        mutationKey : [params.erpSyncPushErpSplitWorkRecordPostRequest],
        mutationFn : () => DefaultInstance.erpSyncPushErpSplitWorkRecordPost(params),
    }),
    cancelERP : (params : DefaultApiErpSyncDeleteErpSplitWorkRecordPostRequest) => ({
        mutationKey : [params.erpSyncPushErpSplitWorkRecordPostRequest],
        mutationFn : () => DefaultInstance.erpSyncDeleteErpSplitWorkRecordPost(params),
    }),
    postERPoutsourcing : (params : DefaultApiErpSyncPushErpSplitOutsourcingRecordPostRequest) => ({
        mutationKey : [params.erpSyncPushErpSplitOutsourcingRecordPostRequest],
        mutationFn : () => DefaultInstance.erpSyncPushErpSplitOutsourcingRecordPost(params),
    }),
    cancelERPoutsourcing : (params : DefaultApiErpSyncDeleteErpSplitOutsourcingRecordPostRequest) => ({
        mutationKey : [params.erpSyncPushErpSplitWorkRecordPostRequest],
        mutationFn : () => DefaultInstance.erpSyncDeleteErpSplitOutsourcingRecordPost(params),
    })
});