import styled from '@emotion/styled'
import React from 'react'


interface Props {
  color: string
}

const Container = styled.div`
  position: relative;
  right: 22px;
  width: 8px;
  height: 8px;
  border-radius: 100
`

const FrequentlyChartLegendShapeCircle: React.FunctionComponent<Props> = ({ color }) => {
  return (
    <Container style={{ backgroundColor: color }}/>
  )
}

export default React.memo(FrequentlyChartLegendShapeCircle)
