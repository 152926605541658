import { DOWN_TIME_REASONS, mutateDownTimeReasons } from "@/api/downtimeReasons/useDowntimeReasonsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { OperationAutoComplete } from "@/features/ui/autoComplete/operation/operation-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiDowntimeReasonsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});


type DowntimeReasonsCreateFormProps = {
  maxSeq?: number;
};

export const DowntimeReasonsCreateForm = ({ maxSeq }: DowntimeReasonsCreateFormProps) => {
  const { closeModal } = useModal();

  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    (params: DefaultApiDowntimeReasonsPostRequest) =>
      mutateDownTimeReasons.create(params).mutationFn(params as DefaultApiDowntimeReasonsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([DOWN_TIME_REASONS]);
      },
    }
  );

  const form = useForm({
    initialValues: {
      seq: maxSeq,
      code: undefined,
      name: undefined,
      operationCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate(
        {
          downtimeReasonsGetRequest: {
            seq: form.values.seq,
            code: form.values.code!,
            name: form.values.name!,
            operationCode: form.values.operationCode!,
          },
        },
        {
          // onError 추가 필요
          onSuccess: (res) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert(
                "비가동사유 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
            } else {
              customAlert(
                "비가동사유 생성에 실패하였습니다.",
                "생성 실패",
                "red"
              )
            }
          },
          onError: (error: any) => {
            customAlert(handleErrorResponse({ error, errorContext: '비가동사유코드' }), '비가동사유 생성 실패', 'red')
          }
        }
      );
    } catch (e) {
      customAlert(
        "비가동사유 생성에 실패하였습니다.",
        "생성 실패",
        "red"
      )
    }
  };

  return (
    <Create.Wrapper>
      <Create.Input
        disabled
        label="순서"
        withAsterisk
        {...form.getInputProps("seq")}
      />
      <Create.Input
        label="비가동사유코드"
        withAsterisk
        {...form.getInputProps("code")}
      />
      <Create.Input
        label="비가동사유명"
        withAsterisk
        {...form.getInputProps("name")}
      />
      <OperationAutoComplete
        label="공정코드"
        {...form.getInputProps("operationCode")}
      />
      <Create.ButtonBox>
        <Create.Button color="gray" onClick={closeModal}>
          취소
        </Create.Button>
        <Create.Button
          disabled={validateFormValues({
            pageType: "downtimeReasons",
            values: form.values,
          })}
          onClick={onSubmit}
        >
          비가동사유 생성
        </Create.Button>
      </Create.ButtonBox>
    </Create.Wrapper>
  );
};
