import { useWorkActions } from '@/features/workByManagement/hooks/useWorkActions';
import { ProductionPlansGet200ResponseRowsInnerWorksInnerAllLotsSummaryInner, WorksItemGet200ResponseRowsInner } from '@sizlcorp/sizl-api-document/dist/models';
import React, { createContext, useContext, useEffect, useState } from 'react';

// 체크박스 상태 타입
type innerCheckboxType = {
    rowId: number | undefined;
    isChecked: boolean;
    lot: ProductionPlansGet200ResponseRowsInnerWorksInnerAllLotsSummaryInner;
};

interface CheckboxState {
    [rowIndex: number]: {
        [checkboxIndex: number]: innerCheckboxType;
    };
}

// Context 초기값 및 타입
interface CheckboxContextProps {
    checkedStates: CheckboxState;
    setCheckedState: (
        rowId: number | undefined,
        lot: ProductionPlansGet200ResponseRowsInnerWorksInnerAllLotsSummaryInner,
        rowIndex: number, checkboxIndex: number,
        isChecked: boolean
    ) => void;
    areAllChecked: () => boolean;
    setCheckAll: (isChecked: boolean) => void;
    resetAllChecked: () => void; // 초기화 함수 추가
    workData: WorksItemGet200ResponseRowsInner[] | undefined;
    setWorkData: (data: WorksItemGet200ResponseRowsInner[] | undefined) => void;
}

// Context 생성
const CheckboxContext = createContext<CheckboxContextProps | undefined>(undefined);

// Context Provider 컴포넌트
export const CheckboxProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [checkedStates, setCheckedStates] = useState<CheckboxState>({});
    const [workData, setWorkData] = useState<WorksItemGet200ResponseRowsInner[] | undefined>([]);
    const actions = useWorkActions();

    // ERP 데이터 업데이트 여부를 관리하는 상태
    const [shouldUpdateERP, setShouldUpdateERP] = useState(false);
    const [checkAllState, setCheckAllState] = useState<boolean | null>(null);

    // 체크박스 상태 업데이트 함수
    const setCheckedState = (
        rowId: number | undefined,
        lot: ProductionPlansGet200ResponseRowsInnerWorksInnerAllLotsSummaryInner,
        rowIndex: number, checkboxIndex: number,
        isChecked: boolean
    ) => {
        setCheckedStates((prevState) => ({
            ...prevState,
            [rowIndex]: {
                ...prevState[rowIndex],
                [checkboxIndex]: {
                    rowId,
                    isChecked,
                    lot,
                }
            },
        }));
    };

    // 모든 체크박스가 선택되었는지 확인하는 함수
    const areAllChecked = () => {
        if (Object.values(checkedStates).length === 0) return false;
        return Object.values(checkedStates).every((rowState: innerCheckboxType[]) => {
            return Object.values(rowState).every((state) => state.isChecked);
        });
    };

    // 모든 체크박스를 한 번에 선택하거나 해제하는 함수
    const setCheckAll = (isChecked: boolean) => {
        if (workData?.map((data) => data.allLotsSummary).flat().length === 0) return alert('선택 가능한 작업이 없습니다.');

        setCheckedStates((prevState) => {
            const newState: CheckboxState = {};
            Object.keys(prevState).forEach((rowIndex) => {
                Object.keys(prevState[Number(rowIndex)]).forEach((checkboxIndex) => {
                    const { lot, rowId } = prevState[Number(rowIndex)][Number(checkboxIndex)];
                    newState[Number(rowIndex)] = {
                        ...newState[Number(rowIndex)],
                        [Number(checkboxIndex)]: {
                            rowId,
                            isChecked,
                            lot,
                        },
                    };
                });
            });

            setCheckAllState(isChecked); // 체크 상태 저장
            setShouldUpdateERP(true);    // ERP 데이터 업데이트 플래그 설정
            return newState;
        });
    };

    // 체크박스 상태를 모두 초기화하는 함수
    const resetAllChecked = () => {
        setCheckedStates({});
        setCheckAllState(null); // 전체 체크 상태 초기화
        setShouldUpdateERP(true); // ERP 데이터 업데이트 플래그 설정
    };

    // 체크박스 상태나 체크 여부가 변경되었을 때 ERP 데이터를 업데이트
    useEffect(() => {
        if (shouldUpdateERP) {
            const startWorkLogId: number[] = [];
            const endWorkLogId: number[] = [];
            const OSPDelvDate: string[] = [];
            const workIds: number[] = [];

            Object.values(checkedStates).forEach((rowState) => {
                Object.values(rowState).forEach((checkboxState: any) => {
                    const { lot, rowId } = checkboxState;
                    if (checkAllState) { // 체크된 경우에만 데이터 추가
                        startWorkLogId.push(lot.startWorkLogId ?? 0);
                        endWorkLogId.push(lot.endWorkLogId ?? 0);
                        OSPDelvDate.push(lot.OSPDelvDate ?? '');
                        workIds.push(rowId ?? 0);
                    }
                });
            });

            actions.setErpWorkData((prevState) => ({
                ...prevState,
                startWorkLogId: checkAllState ? startWorkLogId : [],
                endWorkLogId: checkAllState ? endWorkLogId : [],
                OSPDelvDate: checkAllState ? OSPDelvDate : [],
                workIds: checkAllState ? workIds : [],
            }));

            setShouldUpdateERP(false); // 상태 업데이트 후 플래그 초기화
        }
    }, [shouldUpdateERP, checkAllState, checkedStates, actions]);

    return (
        <CheckboxContext.Provider value={{ checkedStates, setCheckedState, areAllChecked, setCheckAll, resetAllChecked, workData, setWorkData }}>
            {children}
        </CheckboxContext.Provider>
    );
};

// Context를 쉽게 사용하기 위한 Hook
export const useCheckboxContext = () => {
    const context = useContext(CheckboxContext);
    if (!context) {
        throw new Error('useCheckboxContext must be used within a CheckboxProvider');
    }
    return context;
};