import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Chip, Flex, Pagination, TextInput, useMantineColorScheme } from "@mantine/core";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { FormatterProps, SelectColumn } from "react-data-grid";

import { mutatePalletErps } from "@/api/palletErp/usePalletErpQuery";
import { productionPerformances } from "@/api/productionPerformance/useProductionPerformanceQuery";
import { EquipmentDetailForm } from "@/features/equipment/components/form/EquipmentDetailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ConsumeDetailForm } from "@/features/productionPerformance/consumeDetail/consumeDetailForm";
import { DefectDetailForm } from "@/features/productionPerformance/defectDetail/defectDetailForm";
import { ProductionPerformanceTotalTable } from "@/features/productionPerformance/totalTable";
import { ChipsBox } from "@/features/standard/Chips/ChipsBox";
import customAlert from "@/features/ui/alert/alert";
import { Calendar, CalendarDate, isCalendarDate } from "@/features/ui/Calendar";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { usePub, useSub } from "@/hooks";
import { useLoader } from "@/hooks/useLoader";
import DefaultInstance from "@/instance/axios";
import { theme } from "@/styles/theme";
import { dataStyle, excelDownLoad, headerStyle } from "@/utils/excelDownLoad";
import { setToLocaleString } from "@/utils/unitMark";
import { DefaultApiErpSyncDeleteErpSplitOutsourcingRecordPostRequest, DefaultApiErpSyncPushErpSplitOutsourcingRecordPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { SplitLotGet200ResponseRowsInner, SplitLotGet200ResponseRowsInnerDefectsInner, WorkLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models/src/model";
import { IconCalendar, IconFileSpreadsheet, IconFolder, IconFolderCancel } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";


interface ColorThemeProps {
    isDarkMode: boolean;
}

interface WorkLogTableProps {
    dates: CalendarDate;
    filterQueries: { [key: string]: string };
    isTableInputFocused: boolean;
    setIsTableInputFocused: (value: boolean) => void;
}
interface Query {
    $and: Array<{
        createdAt?: {
            $gte: string | undefined;
            $lte: string | undefined;
        };
        routingOutsourceId?: {
            $ne: null; // 외주일땐 ne
        };
        checkErpSync?: {
            $or: [
                { $eq: null },
                { $eq: "D" }
            ];
        };
        [key: string]: any;
    }>;
}
interface SearchProps {
    pageSize: string;
}

export const ProductionPerformanceOutsourcingTable = (params: WorkLogTableProps) => {
    const { dates, filterQueries, isTableInputFocused, setIsTableInputFocused } = params;
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const { setLoading, LoadingOverlay, loading } = useLoader();
    const isDarkMode: boolean = colorScheme === "light";
    const publish = usePub();
    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const [formatterProps, setFormatterProps] = useState<
        FormatterProps<WorkLogsGet200ResponseRowsInner, unknown> | undefined
    >();
    const [selectedRows, setSelectedRows] = useState(
        (): ReadonlySet<any> => new Set()
    );
    const [activePage, setPage] = useState(1);
    const [filterErpSync, setFilterErpSync] = useState(false);
    const [selectedRowsArray, setSelectedRowsArray] = useState<any[]>([]);

    const [valuesArray, setValuesArray] = useState<{ [key: string]: string[] }>({});
    const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
    const [selectedDates, setSelectedDates] = useState<{ [key: string]: CalendarDate }>({});
    const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});
    const focusedInputRef = useRef<string | null>(null);

    // TextInput에서 입력한 숫자를 배열에 저장하는 함수
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>, rowId: string) => {
        const value = event.target.value;
        const numberValue = value.replace(/[^0-9]/g, ''); // 숫자만 입력되도록 필터링
        setInputValues((prev) => ({
            ...prev,
            [rowId]: numberValue,
        }));

        // 숫자가 8자리일 때 (YYYYMMDD 형식이 완성되면) 배열에 추가
        if (numberValue.length === 8 && !isNaN(Number(numberValue))) {
            setValuesArray((prev) => ({
                ...prev,
                [rowId]: [...(prev[rowId] || []), numberValue],
            }));
        }
        focusedInputRef.current = rowId;
    };

    const handleFocus = (rowId: string) => {
        focusedInputRef.current = rowId;
        setIsTableInputFocused(true);
    };

    // DatePicker에서 선택한 날짜를 배열에 'YYYYMMDD' 형식으로 저장하는 함수
    const handleDateChange = (date: any, rowId: string) => {
        const formattedDate = dayjs(date[0]).format('YYYYMMDD');

        // 선택된 날짜를 배열에 추가
        setValuesArray((prev) => ({
            ...prev,
            [rowId]: [...(prev[rowId] || []), formattedDate], // 해당 rowId에 맞는 배열에 추가
        }));
        setInputValues((prev) => ({
            ...prev,
            [rowId]: formattedDate, // 선택한 날짜를 해당 rowId의 TextInput에 반영
        }));
        setSelectedDates((prev) => ({
            ...prev,
            [rowId]: [date[0], (prev[rowId] && prev[rowId][1]) || new Date()], // 필요한 부분만 업데이트
        }));

        if (inputRefs.current[rowId]) {
            inputRefs.current[rowId]?.focus(); // 포커스를 해당 TextInput에 유지
        }
    };

    useEffect(() => {
        if (focusedInputRef.current && inputRefs.current[focusedInputRef.current] && isTableInputFocused) {
            inputRefs.current[focusedInputRef.current]?.focus();
        }
    }, [inputValues, selectedDates, isTableInputFocused]);

    useEffect(() => {
        setSelectedRowsArray(Array.from(selectedRows));
    }, [selectedRows]);

    const [query, setQuery] = useState<Query>({
        $and: [
            {
                createdAt: {
                    $gte: dates[0]?.toISOString(),
                    $lte: dates[1]?.toISOString()
                }
            },
            {
                routingOutsourceId: { $ne: null }
            },
            {
                checkErpSync: {
                    $or: [
                        { $eq: null },
                        { $eq: "D" }
                    ],
                }
            }
        ],
    });


    const [search, setSearch] = useState<SearchProps>({
        pageSize: "10"
    });

    const { openModal } = useModal();

    // filterQueries가 변경되면 페이지를 1로 설정
    useEffect(() => {
        if (filterQueries) {
            setPage(1);
        }
    }, [filterQueries]);

    // 페이지가 변경되면 선택된 행 초기화
    useEffect(() => {
        if (activePage || filterQueries) {
            setSelectedRows(new Set());
        }
    }, [activePage, filterQueries])

    useEffect(() => {
        setQuery((prevQuery) => {
            // 기존 조건에서 filterQueries 관련 조건 제거
            const newAndConditions = prevQuery.$and.filter(
                (condition) => !Object.keys(filterQueries).includes(Object.keys(condition)[0])
            ).map((item) => {
                if (item.createdAt) {
                    return {
                        ...item,
                        createdAt: {
                            $gte: dates[0]?.toISOString(),
                            $lte: dates[1]?.toISOString(),
                        },
                    };
                }
                return item;
            });

            // filterQueries 추가
            Object.entries(filterQueries).forEach(([key, value]) => {
                if (value) {
                    newAndConditions.push({
                        [key]: { $iLike: '%' + value + '%' }
                    });
                }
            });

            // checkErpSync 조건이 이미 있는지 확인
            const hasCheckErpSync = newAndConditions.some(
                (condition) => condition.checkErpSync && condition.checkErpSync.$or
            );

            // checkErpSync 조건이 없고 filterErpSync가 true인 경우에만 추가
            if (filterErpSync && !hasCheckErpSync) {
                newAndConditions.push({
                    checkErpSync: {
                        $or: [
                            { $eq: null },
                            { $eq: "D" }
                        ]
                    }
                });
            }

            // checkErpSync 조건이 있고 filterErpSync가 false인 경우 제거
            if (!filterErpSync && hasCheckErpSync) {
                return {
                    $and: newAndConditions.filter(
                        (condition) => !(condition.checkErpSync && condition.checkErpSync.$or)
                    ),
                };
            }

            // 빈 객체 제거
            return { $and: newAndConditions.filter(condition => Object.keys(condition).length > 0) };
        });
    }, [dates, filterErpSync, filterQueries]);

    const { data: productionPerformanceOutsourcingData, refetch } = useQuery(productionPerformances.get({
        query: query,
        page: activePage,
        pageSize: Number(search.pageSize),
        sort: "-createdAt",
    }));

    // 외주 erp 전송
    const { mutate: postERP } = useMutation(
        (params: DefaultApiErpSyncPushErpSplitOutsourcingRecordPostRequest) =>
            mutatePalletErps.postERPoutsourcing(params).mutationFn(params as DefaultApiErpSyncPushErpSplitOutsourcingRecordPostRequest | any),
        {
            onMutate: () => {
                setLoading(true);
            },
            onSuccess: (res) => {
                if (res.data.success && res.data.success.length) {
                    const workStr = res.data.success.join(', ');
                    customAlert(`작업번호 ${workStr}번 실적이 성공적으로 전달되었습니다.`, '실적 전달', 'info')
                }

                if (res.data.fail && res.data.fail.length) {
                    const workStr = res.data.fail.join(', ');
                    customAlert(`작업번호 ${workStr}번 실적 전달이 실패했습니다.`, '실적 전달', 'red')
                }
                refetch().then(() => {
                    setLoading(false);
                    setSelectedRows(new Set());
                    setSelectedRowsArray([]);
                }).catch((error) => {
                    console.error('Error refetching data:', error);
                    setLoading(false);
                });
            },
            onError: (err) => {
                setLoading(false);
            }
        }
    )

    // 외주 erp 전송 취소
    const { mutate: cancelERP } = useMutation(
        (params: DefaultApiErpSyncDeleteErpSplitOutsourcingRecordPostRequest) =>
            mutatePalletErps.cancelERPoutsourcing(params).mutationFn(params as DefaultApiErpSyncDeleteErpSplitOutsourcingRecordPostRequest | any),
        {
            onMutate: () => {
                setLoading(true);
            },
            onSuccess: (res) => {
                if (res.data.success && res.data.success.length) {
                    const workStr = res.data.success.join(', ');
                    customAlert(`작업번호 ${workStr}번 실적이 성공적으로 취소되었습니다.`, '실적 취소', 'info')
                }

                if (res.data.fail && res.data.fail.length) {
                    const workStr = res.data.fail.join(', ');
                    customAlert(`작업번호 ${workStr}번 실적 취소가 실패했습니다.`, '실적 취소', 'red')
                }
                refetch().then(() => {
                    setLoading(false);
                    setSelectedRows(new Set());
                    setSelectedRowsArray([]);
                }).catch((error) => {
                    console.error('Error refetching data:', error);
                    setLoading(false);
                });
            },
            onError: (err) => {
                setLoading(false);
            }
        }
    )



    const rows: readonly SplitLotGet200ResponseRowsInner[] =
        productionPerformanceOutsourcingData?.data.rows ?? [];
    const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

    useEffect(() => {
        const initialInputValues: { [key: string]: string } = {};
        rows.forEach((row) => {
            if (row.id) {
                initialInputValues[row.id] = row.workDate ?? '';
            }
        });
        setInputValues(initialInputValues);
    }, [rows]);

    const columns: readonly Column<SplitLotGet200ResponseRowsInner | any>[] = [
        {
            ...SelectColumn,
            width: 70,
            maxWidth: 500,
            resizable: true,
            headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
            cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
        },
        {
            key: "checkErpSync",
            name: "전송여부",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <div>
                        {row.checkErpSync === "A" ? "전송완료" : row.checkErpSync === "D" ? "전송취소" : "전송대기"}
                    </div>
                )
            },
        },
        {
            key: "createdAt",
            name: "작업일",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <div>
                        {row.createdAt !== null ? dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss") : '-'}
                    </div>
                )
            }
        },
        {
            key: "erpSyncedAt",
            name: "전송일시",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <div>
                        {row.erpSyncedAt !== null ? dayjs(row.erpSyncedAt).format("YYYY-MM-DD HH:mm:ss") : '-'}
                    </div>
                )
            }
        },
        {
            key: "equipmentName",
            name: "설비명",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <DetailLink
                        onClick={() => openModal(
                            <EquipmentDetailForm equipmentCode={row.equipmentCode} />,
                            null,
                            "설비 상세"
                        )}
                    >
                        {row.equipmentName}
                    </DetailLink>
                )
            },
        },
        {
            key: "lotName",
            name: "LOT NO",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <DetailLink
                        onClick={() => openModal(
                            <ConsumeDetailForm consumeData={row.consumeLogs} />,
                            null,
                            ""
                        )}
                    >
                        {row?.lotName}
                    </DetailLink>
                );
            },
        },
        {
            key: "erpWorkOrderNo",
            name: "작업지시번호",
            sortable: true,
            resizable: true,
        },
        {
            key: "itemName",
            name: "품목명",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <DetailLink onClick={() => openModal(
                        <ItemsDetailForm itemCode={row.itemCode} />,
                        null,
                        "품목 상세"
                    )}>
                        {row.itemName}
                    </DetailLink>
                );
            },
        },
        {
            key: "itemSpec",
            name: "규격",
            sortable: true,
            resizable: true,
        },
        {
            key: "productionSum",
            name: "생산수량",
            sortable: true,
            resizable: true,
            cellClass: css`
                justify-content: flex-end;
            `,
        },
        {
            key: "totalQuantity",
            name: "양품수량",
            sortable: true,
            resizable: true,
            cellClass: css`
                justify-content: flex-end;
            `,
        },
        {
            key: "defects",
            name: "불량수량",
            sortable: true,
            resizable: true,
            cellClass: css`
                justify-content: flex-end;
            `,
            formatter: ({ row }) => {
                const totalDefects = row.defects?.reduce((sum: any, defect: SplitLotGet200ResponseRowsInnerDefectsInner) => sum + defect.defect_sum, 0) || 0;
                return (
                    <DetailLink onClick={() => openModal(
                        <DefectDetailForm defectData={row.defects} />,
                        null,
                        ""
                    )}>
                        <span>{setToLocaleString(totalDefects)}</span>
                    </DetailLink>
                );
            },
        },
        {
            key: "prevSplitLotCreatedAt",
            name: "작업시작 시간",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <div>
                        {row.prevSplitLotCreatedAt !== null ? dayjs(row.prevSplitLotCreatedAt).format("YYYY-MM-DD HH:mm:ss") : '-'}
                    </div>
                )
            }
        },
        {
            key: "createdAtEnd",
            name: "작업종료 시간",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <div>
                        {row.createdAt !== null ? dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss") : '-'}
                    </div>
                )
            }
        },
        {
            key: "timeDifference.hour",
            name: "작업 시간",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <div>
                        {
                            [
                                row.timeDifference?.days ? `${row.timeDifference.days}일 ` : '',
                                row.timeDifference?.hours ? `${row.timeDifference.hours}시간 ` : '',
                                row.timeDifference?.minutes ? `${row.timeDifference.minutes}분` : ''
                            ].join('')
                        }
                    </div>
                )
            }
        },
        {
            key: "creatorUserCode",
            name: "작업자",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <DetailLink onClick={() => openModal(<UsersDetailForm UserCode={row?.creatorUserCode} />, null, "")}>
                        {row?.creatorUserCode}
                    </DetailLink>
                );

            }
        },
        {
            key: "itemCode",
            name: "품목코드",
            sortable: true,
            resizable: true,
        },
        {
            key: "workDate",
            name: "납품일",
            sortable: true,
            resizable: true,
            width: 160,
            formatter: ({ row }) => {
                return (
                    <TextInput
                        key={row.id}
                        placeholder="YYYYMMDD"
                        value={inputValues[row.id] === undefined ? row.workDate : inputValues[row.id]}
                        onChange={(e) => handleInputChange(e, row.id)}
                        ref={(el) => (inputRefs.current[row.id] = el)}
                        onFocus={() => handleFocus(row.id)}
                        onBlur={() => setIsTableInputFocused(false)}
                        maxLength={8}
                        autoFocus={false}
                        rightSection={
                            <IconCalendar
                                onClick={() =>
                                    openModal(
                                        <Calendar dates={selectedDates[row.id] || [new Date(), new Date()]} type="default" />,
                                        null,
                                        ""
                                    ).then((result) => {
                                        if (isCalendarDate(result)) {
                                            handleDateChange(result, row.id);  // 각 row.id에 맞는 날짜 업데이트
                                        }
                                    })
                                }
                            />
                        }
                    />
                );
            },
        }
    ];

    useSub("excelDownLoad", async () => {

        try {
            setLoading(true);
            const response = await DefaultInstance.splitLotFindPost({
                query: JSON.stringify(query),
                sort: "-createdAt",
            });

            const data = response.data;

            const headerRow = [
                { v: "전송여부", t: "s", s: headerStyle },
                { v: "작업일", t: "s", s: headerStyle },
                { v: "전송일시", t: "s", s: headerStyle },
                { v: "설비명", t: "s", s: headerStyle },
                { v: "LOTNO", t: "s", s: headerStyle },
                { v: "작업지시번호", t: "s", s: headerStyle },
                { v: "품목명", t: "s", s: headerStyle },
                { v: "규격", t: "s", s: headerStyle },
                { v: "생산수량", t: "s", s: headerStyle },
                { v: "양품수량", t: "s", s: headerStyle },
                { v: "불량수량", t: "s", s: headerStyle },
                { v: "작업시작 시간", t: "s", s: headerStyle },
                { v: "작업종료 시간", t: "s", s: headerStyle },
                { v: "작업 시간", t: "s", s: headerStyle },
                { v: "작업자", t: "s", s: headerStyle },
                { v: "품목코드", t: "s", s: headerStyle },
                { v: "납품일", t: "s", s: headerStyle },
                { v: "자재소모내역", t: "s", s: headerStyle },
            ];
            const dataRows = data.map((item: SplitLotGet200ResponseRowsInner) => {
                const totalDefects = item.defects?.reduce((sum: number, defect: SplitLotGet200ResponseRowsInnerDefectsInner) => sum + (defect.defect_sum ?? 0), 0) ?? 0;

                return [
                    { v: item.checkErpSync === "A" ? "전송완료" : item.checkErpSync === "D" ? "전송취소" : "전송대기", t: "s", s: dataStyle },
                    { v: item.createdAt ? dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") : '-', t: "s", s: dataStyle },
                    { v: item.erpSyncedAt ? dayjs(item.erpSyncedAt).format("YYYY-MM-DD HH:mm:ss") : '-', t: "s", s: dataStyle },
                    { v: item.equipmentName ?? '', t: "s", s: dataStyle },
                    { v: item.lotName ?? '', t: "s", s: dataStyle },
                    { v: item.erpWorkOrderNo?.toString() ?? '', t: "s", s: dataStyle },
                    { v: item.itemName ?? '', t: "s", s: dataStyle },
                    { v: item.itemSpec?.toString() ?? '', t: "s", s: dataStyle },
                    { v: setToLocaleString(item.productionSum) ?? '', t: "s", s: dataStyle },
                    { v: setToLocaleString(item.totalQuantity) ?? '', t: "s", s: dataStyle },
                    { v: setToLocaleString(totalDefects), t: "s", s: dataStyle },
                    { v: item.prevSplitLotCreatedAt ? dayjs(item.prevSplitLotCreatedAt).format("YYYY-MM-DD HH:mm:ss") : '-', t: "s", s: dataStyle },
                    { v: item.createdAt ? dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") : '-', t: "s", s: dataStyle },
                    { v: `${item.timeDifference?.days?.toString() ?? '-'}일 ${item.timeDifference?.hours?.toString() ?? '-'}시간 ${item.timeDifference?.minutes?.toString() ?? '-'}분`, t: "s", s: dataStyle },
                    { v: item.creatorUserCode ?? "", t: "s", s: dataStyle },
                    { v: item.itemCode ?? '', t: "s", s: dataStyle },
                    { v: item.workDate ?? '', t: "s", s: dataStyle },
                    {
                        v: item.consumeLogs?.map((consume) => {
                            const lotName = consume?.lotName ? `로트명 - ${consume.lotName}` : '';
                            const itemCode = consume?.itemCode ? `품목코드 - ${consume.itemCode}` : '';
                            const quantity = consume?.quantity ? `수량 : ${consume.quantity}` : '';

                            // 값이 있는 항목만 필터링하여 문자열로 결합
                            return [lotName, itemCode, quantity].filter(Boolean).join(' ');
                        }).join(' ') ?? '',
                        t: "s",
                        s: dataStyle
                    },
                ];
            }) ?? [];

            await excelDownLoad({
                headerRow,
                dataRows,
                colWidths: [120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 280],
                fileName: '외주실적조회'
            });

        } catch (e) {
            throw e;
        } finally {
            setLoading(false);
        }
    }, [JSON.stringify(query)]);

    return (
        <WorkLogsTableWrapper>
            <LoadingOverlay />
            <GridWrapper isDarkMode={isDarkMode}>
                <TableWrapper>
                    <RetrieveWrapper>
                        <ChipsBox>
                            <Chip
                                variant="filled" size="sm" radius="xs"
                                checked={filterErpSync || false}
                                onClick={() => setFilterErpSync(prev => !prev)}
                            >
                                ERP 전송 조회
                            </Chip>
                        </ChipsBox>
                        <Flex gap="1rem">
                            <Button
                                leftIcon={<IconFolder />}
                                onClick={() => {
                                    const OSPDelvDates = selectedRowsArray.map((id) => valuesArray[id]?.[valuesArray[id]?.length - 1] || null);
                                    const workDate = selectedRowsArray.map((id) =>
                                        rows.map((row) => row.id === id ? dayjs(row.createdAt).format('YYYYMMDD') : null)
                                            .filter((item): item is string => item !== null)[0]
                                    );

                                    const finalOSPDelvDates = OSPDelvDates.map((OSPDelvDate, index) => {
                                        return OSPDelvDate !== null && OSPDelvDate !== undefined ? OSPDelvDate : workDate[index];
                                    });

                                    postERP({
                                        erpSyncPushErpSplitOutsourcingRecordPostRequest: {
                                            workLogIds: selectedRowsArray,
                                            OSPDelvDates: finalOSPDelvDates
                                        }
                                    });
                                }}
                                disabled={(rows.some((row) => selectedRowsArray.includes(row.id) && row.checkErpSync === "A")) || loading}
                            >
                                외주실적 전송
                            </Button>
                            <Button
                                color="orange"
                                leftIcon={<IconFolderCancel />}
                                onClick={() => cancelERP({
                                    erpSyncPushErpSplitWorkRecordPostRequest: {
                                        workLogIds: selectedRowsArray,
                                    }
                                })}
                                disabled={(rows.some((row) => selectedRowsArray.includes(row.id) && row.checkErpSync !== "A")) || loading}
                            >
                                외주실적 전송 취소
                            </Button>
                            <Button
                                color="teal"
                                rightIcon={<IconFileSpreadsheet />}
                                onClick={() => {
                                    publish("excelDownLoad");
                                }}
                            >엑셀 다운로드
                            </Button>
                        </Flex>
                    </RetrieveWrapper>
                    <DataGrid
                        columns={columns ?? []}
                        rows={rows as any}
                        rowHeight={40}
                        rowKeyGetter={(row) => row.id}
                        sortColumns={sortColumns}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={(e) => {
                            setSelectedRows(e);
                            const newSelectedRows = new Set(selectedRowsRef.current);
                            newSelectedRows.add(e);
                            selectedRowsRef.current = newSelectedRows;
                        }}
                    />
                    <ProductionPerformanceTotalTable selectedRows={selectedRows} />
                </TableWrapper>
                <PaginationWrapper>
                    <Pagination
                        onChange={setPage}
                        value={activePage}
                        total={productionPerformanceOutsourcingData?.data?.totalPages ?? 0}
                        size="lg"
                        radius="sm"
                    />
                </PaginationWrapper>
            </GridWrapper>
        </WorkLogsTableWrapper>
    );
};

const WorkLogsTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
        props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
        props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap : 10px;
`;

const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;
const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
