import styled from '@emotion/styled';
import React from 'react';


interface Props {

    type : 'hour' | 'minute' | 'second',
    active : boolean 
    time : string
    setTime : (data : any) => void
    times : any
}


const DirectTimeInput:React.FunctionComponent<Props> = ({type , active , time , setTime , times}) => {


    const unitOfType = React.useMemo(() => {
        switch(type){
            case 'hour' :
                return '시'
            case 'minute' :
                return '분'
            case 'second' :
                return '초'
        }
    },[type])

    const maxOfType =  React.useMemo(() => {
        switch(type){
            case 'hour' :
                return '23'
            case 'minute' :
                return '59'
            case 'second' :
                return '59'
        }
    },[type])

    const handleChange = (event : any) => {
        let { value, min, max } = event.target;
        let filterValue = String(Math.max(Number(min), Math.min(Number(max), Number(value))));

        if(filterValue.length < 2){
            filterValue = `0${filterValue}`
        }
        setTime({...times , [type] : filterValue , isChange : true});
    };

    return (
        <TimeBox style={{display :'flex'}}>
            <TimeInput 
                type='number'
                min='0'
                max={maxOfType}
                onChange={handleChange}
                value={time}
                readOnly={!active}
                />
            <UnitP>
                {unitOfType}
            </UnitP>
        </TimeBox>


    )

}

export default DirectTimeInput

const TimeBox = styled.div`

    display : flex;
    margin-right : 40px;
`


const TimeInput = styled.input<any>`
    
    width : 80px;
    height : 32px; 
    background : #F4F6FA;
    border : 0;
    text-align : center;
    font-size : 15px;
    color : ${(props) => props.readOnly ? 'gray' : 'black'};

    &:focus-within {
        outline: none;
        box-shadow: ${(props) => props.readOnly ? 'none': '0px 0px 7px #19B9DF'}
    }

`

const UnitP = styled.p`

    margin: 6px -20px;
    font-size: 15px;

`