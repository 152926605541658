import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlayerPause, IconPlayerPlay, IconRepeat, IconX } from "@tabler/icons-react";

export type StatusObjType = {
  [key: string]: string;
};

export const trackingStatusColor: StatusObjType = {
  INPUT: "blue.7",
  CANCEL_INPUT: "red.7",
  CONSUME: "green.7",
  CONSUME_IDENTIFIED: "purple.7",
  CANCEL_CONSUME_IDENTIFIED: "yellow.7",
  PROGRESS: "orange.7",
  PRODUCTION: "teal.7",
  DEFECT: "pink.7",
  LOSS: "indigo.7",
  WIP_DECREASE: "cyan.7",
  REWORK: "lime.7",
  RESTORE: "lime.5",
  WIP_INCREASE: "deepPurple.7",
  START: "blue.6",
  END: "red.6",
  TIME_TRACKING_START: "green.6",
  TIME_TRACKING_END: "orange.6",
  TIME_TRACKING_PAUSE: "orange.6",
  TIME_TRACKING_RESUME: "lime.6",
  CANCEL_CONSUME: "pink.5",
  CANCEL_PROGRESS: "green.5",
  CANCEL_PRODUCTION: "blue.5",
  CANCEL_DEFECT: "red.5",
  CANCEL_REWORK: "blue.5",
  CANCEL_START: "orange.6",
  CANCEL_END: "cyan.6",
  CANCEL_LOSS: "indigo.6",
  PERFORMANCE: "green.5",
  CANCEL_WIP_DECREASE: "purple.6",
  SPLIT_LOT: "teal.6",
  CORRECTION: "teal.4",
  CANCEL_PERFORMANCE: "green.4",
  RECYCLE: 'green.4'
};



export const statusObj: StatusObjType = {
  INPUT: '투입',
  CANCEL_INPUT: '투입 취소',
  CONSUME: "소모",
  CONSUME_IDENTIFIED: "식별된 소모",
  CANCEL_CONSUME_IDENTIFIED: "식별된 소모 취소",
  PROGRESS: "진행중",
  PRODUCTION: "생산",
  DEFECT: "불량",
  LOSS: "손실",
  WIP_DECREASE: "재공재고 감소",
  // REWORK: "복원",
  // RESTORE: "복원",
  WIP_INCREASE: "재공재고 증가",
  START: "시작",
  END: "종료",
  TIME_TRACKING_START: "작업시작",
  TIME_TRACKING_END: " 작업종료",
  TIME_TRACKING_PAUSE: "작업지연",
  TIME_TRACKING_RESUME: "작업재개",
  CANCEL_CONSUME: "소모 취소",
  CANCEL_PROGRESS: "진행 취소",
  CANCEL_PRODUCTION: "생산 취소",
  CANCEL_DEFECT: "불량 취소",
  // CANCEL_REWORK: "복원 취소",
  CANCEL_START: "시작 취소",
  CANCEL_END: "종료 취소",
  CANCEL_LOSS: "손실 취소",
  PERFORMANCE: "생산 시작",
  CANCEL_WIP_DECREASE: "재공재고 감소 취소",
  SPLIT_LOT: "LOT 분할",
  CORRECTION: "작업 보정",
  CANCEL_PERFORMANCE: "실적 취소",
  RECYCLE: '재활용'
};

export const setWorkStatus = (
  status: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum | undefined
): string => {
  if (!status) return "";
  return statusObj[status];
};

export const setStatusIcon = (status: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum) => {
  const color = trackingStatusColor[status];

  switch (status) {
    case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_START:
      return { icon: IconPlayerPlay, color };
    case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END:
      return { icon: IconX, color };
    case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_PAUSE:
      return { icon: IconPlayerPause, color };
    case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_RESUME:
      return { icon: IconRepeat, color };
    default:
      return { icon: IconX, color };
  }
};