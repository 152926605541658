

import { useModal } from "@/features/modal/ModalStackManager";
import { PartnerCreateForm } from "@/features/partner/form/createForm";
import { PartnerTable } from "@/features/partner/table";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const DefectsWrap = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  CreateForm: PartnerCreateForm,
});

export const PartnerPage = () => {
  const { openModal } = useModal();

  const publish = usePub();

  return (
    <DefectsWrap.Wrapper>
      <DefectsWrap.ActionHeader>
        <DefectsWrap.ActionButton
          leftIcon={<IconPlus />}
          onClick={() =>
            openModal(<DefectsWrap.CreateForm />, null, "협력사 추가", true)
          }
        >
          추가
        </DefectsWrap.ActionButton>
        <DefectsWrap.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => publish("deleteSelectedItems")}
        >
          선택된 행 삭제
        </DefectsWrap.ActionButton>
      </DefectsWrap.ActionHeader>
      <DefectsWrap.Header>
        <DefectsWrap.HeaderTitle>협력사</DefectsWrap.HeaderTitle>
        <DefectsWrap.HeaderSubTitle>
          우리 회사에서 다루는 협력사에 대해 조회할 수 있는 페이지 입니다.
        </DefectsWrap.HeaderSubTitle>
      </DefectsWrap.Header>
      <PartnerTable />
    </DefectsWrap.Wrapper>
  );
};
