import DefaultInstance from "@/instance/axios";
import { createMutationKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiRoutingBomsPostRequest, DefaultApiRoutingBomsRoutingBomIdDeleteRequest, DefaultApiRoutingBomsRoutingBomIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";



const ROUTING_BOMS_KEY = 'routingBoms';

export const mutateRoutingBoms = createMutationKeys(ROUTING_BOMS_KEY, {

    create: (params: DefaultApiRoutingBomsPostRequest) => ({
        mutationKey: [params.routingBomsGetRequest],
        mutationFn: () => DefaultInstance.routingBomsPost(params)
    }),
    update: (params: DefaultApiRoutingBomsRoutingBomIdPutRequest) => ({
        mutationKey: [params.routingBomId, params.routingBomsGetRequest],
        mutationFn: () => DefaultInstance.routingBomsRoutingBomIdPut(params)
    }),

    delete: (params: DefaultApiRoutingBomsRoutingBomIdDeleteRequest) => ({
        mutationKey: [params.routingBomId],
        mutationFn: () => DefaultInstance.routingBomsRoutingBomIdDelete(params)
    })
});

export const routingsQueryKeys = mergeQueryKeys(mutateRoutingBoms);