import { inspections } from "@/api/inspect/inspectSpecification/useInspectionQuery";
import { DateSlashtimeUtil } from "@/utils/dateTimeUtil";
import { Container, Table, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";

interface DetailFormProps {
    inspectionId: number;
}

export const InspectionDetailForm = (props: DetailFormProps) => {
    const { inspectionId } = props;

    const { data } = useQuery(inspections.detail({
        inspectionId: inspectionId,
        populate: ["lot"],
    }))
    const inspectionData = data?.data;

    let recordIndex = 0; // recordIndex 초기화

    const tBodyRows = inspectionData?.specificationData?.criteria?.map((criteria, index) => {

        return (
            <>
                <tr key={index} style={{ fontWeight: "bold" }}>
                    <td>{index + 1}. {criteria.name}</td>
                    <td>{criteria.type === 'boolean' ? '체크' : ''}</td>
                    {inspectionData?.record?.[recordIndex]?.map((recordItem: any, itemIndex: number) => {
                        return (
                            <td key={itemIndex}>
                                {recordItem?.isSaved ? (recordItem?.value ? '합격' : '불합격') : ""}
                            </td>
                        )
                    })}
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={2} style={{ textAlign: 'center' }}>검사자</td>
                    {inspectionData?.record?.[recordIndex]?.map((recordItem: any, itemIndex: number) => {
                        return (
                            <td key={itemIndex}>
                                {recordItem?.isSaved ? (recordItem?.creatorUserName) : ""}
                            </td>
                        )
                    })}
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={2} style={{ textAlign: 'center' }}>검사일</td>
                    {inspectionData?.record?.[recordIndex]?.map((recordItem: any, itemIndex: number) => {
                        return (
                            <td key={itemIndex}>
                                {recordItem?.isSaved ? (DateSlashtimeUtil(recordItem?.createdAt)) : ""}
                            </td>
                        )
                    })}
                    <td></td>
                </tr>
            </>
        );
    });

    return (
        <Container w={"80rem"}>
            <Title order={2} align="center" mt="md" mb="md">
                초중종물 체크시트
            </Title>
            <Table withBorder withColumnBorders mb={"1rem"}>
                <thead>
                    <tr>
                        <th>품목명</th>
                        <th>{inspectionData?.itemCode}</th>
                        <th>로트명</th>
                        <th>{inspectionData?.lot?.name}</th>
                        <th>생산계획번호</th>
                        <th>{inspectionData?.productionPlanId}</th>
                    </tr>
                </thead>
            </Table>
            <Table striped highlightOnHover withBorder withColumnBorders>
                <thead>
                    <tr>
                        <th>검사항목</th>
                        <th>검사방법</th>
                        <th colSpan={3}></th>
                        <th>비고</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>x1</th>
                        <th>x2</th>
                        <th>x3</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tBodyRows}
                </tbody>
            </Table>
            {/* <Flex justify="end" mt="md">
                <Button>
                    인쇄하기
                </Button>
            </Flex> */}
        </Container>
    )
}