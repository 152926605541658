import { ScmInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import { ScmApiTransportationFindPostRequest, ScmApiTransportationGetRequest, ScmApiTransportationPostRequest, ScmApiTransportationTransportationIdDeleteRequest, ScmApiTransportationTransportationIdGetRequest, ScmApiTransportationTransportationIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const transportations = createQueryKeys('transportation', {
    all: null,
    get: (params: ScmApiTransportationGetRequest) => ({
        queryKey: [params],
        queryFn: () => ScmInstance.transportationGet(params),
    }),
    detail: (params: ScmApiTransportationTransportationIdGetRequest) => ({
        queryKey: [params.transportationId],
        queryFn: () => ScmInstance.transportationTransportationIdGet(params)
    }),
    find: (params: ScmApiTransportationFindPostRequest) => ({
        queryKey: [params],
        queryFn: () => ScmInstance.transportationFindPost(params),
    }),
});

export const mutateTransportation = createMutationKeys('transportation', {
    create: (params: ScmApiTransportationPostRequest) => ({
        mutationKey: [params.transportationGetRequest],
        mutationFn: () => ScmInstance.transportationPost(params),
    }),
    update: (params: ScmApiTransportationTransportationIdPutRequest) => ({
        mutationKey: [params.transportationGetRequest, params.transportationId],
        mutationFn: () => ScmInstance.transportationTransportationIdPut(params)
    }),
    delete: (params: ScmApiTransportationTransportationIdDeleteRequest) => ({
        mutationKey: [params.transportationId],
        mutationFn: () => ScmInstance.transportationTransportationIdDelete(params)
    }),
})