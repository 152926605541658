import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

const Update = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
});

export const BOMUpdateForm = (params: any) => {
    const { formatterProps } = params;
    const { closeModal } = useModal();

    const form = useForm({
        initialValues: {
            partnerCode: formatterProps.partnerCode ?? undefined,
            modelName: formatterProps.modelName ?? undefined,
            bomCode: formatterProps.bomCode ?? undefined,
            itemName: formatterProps.itemName ?? undefined,
            spec: formatterProps.spec ?? undefined,
            itemType: formatterProps.itemType ?? undefined,
            unitText: formatterProps.unitText ?? undefined,
            operationCode: formatterProps.operationCode ?? undefined,
        }
    })

    const onSubmit = async () => {
        try {
            closeModal(form.values);

            customAlert("BOM 수정에 실패하였습니다.", '수정 실패', 'red')
        } catch (e) {
            customAlert(
                "BOM 수정에 실패하였습니다.",
                "수정 실패",
                "red"
            )
        }
    };

    return (
        <Update.Wrapper>
            <Update.Input
                withAsterisk
                label="협력사"
                placeholder="협력사"
                {...form.getInputProps("partnerCode")}
            />
            <Update.Input
                withAsterisk
                label="모델명"
                placeholder="모델명"
                {...form.getInputProps("modelName")}
            />
            <Update.Input
                withAsterisk
                label="BOM 코드"
                placeholder="BOM 코드"
                {...form.getInputProps("bomCode")}
            />
            <Update.Input
                withAsterisk
                label="품목명"
                placeholder="품목명"
                {...form.getInputProps("itemName")}
            />
            <Update.Input
                withAsterisk
                label="규격"
                placeholder="규격"
                {...form.getInputProps("spec")}
            />
            <Update.Input
                withAsterisk
                label="품목유형"
                placeholder="품목유형"
                {...form.getInputProps("itemType")}
            />
            <Update.Input
                withAsterisk
                label="단위"
                placeholder="단위"
                {...form.getInputProps("unitText")}
            />
            <Update.Input
                withAsterisk
                label="공정코드"
                placeholder="공정코드"
                {...form.getInputProps("operationCode")}
            />
            <Update.ButtonBox>
                <Update.Button color="gray" onClick={closeModal}>취소</Update.Button>
                <Update.Button
                    disabled={validateFormValues({
                        pageType: "bom",
                        values: form.values,
                    })}
                    onClick={onSubmit}
                >
                    BOM 수정
                </Update.Button>
            </Update.ButtonBox>
        </Update.Wrapper>
    )
};