import { useModal } from "@/features/modal/ModalStackManager";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import styled from "@emotion/styled";
import { ActionIcon, Button, FileInput, Flex, Select, Text } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { IconCalendar } from "@tabler/icons-react";
import { useState } from "react";
import DataGrid, { Column, SelectColumn } from "react-data-grid";

type MoldRepair = {
    code: string | undefined;
    name: string | undefined;
    cavity: string | undefined;
    spm: string | undefined;
    slideLocation: string | undefined;
    maxBats: string | undefined;
    inspectionBats: string | undefined;
    currentBats: string | undefined;
    order: string | undefined;
    requestData: Date;
    requestInfo: string | undefined;
    questionType: string | undefined;
    requestUser: string | undefined;
    requestCompleteDate: Date;
};

export const MoldRepairsPage = () => {
    const { openModal } = useModal();
    const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

    const form = useForm({
        initialValues: {
            moldRepair: [
                {
                    code: undefined,
                    name: undefined,
                    cavity: undefined,
                    spm: undefined,
                    slideLocation: undefined,
                    maxBats: undefined,
                    inspectionBats: undefined,
                    currentBats: undefined,
                    order: undefined,
                    requestData: new Date(),
                    requestInfo: undefined,
                    questionType: undefined,
                    requestUser: undefined,
                    requestCompleteDate: new Date(),
                }
            ] as MoldRepair[]
        }
    });

    const handleRowAdd = () => {
        form.insertListItem("moldRepair", {
            code: undefined,
            name: undefined,
            cavity: undefined,
            spm: undefined,
            slideLocation: undefined,
            maxBats: undefined,
            inspectionBats: undefined,
            currentBats: undefined,
            order: undefined,
            requestData: new Date(),
            requestInfo: undefined,
            questionType: undefined,
            requestUser: undefined,
            requestCompleteDate: new Date(),
        });
    };

    const columns: readonly Column<MoldRepair>[] = [
        {
            ...SelectColumn,
            width: 70,
            maxWidth: 500,
            resizable: true,
        },
        {
            key: "code",
            name: "금형코드",
            sortable: true,
            resizable: true,
            frozen: true,
        },
        {
            key: "name",
            name: "금형명",
            width: 240,
            sortable: true,
            resizable: true,
            frozen: true,
            formatter: () => {
                return <MoldAutoComplete withinPortal />;
            }
        },
        {
            key: "cavity",
            name: "캐비티",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return <Text w="100%" ta="right">{formatterProps.row.cavity ?? 0} EA</Text>;
            }
        },
        {
            key: "spm",
            name: "SPM",
            sortable: true,
            resizable: true,
        },
        {
            key: "slideLocation",
            name: "슬라이드 위치",
            sortable: true,
            resizable: true,
        },
        {
            key: "maxBats",
            name: "최대 타수",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (<Text w="100%" ta="right">{formatterProps.row.maxBats}</Text>);
            }
        },
        {
            key: "inspectionBats",
            name: "점검 타수",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (<Text w="100%" ta="right">{formatterProps.row.inspectionBats}</Text>);
            }
        },
        {
            key: "currentBats",
            name: "현재 타수",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (<Text w="100%" ta="right">{formatterProps.row.currentBats}</Text>);
            }
        },
        {
            key: "order",
            name: "우선순위",
            sortable: true,
            resizable: true,
            formatter: () => {
                return (<Select defaultValue="일반" data={["일반", "긴급"]} withinPortal />);
            }
        },
        {
            key: "requestData",
            name: "요청 날짜(필수)",
            sortable: true,
            resizable: true,
            formatter: () => {
                return (
                    <ActionIcon
                        onClick={() => {
                            openModal(<DatePicker />, null, "날짜 선택");
                        }}
                    >
                        <IconCalendar />
                    </ActionIcon>
                );
            }
        },
        {
            key: "requestInfo",
            name: "요청 사진",
            sortable: true,
            resizable: true,
            formatter: () => {
                return <FileInput withAsterisk />;
            }
        },
        {
            key: "questionType",
            name: "문제유형(필수)",
            sortable: true,
            resizable: true,
            formatter: () => {
                return <Select defaultValue="점검" data={[{ label: "점검", value: "점검" }]} withinPortal />;
            }
        },
        {
            key: "requestUser",
            name: "요청자",
            sortable: true,
            resizable: true,
        },
        {
            key: "requestCompleteDate",
            name: "완료 요청 날짜",
            sortable: true,
            resizable: true,
            formatter: () => {
                return (
                    <ActionIcon
                        onClick={() => {
                            openModal(<DatePicker />, null, "날짜 선택");
                        }}
                    >
                        <IconCalendar />
                    </ActionIcon>
                );
            }
        },
    ];

    return (
        <Flex gap="md" direction="column" w="100%" h="100%" align="center">
            <Flex gap="md" justify="space-between" w="100%">
                <Text size="xl">금형 수리 요청 등록</Text>
                <Flex gap="md">
                    <Button onClick={handleRowAdd}>행 추가</Button>
                    <Button onClick={() => { }}>선택한 행 삭제</Button>
                    <Button onClick={() => { }}>선택한 행 저장</Button>
                </Flex>
            </Flex>
            <GridWrapper direction="column" w="100%">
                <DataGrid
                    columns={columns}
                    rows={form.values.moldRepair}
                    rowHeight={40}
                    rowKeyGetter={(row: MoldRepair) => row.code as string}
                    selectedRows={selectedRows}
                    onSelectedRowsChange={setSelectedRows}
                />
            </GridWrapper>
        </Flex>
    );
};

const GridWrapper = styled(Flex)`
  & *[role="grid"] {
    height: inherit;
  }

  & *[role="columnheader"] {
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    box-shadow: none;
    display: flex;
    align-items: center;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    box-shadow: none;
    width: 100%;
  }
`;
