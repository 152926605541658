import { KpiInstance } from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { KpiApiKpiCapacityUtilizationGetRequest, KpiApiKpiDefectRateGetRequest, KpiApiKpiElectricPowerGetRequest, KpiApiKpiLeadTimeGetRequest, KpiApiKpiManHourCostGetRequest, KpiApiKpiOrderLeadTimeGetRequest, KpiApiKpiUphGetRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const KPI = createQueryKeys('kpi', {
    all : null,
    leadTime : (params : KpiApiKpiLeadTimeGetRequest) => {
        return {
            queryKey : [params],
            queryFn : () => KpiInstance.kpiLeadTimeGet(params)
        }
    },
    manhour : (params : KpiApiKpiManHourCostGetRequest) => {
        return {
            queryKey : [params],
            queryFn : () => KpiInstance.kpiManHourCostGet(params)
        }
    },
    defectRate : (params : KpiApiKpiDefectRateGetRequest) => {
        return {
            queryKey : [params],
            queryFn : () => KpiInstance.kpiDefectRateGet(params)
        }
    },
    UPH : (params : KpiApiKpiUphGetRequest) => {
        return {
            queryKey : [params],
            queryFn : () => KpiInstance.kpiUphGet(params)
        }
    },
    leadTimeOrder : (params : KpiApiKpiOrderLeadTimeGetRequest) => {
        return {
            queryKey : [params],
            queryFn : () => KpiInstance.kpiOrderLeadTimeGet(params)
        }
    },
    powerUsage : (params : KpiApiKpiElectricPowerGetRequest) => {
        return {
            queryKey : [params],
            queryFn : () => KpiInstance.kpiElectricPowerGet(params)
        }
    },
    rateOfOperation : (params : KpiApiKpiCapacityUtilizationGetRequest) => {
        return {
            queryKey : [params],
            queryFn : () => KpiInstance.kpiCapacityUtilizationGet(params)
        }
    },
});