import { ApexOptions } from 'apexcharts'
import React from 'react'
import ReactApexChart from 'react-apexcharts'

interface Props {
  type: 'area' | 'line' | 'bar'
  series: ApexAxisChartSeries | ApexNonAxisChartSeries
  options: ApexOptions
  height?: number
}

const FrequentlyAreaChart: React.FunctionComponent<Props> = ({ type, series, options, height }) => {
  return (
    <div>
      <ReactApexChart options={options} series={series} type={type} height={height}/>
    </div>
  )
}

export default React.memo(FrequentlyAreaChart)
