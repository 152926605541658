import { useModal } from "@/features/modal/ModalStackManager";
import { MachineSearchBox } from "@/features/ui/PMS/machineSearchBox";
import CustomTable from "@/features/ui/table/CustomTable";
import { ActionIcon, Flex, Text } from "@mantine/core";
import { IconReportSearch } from "@tabler/icons-react";


export const AnalysisErrorPage = () => {
    const { openModal } = useModal();

    const Header = {
        error: ['기계 제조사', '기계 이름', '제조 연월일', '제조 번호'],
        errorHistory: ['기계 이름', '에러 시작 일시', '에러 종료 일시', '에러 번호', '명칭', '키캠 상태', '로드톤'],
        errorView: ['에러 번호', '명칭', '총 에러 발생 시간', '평균 에러 해제 시간', '발생 횟수', '(에러 발생 횟수)']
    }

    const BodyData = {
        error: [{ '기계 제조사': '제조사1', '기계 이름': '이름1', '제조 연월일': '2022-01-01', '제조 번호': '1234' }],
        errorHistory: [{ '기계 이름': '기계1', '에러 시작 일시': '2022-01-01', '에러 종료 일시': '2022-01-02', '에러 번호': '1', '명칭': '에러1', '키캠 상태': '상태1', '로드톤': '1 ton' }],
        errorView: [{ '에러 번호' : '1', '명칭' : '에러1', '총 에러 발생 시간' : '1 hour', '평균 에러 해제 시간' : '1 hour', '발생 횟수' : '1', '(에러 발생 횟수)' : '1' }]
    }
    
    return (
        <>
            <Flex
                gap="md"
                direction="column"
            >
                <Flex
                    gap="md"
                    direction="column"
                >
                    <Flex
                        justify='space-between'
                    >
                        <Text size="xl">에러</Text>
                        <ActionIcon
                            onClick={() => {
                                openModal(<MachineSearchBox />, null, '기계 검색')
                            }}
                        >
                            <IconReportSearch />
                        </ActionIcon>
                    </Flex>
                    <CustomTable
                        headers={Header.error}
                        data={BodyData.error}
                    />
                </Flex>
                <Flex
                    gap="md"
                    direction="column"
                >
                    <Flex
                        justify='space-between'
                    >
                        <Text size="xl">에러 이력</Text>
                    </Flex>
                    <CustomTable
                        headers={Header.errorHistory}
                        data={BodyData.errorHistory}
                    />
                </Flex>
                <Flex
                    gap="md"
                    direction="column"
                >
                    <Flex
                        justify='space-between'
                    >
                        <Text size="xl">에러별 보기</Text>
                    </Flex>
                    <CustomTable
                        headers={Header.errorView}
                        data={BodyData.errorView}
                    />
                </Flex>
            </Flex>
        </>
    )
}