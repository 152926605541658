import { moldStocks } from "@/api/moldStock/useMoldStockQuery";
import { MoldStockDetailInform } from "@/features/moldStock/inform/moldStockInForm";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs } from "@mantine/core";
import { MoldStocksGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";

const Detail = Object.assign(Main, {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
})

export interface DetailMoldInformProps {
    moldStockIdData?: MoldStocksGet200ResponseRowsInner | any;
    moldStockCode: string | undefined;
}

export const MoldStockDetailForm = ({ moldStockCode }: DetailMoldInformProps) => {

    const { data: moldStock } = useQuery(moldStocks.get({
        query: { $and: [{ "code": { "$eq": moldStockCode } }] },
    }));

    return (
        <>
            <Detail.Container>
                <Detail.Wrapper>
                    <Detail.Content>
                        <MoldStockDetailInform data={moldStock} />
                        <Tabs
                            variant="outline"
                            defaultValue="lot"
                            styles={(theme) => ({
                                tab: {
                                    "&:not([data-active])": {
                                        backgroundColor: theme.colors?.gray?.[0],
                                    },
                                },
                            })}
                        >
                            <Tabs.List>
                                <LeftSpace />
                                <Tabs.Tab value="routing">금형 점검 기록</Tabs.Tab>
                                <Tabs.Tab value="inpectStandard">금형 사용 기록</Tabs.Tab>
                                <RightSpace />
                            </Tabs.List>
                            <Tabs.Panel value="routing" p={20}>
                                1
                            </Tabs.Panel>
                            <Tabs.Panel value="inpectStandard">
                                2
                            </Tabs.Panel>
                        </Tabs>
                    </Detail.Content>
                </Detail.Wrapper>
            </Detail.Container>
        </>
    )
}

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
