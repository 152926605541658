import styled from '@emotion/styled'
import React from 'react'

interface Props {
  styles?: Record<string, string | number | undefined>,
  children: any
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const FrequentlyFlexMiddle: React.FunctionComponent<Props> = ({ styles, children }) => {
  return (
    <Container style={styles}>
      {children}
    </Container>
  )
}

export default FrequentlyFlexMiddle
