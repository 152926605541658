import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { items } from "@/api/items/useItemQuery";
import { mutateLots } from "@/api/lots/useLotsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { LotMutationFromProps } from "@/features/stockPlan/form/stockPlanIncomingForm";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { LotSelectComplete } from "@/features/ui/autoComplete/lot/lot-\bselectComplete";
import useGetLotsIdQuery from "@/features/ui/autoComplete/lot/select-useGetLotsQuery";
import { LotForm } from "@/features/ui/form/lots/form";
import { Button, Flex, Space, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, DefaultApiLotsPostRequest, DefaultApiWmsIncomingPostRequest, WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { EventHandler, useState } from "react";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Date: DateInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    ItemAutoComplete: ItemAutoComplete,
});

export const DeliveryIncomingForm = (params: { formatterProps: AuthSignupPost201ResponseEquipmentPurchaseOrderItem }) => {
    const { closeModal, openModal } = useModal();
    const { formatterProps } = params;
    const [newLot, setNewLot] = useState<LotMutationFromProps>();
    const queryClient = useQueryClient();
    const { mutate: incomingMutate } = useMutation(
        (params: DefaultApiWmsIncomingPostRequest) =>
            mutateInventories.incoming(params).mutationFn(params as DefaultApiWmsIncomingPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["inventories"]);
                queryClient.invalidateQueries(["purchaseOrderItems"]);
            }
        }
    )

    const { mutate: postLotsMutate } = useMutation(
        (params: DefaultApiLotsPostRequest) =>
            mutateLots.create(params).mutationFn(params as DefaultApiLotsPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["lots"]);
            }
        }
    );


    const form = useForm<Partial<WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner>>({
        initialValues: {
            itemCode: formatterProps.itemCode,
            locationCode: formatterProps?.targetLocationCode,
            quantity: undefined,
            lotId: undefined,
            lotInfo: {
                name: undefined,
                expiration: undefined,
            },
            purchaseOrderItemId: formatterProps.id,
        },
    });

    const { data: itemData } = useQuery(items.codeSearch({
        query: form.values.itemCode
            ? { $and: [{ code: { $eq: form.values.itemCode } }] }
            : "",
    }));

    const { data: lotData } = useGetLotsIdQuery(
        Number(form.values?.lotId) || Number(newLot?.id),
        !!form.values?.lotId || !!newLot?.id
    );

    const onSubmit = () => {
        try {
            const { itemCode, locationCode, quantity } = form.values;
            if (!itemCode && !locationCode && !quantity) return;

            incomingMutate(
                {
                    workLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner: {
                        itemCode:
                            formatterProps.itemCode ?? '',
                        locationCode:
                            form.values.locationCode!,
                        quantity: form.values.quantity as string,
                        lotId: Number(newLot?.id ?? form.values.lotId),
                        lotInfo: {
                            name:
                                lotData?.name ?? newLot?.name,
                            expiration:
                                lotData?.expiration ?? newLot?.expiredAt,
                        },
                        purchaseOrderItemId: formatterProps.id,
                        actionFrom: 'WEB'
                    },
                },
                {
                    onSuccess: (res: { status: number }) => {
                        closeModal(form.values);
                        if (res.status === 200) {
                            customAlert(
                                "납품에 성공하였습니다.",
                                "납품 성공",
                                "green"
                            )
                        } else {
                            customAlert(
                                "납품에 실패하였습니다.",
                                "납품 실패",
                                "red"
                            )
                        }
                    },
                }
            );
        } catch (e) {
            customAlert(
                "납품에 실패하였습니다.",
                "납품 실패",
                "red"
            )
        }
    };

    const onCloseHandler: EventHandler<any> = (values) => {
        postLotsMutate(
            {
                lotsGetRequest: {
                    name: values.name!,
                    expiration: values.expiredAt!,
                    itemCode: values.itemCode!,
                },
            },
            {
                onSuccess: (data) => {
                    setNewLot(data?.data as LotMutationFromProps | undefined);
                    form.setFieldValue("lotId", data?.data?.id);
                    form.setFieldValue("lotInfo.name", data?.data?.name);
                    form.setFieldValue("lotInfo.expiration", data?.data?.expiration);
                    alert("로트를 생성했습니다.");
                },
            }
        );
        closeModal(values);
    };

    const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
        e
    ) => {
        e.preventDefault();
        openModal(
            <LotForm
                itemCodeValue={form.values.itemCode as string}
                onSubmit={onCloseHandler}
            />,
            null,
            "신규 LOT 등록",
            true
        ).then((result) => { });
    };

    return (
        <>
            <Create.Wrapper>
                <Create.Input
                    disabled
                    label="품목코드"
                    withAsterisk
                    {...form.getInputProps("itemCode")}
                    defaultValue={formatterProps?.itemCode}
                />
                <Flex justify="space-between" align="center" w="100%">
                    <LotSelectComplete
                        w="100%"
                        label="LOT 정보"
                        withAsterisk
                        description={`LOT 관리 기준 : 
                        ${form.values.itemCode &&
                                itemData?.data?.rows &&
                                itemData?.data?.rows[0]?.usesLotName &&
                                itemData?.data?.rows[0]?.usesLotExpiration
                                ? "이름 + 만료일"
                                : form.values.itemCode &&
                                    itemData?.data?.rows &&
                                    itemData?.data?.rows[0]?.usesLotName
                                    ? "이름"
                                    : form.values.itemCode &&
                                        itemData?.data?.rows &&
                                        itemData?.data?.rows[0]?.usesLotExpiration
                                        ? "만료일"
                                        : "관리 안함"
                            }`}
                        itemCodeValue={
                            (form.values.itemCode as string) || (lotData?.itemCode as string)
                        }
                        newIdValue={lotData?.id}
                        dropdownPosition="bottom"
                        {...form.getInputProps("lotId")}
                        defaultValue={lotData && lotData?.id!.toString()}
                        mr="sm"
                        styles={
                            lotData
                                ? {
                                    description: { fontSize: 10, color: "red" },
                                    input: { fontSize: "0.8em", paddingBottom: "0.5rem" },
                                }
                                : {
                                    description: { fontSize: 10, color: "red" },
                                }
                        }
                        disabled={
                            (itemData?.data?.rows && itemData?.data?.rows[0]?.usesLotName) ||
                                (itemData?.data?.rows &&
                                    itemData?.data?.rows[0]?.usesLotExpiration)
                                ? false
                                : true
                        }
                    />
                    <div>
                        <Space h="xl" />
                        <Space h="lg" />
                        <Button
                            my="xl"
                            disabled={
                                (itemData?.data?.rows &&
                                    itemData?.data?.rows[0]?.usesLotName) ||
                                    (itemData?.data?.rows &&
                                        itemData?.data?.rows[0]?.usesLotExpiration)
                                    ? false
                                    : true
                            }
                            onClick={addNewModalHandler}
                        >
                            신규로트 생성
                        </Button>
                    </div>
                </Flex>
                <LocationAutoComplete
                    label="로케이션코드"
                    withAsterisk
                    {...form.getInputProps("locationCode")}
                />
                <Create.Input
                    label="수량"
                    withAsterisk
                    type="number"
                    {...form.getInputProps("quantity")}
                />
                <Create.Input
                    disabled
                    label="만료일"
                    {...form.getInputProps("lotInfo.expiration")}
                />
                <Create.Input
                    label="수주 정보"
                    {...form.getInputProps("purchaseOrderItemId")}
                    defaultValue={formatterProps?.id}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>
                        취소
                    </Create.Button>
                    <Create.Button onClick={onSubmit}>납품</Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    );
};
