import { Divider, Flex, Text } from "@mantine/core";

interface BetweenFlexProps {
    topTitle?: string;
    topData?: string;
    bottomTitle?: string;
    bottomData?: string;
}
export const BetweenFlex = (
    { topTitle, topData, bottomTitle, bottomData }: BetweenFlexProps
) => {
    return (
        <Flex
            direction="column"
            justify="space-between"
            style={{ backgroundColor: "#f5f5f5", borderRadius: "10px" }}
            w="9rem"
            h="4rem"
            p="sm"
        >
            <Flex justify="space-between">
                <Text size="sm">{topTitle}</Text>
                <Text size="sm">{topData}</Text>
            </Flex>
            <Flex justify="center">
                <Divider w="8rem" />
            </Flex>
            <Flex justify="space-between">
                <Text size="sm">{bottomTitle}</Text>
                <Text size="sm">{bottomData}</Text>
            </Flex>
        </Flex>
    )
}