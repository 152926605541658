import { columnlist } from "@/constants/columnlist";
import { useModal } from "@/features/modal/ModalStackManager";
import { Calendar } from "@/features/ui/Calendar";
import { GridTable } from "@/features/ui/PMS/GridTable";
import { Button, Flex, Input, Select, Text } from "@mantine/core";
import { IconCalendar, IconSearch } from "@tabler/icons-react";
import { SelectColumn } from "react-data-grid";

export const PressDailyRegisterPage = () => {

    const { openModal } = useModal();

    return (
        <>
            <Flex
                gap="md"
                direction={'column'}
            >
                <Flex justify={'space-between'}>
                    <Text size="xl">프레스 일상점검 등록</Text>
                    <Flex
                        gap="sm"
                    >
                        <Button
                            leftIcon={<IconCalendar />}
                            color="indigo"
                            onClick={() => {
                                openModal(<Calendar dates={[null, null]} type="range" />, null, '날짜 선택')
                            }}
                        >날짜선택</Button>
                        <Select
                            w={'8rem'}
                            data={[
                                { label: '기계 제조사', value: 'all' },
                                { label: '기계이름', value: 'all' },
                                { label: '제조번호', value: 'all' },
                            ]}
                        />
                        <Input
                            w={'16rem'}
                            placeholder="검색어를 입력하세요"
                            rightSection={<IconSearch />}
                        />
                        <Button>검색</Button>
                    </Flex>
                </Flex>
                <GridTable 
                    headerList={[
                        SelectColumn,
                        ...columnlist.pressDaily_register
                    ]}
                    row={''}
                    setRow={() => {}}
                />
            </Flex>
        </>
    )
}