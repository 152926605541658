import { WorkBox } from "@/features/monitoring/components/childrenBox/WorkBox";
import { WorkBoxTitle } from "@/features/monitoring/components/childrenBox/WorkBoxTitle";
import { WorkStatusBox } from "@/features/monitoring/components/childrenBox/WorkStatusBox";
import { WorkTextBox } from "@/features/monitoring/components/childrenBox/WorkTextBox";
import { WorkValueText } from "@/features/monitoring/components/childrenBox/WorkValueText";
import { Parents } from "@/features/monitoring/components/parentsBox/Parents";
import { ParentsBox } from "@/features/monitoring/components/parentsBox/ParentsBox";
import { ParentsBoxDiv } from "@/features/monitoring/components/parentsBox/ParentsBoxDiv";
import { ParentsBoxText } from "@/features/monitoring/components/parentsBox/ParentsBoxText";
import { ParentsBoxTextDiv } from "@/features/monitoring/components/parentsBox/ParentsBoxTextDiv";
import { Main } from "@/features/standard/Main";
import { EquipmentStatusColor } from "@/utils/equipmentStatus";
import { Box, Flex, Text } from "@mantine/core";
import { useEffect, useState } from "react";

const Monitoring = Object.assign({}, Main, {
    Parents: Parents,
    ParentsBox: ParentsBox,
    ParentsBoxDiv: ParentsBoxDiv,
    ParentsBoxTextDiv: ParentsBoxTextDiv,
    ParentsBoxText: ParentsBoxText,
    WorkStatusBox: WorkStatusBox,
    WorkBox: WorkBox,
    WorkTextBox: WorkTextBox,
    WorkText: WorkBoxTitle,
    Box: Box,
    WorkValueText: WorkValueText,
});

export interface MonitoringFieldConfig {
    label: string;
    value?: string | number | undefined;
    unit?: string;
    highlightKey?: boolean;
    color?: string;
    backgroundColor?: string
}

interface MonitoringBoxConfig {
    statusInfo: {
        pressStatus: string | undefined;
        errorName: string | undefined;
    }
    textFields?: MonitoringFieldConfig[];
    upperFields?: MonitoringFieldConfig[];
    lowerFields?: MonitoringFieldConfig[];
}

export const MonitoringBox = (params: MonitoringBoxConfig) => {
    const { textFields, statusInfo, upperFields, lowerFields } = params;

    const [prevData, setPrevData] = useState<MonitoringFieldConfig[]>([]);
    const [highlight, setHighlight] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const newHighlight: { [key: string]: boolean } = {};

        if (!textFields || !upperFields || !lowerFields) return;

        [...textFields, ...upperFields, ...lowerFields].forEach((field) => {
            const prevField = prevData.find((prev) => prev.label === field.label);
            if (prevField && prevField.value !== field.value) {
                newHighlight[field.label] = true;
            }
        });

        setHighlight(newHighlight);
        setPrevData([...textFields, ...upperFields, ...lowerFields]);

        const timeout = setTimeout(() => {
            setHighlight((prev) => {
                const updatedHighlight = { ...prev };
                Object.keys(updatedHighlight).forEach((key) => {
                    updatedHighlight[key] = false;
                });
                return updatedHighlight;
            });
        }, 1000);

        return () => clearTimeout(timeout);
    }, [textFields, upperFields, lowerFields]);

    return (
        <>
            <Monitoring.ParentsBox>
                <Monitoring.ParentsBoxDiv height="30%">
                    {textFields?.map((field, index) => (
                        <Monitoring.ParentsBoxTextDiv key={index}>
                            <Monitoring.ParentsBoxText
                            >
                                {field.label} : {field.value}
                            </Monitoring.ParentsBoxText>
                        </Monitoring.ParentsBoxTextDiv>
                    ))}
                </Monitoring.ParentsBoxDiv>
                <Monitoring.ParentsBoxDiv height="70%">
                    <Flex gap={'0.5rem'} w={'100%'}>
                        {
                            statusInfo.pressStatus &&
                            <div style={{ marginTop: '0.5rem' }}>
                                <Monitoring.WorkStatusBox
                                    backgroundColor={EquipmentStatusColor[statusInfo?.pressStatus as string]}>
                                    {statusInfo.pressStatus}
                                </Monitoring.WorkStatusBox>
                            </div>
                        }
                        <Monitoring.Box w={'100%'}>
                            <Monitoring.WorkBox>
                                {upperFields?.map((field, index) => (
                                    <Monitoring.WorkTextBox key={index} highlight={highlight[field.label]}>
                                        <Monitoring.WorkText>
                                            {field.label}
                                        </Monitoring.WorkText>
                                        <Monitoring.WorkValueText>
                                            {field.value} {field.unit}
                                        </Monitoring.WorkValueText>
                                    </Monitoring.WorkTextBox>
                                ))}
                            </Monitoring.WorkBox>
                            <Monitoring.WorkBox>
                                {lowerFields?.map((field, index) => (
                                    <Monitoring.WorkTextBox
                                        backgroundColor={field?.backgroundColor}
                                        key={index}
                                        highlight={highlight[field.label]}>
                                        <Monitoring.WorkText color={field?.color}>
                                            {field.label}
                                        </Monitoring.WorkText >
                                        <Monitoring.WorkValueText color={field?.color}>
                                            {field.value} {field.unit}
                                        </Monitoring.WorkValueText>
                                    </Monitoring.WorkTextBox>
                                ))}
                            </Monitoring.WorkBox>
                        </Monitoring.Box>
                    </Flex>

                </Monitoring.ParentsBoxDiv>
                {statusInfo.pressStatus === "에러"
                    ?
                    <Text c={'red'} weight={900}>
                        에러명: {statusInfo.errorName}
                    </Text>
                    : null
                }
            </Monitoring.ParentsBox>
        </>
    )
};
