import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Table } from "@mantine/core";
import { DefectDetailFormProps } from "./defectDetailForm";

const DefectDetail = Object.assign(DetailHeader, {
    Title : DetailTitle
});

export const DefectDetailView = (params: DefectDetailFormProps) => {
    const { defectData } = params;

    if (!Array.isArray(defectData)) {
        console.error("defectData is not an array");
        return null;
    }

    return (
        <DefectDetail>
            <DefectDetail.Title>
                불량 상세 정보
            </DefectDetail.Title>
            <Table>
                <thead>
                    <tr>
                        <th>불량 코드</th>
                        <th>불량 수량</th>
                    </tr>
                </thead>
                <tbody>
                    {defectData.map((defect, index) => (
                        <tr key={index}>
                            <td>{defect.defect_code}</td>
                            <td style={{ textAlign : 'right'}}>{defect.defect_sum}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </DefectDetail>
    );
};