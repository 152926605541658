import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import { Button, Checkbox, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Checkbox,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
});

export const SystemParameterCreateForm = () => {
    const { closeModal } = useModal();

    const form = useForm({
        initialValues: {
            systemKey: undefined,
            systemValue: undefined,
            isActive: false,
            description: undefined,
        }
    })

    const onSubmit = async () => {
        try {
            closeModal(form.values);

            customAlert("시스템 파라미터 등록에 실패하였습니다.", '등록 실패', 'red')
        } catch (e) {
            customAlert(
                "시스템 파라미터 등록에 실패하였습니다.",
                "등록 실패",
                "red"
            )
        }
    };

    return (
        <Create.Wrapper>
            <Create.Input
                withAsterisk
                label="시스템 파라미터 키"
                placeholder="파라미터 키"
                {...form.getInputProps("systemKey")}
            />
            <Create.Input
                withAsterisk
                label="시스템 파라미터 값"
                placeholder="파라미터 값"
                {...form.getInputProps("systemValue")}
            />
            <Create.Checkbox
                label="비활성화(필수)"
                {...form.getInputProps("isActive")}
            />
            <Create.Input
                withAsterisk
                label="비고"
                placeholder="비고"
                {...form.getInputProps("description")}
            />
            <Create.ButtonBox>
                <Create.Button color="gray" onClick={closeModal}>취소</Create.Button>
                <Create.Button
                    disabled={validateFormValues({
                        pageType: "systemParameter",
                        values: form.values,
                    })}
                    onClick={onSubmit}
                >
                    시스템 파라미터 생성
                </Create.Button>
            </Create.ButtonBox>
        </Create.Wrapper>
    )
};