import DefaultInstance from "@/instance/axios";
import { WmsLogsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export const GET_DELIVERY_QUERY_KEY = "/delivery";

const getDeliveryFetch = (params?: any) => {
    if (params) {
        const query: any = {};
        // query를 보내는 조건은 필터 검색을 할 때이다.
        if (params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }

        // 검색내용이 있을 때만 search를 보내야 한다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return DefaultInstance.wmsLogsGet({
            ...query,
            page: params.activePage ?? 1,
            pageSize: params.pageSize ?? 10,
            sort: params?.sort,
            populate: params?.populate,
        });
    } else return DefaultInstance.wmsLogsGet({});
};

const useGetDeliveryQuery = (
    params?: any,
    options?: {
        onSuccess: (data: any) => void;
    }
) => {
    return useQuery<AxiosResponse<WmsLogsGet200Response, any[]>>({
        queryKey: params
            ? [
                GET_DELIVERY_QUERY_KEY,
                params.activePage,
                JSON.stringify(params.query),
                params.searchFields,
                params.search,
                params.populate,
                params.sort,
            ]
            : [GET_DELIVERY_QUERY_KEY],
        queryFn: () =>
            params ? getDeliveryFetch(params) : getDeliveryFetch(),
    });
};

export default useGetDeliveryQuery;