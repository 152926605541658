import {
  MRT_PaginationState,
  // createRow,
  type MRT_ColumnDef
} from 'mantine-react-table';
import { useMemo, useState } from 'react';

import { productionPlans } from '@/api/productionPlan/useProductionPlanQuery';
import { EquipmentDetailForm } from '@/features/equipment/components/form/EquipmentDetailForm';
import { useModal } from '@/features/modal/ModalStackManager';
import { MoldDetailForm } from '@/features/mold/form/moldDetailForm';
import { OperationDetailForm } from '@/features/operations/components/form/operationDetailForm';
import { RoutingDetailForm } from '@/features/routing/components/form/RoutingDetailForm';
import { DetailLink } from '@/features/ui/detail/DetailLink';
import { MantineReactCommonTable } from '@/features/ui/mantineTable';
import { WorkView } from '@/features/work/WorkView/WorkView';
import DatetimeUtil from '@/utils/dateTimeUtil';
import { setToLocaleString } from '@/utils/unitMark';
import { Badge, Flex } from '@mantine/core';
import { useQuery } from "@tanstack/react-query";
import { ProductionPlanFormProps } from '../../view';

export interface Row {
  targetQuantity: string | any;
  id: number | any;
  scheduledAt: string | any;
  seq: number | any;
  routingCode: string | any;
  routingData: {
    moldCode: string | any;
    operationCode: string | any;
    seq: number | any;
    operation: {
      name: string | any;
    }
  };
  equipmentCode: string | any;
  summary: {
    todoQuantity: number | any;
    defectTotal: number | any;
  }
  item: {
    unitText: string | any;
  }
  works: {
    item: {
      unitText: string | any;
    }
  }
}

export const SummaryTabs = ({ ProductionPlanId }: ProductionPlanFormProps) => {

  const { openModal } = useModal();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });

  const { data: planData } = useQuery(productionPlans.get({
    query: {
      $and: [
        { id: { $eq: ProductionPlanId } },
      ]
    },
    populate: ["works", "equipment", "itemUnit"],
    pageSize: pagination.pageSize,
    page: pagination.pageIndex,
  }))


  const columns = useMemo<MRT_ColumnDef<Row>[]>(
    () => [
      {
        accessorFn: (row) => row.routingData.seq,
        accessorKey: 'seq',
        header: '시퀀스',
        size: 40,
        Cell: (rows) => {
          return (
            <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.seq}</div>
          )
        }
      },
      {
        accessorFn: (row) => row.routingData.operation?.name,
        accessorKey: 'operationCode',
        header: '공정코드',
        size: 120,
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        Cell: (rows) => {
          return (
            <DetailLink onClick={() => openModal(<OperationDetailForm operationCode={rows.row.original.routingData.operationCode} />, null, "")} justify="flex-start">
              {rows.row.original.routingData.operation?.name}
            </DetailLink>
          )
        },
      },
      {
        accessorFn: (row) => row.routingCode,
        accessorKey: 'routingCode',
        header: '라우팅코드',
        size: 120,
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        Cell: (rows) => {
          return (
            <DetailLink onClick={() => openModal(<RoutingDetailForm routingCode={rows.row._valuesCache.routingCode} />, null, "")} justify="flex-start">
              {rows.row._valuesCache.routingCode}
            </DetailLink>
          )
        },
      },
      {
        accessorFn: (row) => DatetimeUtil(row.scheduledAt),
        accessorKey: 'scheduledAt',
        header: '작업예정일시',
        size: 120,
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
      },
      {
        accessorFn: (row) => row.routingData.moldCode,
        accessorKey: 'moldCode',
        header: '사용 금형',
        size: 120,
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        Cell: (rows) => {
          return (
            <Flex direction="column">
              {rows.row._valuesCache.moldCode && <Badge size="sm" w={40}>금형</Badge>}
              <DetailLink
                onClick={() => openModal(
                  <MoldDetailForm moldCode={rows.row._valuesCache.moldCode} />,
                  null,
                  ""
                )}
                justify="flex-start"
              >
                {rows.row._valuesCache.moldCode}
              </DetailLink>
            </Flex>
          )
        }
      },
      {
        accessorFn: (row) => row.equipmentCode,
        accessorKey: 'equipmentCode',
        header: '합당 설비',
        size: 120,
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        Cell: (rows) => {
          return (
            <Flex direction="column">
              {rows.row._valuesCache.equipmentCode && <Badge size="sm" w={40}>설비</Badge>}
              <DetailLink onClick={() => openModal(<EquipmentDetailForm equipmentCode={rows.row._valuesCache.equipmentCode} />, null, "")} justify="flex-start">{rows.row._valuesCache.equipmentCode}</DetailLink>
            </Flex>
          )
        }
      },
      {
        accessorFn: (row) => row.targetQuantity + " " + row.item.unitText,
        accessorKey: 'targetQuantity',
        header: '목표수량',
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        size: 80,
        Cell: (rows) => {
          const unitText = rows.row.original.works?.item?.unitText;
          return (
            <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.targetQuantity} {unitText}</div>
          )
        }
      },
      {
        accessorFn: (row) => setToLocaleString(row.summary.todoQuantity) + " " + row.item.unitText,
        accessorKey: 'todoQuantity',
        header: '생산수량',
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        size: 80,
        Cell: (rows) => {
          const unitText = rows.row.original.works?.item?.unitText;
          return (
            <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.todoQuantity} {unitText}</div>
          )
        }
      },
      {
        accessorFn: (row) => setToLocaleString(row.summary.defectTotal) + " " + row.item.unitText,
        accessorKey: 'defectTotal',
        header: '불량수량',
        size: 80,
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        Cell: (rows) => {
          const unitText = rows.row.original.works?.item?.unitText;
          return (
            <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.defectTotal} {unitText}</div>
          )
        }
      },
      {
        accessorFn: (row) => row.id,
        accessorKey: 'id',
        header: '상세보기',
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        size: 100,
        Cell: (rows) => {
          return (
            <DetailLink
              onClick={() => openModal(
                <WorkView workId={rows.row._valuesCache.id} />,
                null,
                ""
              )}
              justify="flex-start"
            >
              {rows.row._valuesCache.id}
            </DetailLink>
          )
        }
      },
    ],
    [],
  );

  const worksData: Row[] | undefined = planData?.data && Array.isArray(planData?.data.rows && planData?.data.rows[0]?.works) ? planData?.data.rows && planData?.data.rows[0]?.works?.map(row => {
    if (Array.isArray(row) && row.length > 0) {
      return {
        ...row,
      };
    } else {
      return row;
    }
  }) as unknown as Row[] : [];

  return (
    <MantineReactCommonTable
      data={worksData ?? []}
      columns={columns}
      pagination={pagination}
      setPagination={setPagination}
      totalCount={worksData?.length}
      totalPages={Math.ceil((worksData?.length && worksData?.length) ?? 1 / pagination.pageSize)}
    />
  )
};
