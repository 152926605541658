import DefaultInstance from "@/instance/axios"
import { DefaultApiErpSyncPushPushStockMovePostRequest } from "@sizlcorp/sizl-api-document/dist/models"

export const ErpTransferQuery = (params: DefaultApiErpSyncPushPushStockMovePostRequest) => {
    return DefaultInstance.erpSyncPushPushStockMovePost({
        erpSyncPushPushStockMovePostRequest: {
            wmsLogIds: params.erpSyncPushPushStockMovePostRequest.wmsLogIds
        }
    })
}
