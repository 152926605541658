import { BomsTable } from "@/features/bomSearch/table/BomsTable";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { Button } from "@mantine/core";

const BOMSearchWrap = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    Wrapper: Wrapper,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    // CreateForm: ItemsCreateForm,
});

export const BOMSearchPage = () => {

    return (
        <BOMSearchWrap.Wrapper>
            <BOMSearchWrap.Header>
                <BOMSearchWrap.HeaderTitle>품목별 BOM 조회</BOMSearchWrap.HeaderTitle>
                <BOMSearchWrap.HeaderSubTitle>
                    {"우리 회사에서 다루는 품목별 Bom에 대해 조회할 수 있는 페이지 입니다."}
                </BOMSearchWrap.HeaderSubTitle>
            </BOMSearchWrap.Header>
            <BomsTable />
        </BOMSearchWrap.Wrapper>
    );
};
