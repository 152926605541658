import { mutateMolds } from "@/api/mold/useMoldQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { MoldCreateForm } from "@/features/mold/form/createForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import styled from "@emotion/styled";
import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { MasterApiMoldsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EventHandler, ReactNode, forwardRef, useState } from "react";
import customAlert from "../../alert/alert";
import {
  MoldOption,
  useMoldsAutoComplete,
  useMoldsCode,
} from "./auto-useGetMoldsQuery";
interface MoldProps extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  value: string;
}

export interface MoldAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (itemCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
}

export const MoldAutoComplete = (params: MoldAutoCompleteProps) => {
  const {
    value: moldCode,
    onChange,
    maxDropdownHeight,
    width,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const { data: options } = useMoldsAutoComplete(focused, searchKeyword);
  const { data: initialOptions } = useMoldsCode(!!moldCode, moldCode ?? null);

  let selectedMold = initialOptions?.find((moldItem) => {
    return moldItem.value === moldCode;
  });

  const onChangeHandler = (e: string | null) => {
    selectedMold = options?.find((moldItem) => moldItem.value === e);
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, MoldProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{code}</Text>
            <Text fz="xs"> (name: {code})</Text>
          </Flex>
        </Flex>
      </div>
    )
  );

  return (
    <MoldSelect
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedMold?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "10px",
              }}
            >
              {"code: " + selectedMold?.value}
            </div>
          )}
        </div>
      )}
      value={moldCode}
      itemComponent={SelectItem}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: MoldProps[], option: MoldProps) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={MoldInfo({
        mold: selectedMold as MoldOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewMold({
        moldName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const MoldInfo = (params: {
  mold?: MoldOption;
  onChange: (MoldCode: string | null) => void;
}) => {
  const { mold, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return mold?.value ? (
    <MoldInfoLabel>
      {/* ({mold.value}) */}
      <IconX size="1rem" onClick={clearHandler} />{" "}
    </MoldInfoLabel>
  ) : null;
};

const AddNewMold = (params: {
  moldName: string;
  onChange: (itemCode: string) => void;
}) => {
  const { moldName, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (params: MasterApiMoldsPostRequest) =>
      mutateMolds.create(params).mutationFn(params as MasterApiMoldsPostRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['molds']);
        closeModal(res.data);
        customAlert(
          "금형 생성에 성공하였습니다.",
          "생성 성공",
          "green"
        )
      },
      onError: (error) => {
        customAlert(handleErrorResponse({ error, errorContext: '금형코드' }), '금형 생성 실패', 'red')
      }
    }
  )

  const onCloseHandler: EventHandler<any> = (values) => {

    mutate({
      moldsGetRequest: {
        code: values.code!,
        siteCode: values.siteCode,
        capacity: values.capacity,
      }
    })
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <MoldCreateForm code={moldName} autoCompleteOnSubmit={onCloseHandler} />,
      null,
      "신규 금형 등록",
      true
    ).then((result) => {
      onChange(result.code);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 금형({moldName}) 등록
    </Button>
  );
};
const MoldInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;
const MoldSelect = styled(Select)<{value : string | null | undefined}>`
  width: 100%;

  .mantine-Select-input {
    font-size: 0.8rem;
    padding-bottom: 0.8rem !important;

    &.item-dirty {
      color: cadetblue !important;
      border-color: cadetblue;
    }
  }
  .mantine-Select-error {
    font-size: 11px;
    color: red;
  }
`;
