import { Anchor, Badge, Flex } from "@mantine/core";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  // createRow,
  type MRT_ColumnDef
} from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";

import { moldCavity, mutateMoldCavity } from "@/api/mold/useMoldCavityQuery";
import { AbstractItemDetailForm } from "@/features/abstractItem/form/detailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { MantineReactCommonTable } from "@/features/ui/mantineTable";
import { DefaultApiMoldCavitiesMoldCavityIdDeleteRequest, MoldCavitiesGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MoldCodeProps } from "../form/cavityForm";
import { MoldCavityUpdateForm } from "../form/cavityUpdateForm";

export interface Row {
  id: number | any;
  moldCode: string;
  itemCode: string;
  abstractItemCode: string;
  num: number;
  seq: number;
}

export const MoldCavityTable = ({ moldCodeProps }: MoldCodeProps) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  // 각 컬럼 sort
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  // 각 컬럼 filter
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  // 전체 검색 (단, serchFields에 필드를 직접 입력해줘야함.)
  const [globalFilter, setGlobalFilter] = useState('');

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  // sort를 id 별 string으로 만들어줌
  const sortString = sorting
    .map(({ id, desc }) => (desc ? `-${id}` : id))
    .join(',');

  // filter를 id 별 like를 사용하여 단어가 포함되어있는지 확인
  const filterQuery: { [key: string]: { $iLike: string } } = columnFilters.reduce<{ [key: string]: { $iLike: string } }>((acc, filter) => {
    acc[filter.id] = { $iLike: `%${filter.value}%` as string };
    return acc;
  }, {});

  const { data: moldCavities } = useQuery(moldCavity.get({
    query: {
      $and: [
        { moldCode: { $eq: moldCodeProps } },
        ...Object.keys(filterQuery).map(key => ({ [key]: filterQuery[key] }))
      ]
    },
    pageSize: pagination.pageSize,
    page: pagination.pageIndex,
    search: globalFilter,
    searchFields: ["itemCode", "abstractItemCode"],
    sort: sortString || undefined,
  }));

  const { openModal } = useModal();

  const rows: MoldCavitiesGet200ResponseRowsInner[] =
    moldCavities?.data.rows ?? [];

  // 선택된 id를 배열로 만들어서 상태로 관리
  useEffect(() => {
    const ids = Object.keys(rowSelection).filter((key) => rowSelection[key]);
    setSelectedRowIds(ids);
  }, [rowSelection]);

  const columns = useMemo<MRT_ColumnDef<MoldCavitiesGet200ResponseRowsInner>[]>(
    () => [
      {
        accessorKey: "seq",
        header: "캐비티",
        size: 80,
        Cell: (rows) => {
          return (
            <div style={{ textAlign: "right" }}>
              {rows.row._valuesCache.seq}
            </div>
          );
        },
      },
      {
        accessorKey: "itemCode",
        header: "품목코드",
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <Flex direction="column">
              {rows.row._valuesCache.itemCode && (
                <Badge size="sm" w={40}>
                  제품
                </Badge>
              )}
              <Anchor
                onClick={() => openModal(<ItemsDetailForm itemCode={rows.row._valuesCache.itemCode} />, null, "")}
              >
                {rows.row._valuesCache.itemCode}
              </Anchor>
            </Flex>
          );
        },
      },
      {
        accessorKey: "abstractItemCode",
        header: "품목군",
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <Anchor
              onClick={() => openModal(<AbstractItemDetailForm AbstractItemCode={rows.row._valuesCache.abstractItemCode} />, null, "")}
            >
              {rows.row._valuesCache.abstractItemCode}
            </Anchor>
          );
        },
      },
      {
        accessorKey: "num",
        header: "개수",
        size: 120,
        mantineEditTextInputProps: {
          type: "number",
          required: true,
        },
        Cell: (rows) => {
          return (
            <div style={{ textAlign: "right" }}>
              {rows.row._valuesCache.num}
            </div>
          );
        },
      },
    ],
    []
  );

  // 삭제
  const deleteUser = useDeleteUser();

  return (
    <MantineReactCommonTable
      data={rows as MoldCavitiesGet200ResponseRowsInner[]} // data
      columns={columns} // columns
      totalCount={moldCavities?.data.total} // total count
      totalPages={moldCavities?.data.totalPages} // total pages
      pagination={pagination} // pagination
      sorting={sorting} // 정렬
      columnFilters={columnFilters} // 컬럼별 검색
      setPagination={setPagination}
      setSorting={setSorting}
      setColumnFilters={setColumnFilters}
      globalFilter={globalFilter} // 전체 검색
      setGlobalFilter={setGlobalFilter}
      rowSelection={rowSelection} // 선택
      setRowSelection={setRowSelection}
      selectedRowIds={selectedRowIds} // 선택된 id
      setSelectedRowIds={setSelectedRowIds}
      enableRowSelection={true} // 선택 가능 여부
      enableRowActions={true} // 액션 가능 여부
      positionActionsColumn={true} // 액션 위치
      enableEditing={true} // 수정 가능 여부
      enableTopToolbar={true} // 상단 툴바
      renderRowActions={({ row }) => (
        <Flex gap="md">
          <Anchor color="orange" onClick={() =>
            openModal(
              <MoldCavityUpdateForm formatterProps={row.original} />,
              null,
              "금형 수정",
              true
            )}>
            <IconEdit />
          </Anchor>
          <Anchor color="red" onClick={() => {
            if (window.confirm("정말 삭제하시겠습니까?")) {
              deleteUser(row?.original?.id);
            }
          }}>
            <IconTrash />
          </Anchor>
        </Flex>
      )}
    />
  )
};

function useDeleteUser() {
  const queryClient = useQueryClient();
  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiMoldCavitiesMoldCavityIdDeleteRequest) =>
      mutateMoldCavity.delete(params).mutationFn(params as DefaultApiMoldCavitiesMoldCavityIdDeleteRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['moldCavity'])
      }
    }
  );

  return async (values: number | undefined) => {
    await deleteMutate(
      {
        moldCavityId: values ?? 0,
      },
      {
        onSuccess: () => {
          customAlert(
            "금형 캐비티 삭제에 성공하셨습니다.",
            "삭제 성공",
            "green",
          );
        },
        onError: () => {
          customAlert(
            "금형 캐비티 삭제에 실패하셨습니다.",
            "삭제 실패",
            "red",
          );
        },
      }
    );
  };
}
