import { LocationCreateForm } from "@/features/location/form/CreateForm";
import { LocationTable } from "@/features/location/table";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button, Group } from "@mantine/core";
import { IconPlus, IconPrinter, IconTrash } from "@tabler/icons-react";

const Location = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  CreateForm: LocationCreateForm,
});

export const LocationPage = () => {
  const { openModal } = useModal();

  const publish = usePub();

  return (
    <>
      <Location.Wrapper>
        <Location.ActionHeader>
          <Group>
            <Location.ActionButton
              leftIcon={<IconPlus />}
              onClick={() =>
                openModal(<Location.CreateForm />, null, "창고 추가", true)
              }
            >
              추가
            </Location.ActionButton>
            <Location.ActionButton
              leftIcon={<IconPrinter />}
              color="green" onClick={() => {
                publish("printLocationBarcode");
              }}>
              바코드 출력
            </Location.ActionButton>
          </Group>
          <Location.ActionButton
            color="red"
            rightIcon={<IconTrash />}
            onClick={() => publish("deleteSelectedItems")}
          >
            선택된 행 삭제
          </Location.ActionButton>
        </Location.ActionHeader>
        <Location.Header>
          <Location.HeaderTitle>창고</Location.HeaderTitle>
          <Location.HeaderSubTitle>
            우리 회사에서 다루는 창고를 관리합니다.
          </Location.HeaderSubTitle>
        </Location.Header>
        <LocationTable />
      </Location.Wrapper>
    </>
  );
};
