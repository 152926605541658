import { LedgersAllTable } from "@/features/ledgersAll/components/table";
import { LedgerProvider } from "@/features/ledgersAll/context/ledgerProvider";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { TimeHeader } from "@/features/standard/TimeHeader";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";

const Receipt = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    ReceiptWrapper: Wrapper,
    LedgersAllTable: LedgersAllTable,
    LedgersTimeHeader: TimeHeader,
    LedgerProvider: LedgerProvider,
});

export const ReceiptPage = () => {
    return (
        <Receipt.LedgerProvider>
            <Receipt.ReceiptWrapper>
                <Receipt.LedgersTimeHeader />
                <Receipt.Header>
                    <Receipt.HeaderTitle>
                        재고 수불 이력
                    </Receipt.HeaderTitle>
                    <Receipt.HeaderSubTitle>
                        우리 회사에서 다루는 모든 입출고 내역을 확인할 수 있습니다.
                    </Receipt.HeaderSubTitle>
                </Receipt.Header>
                <Receipt.LedgersAllTable />
            </Receipt.ReceiptWrapper>
        </Receipt.LedgerProvider>
    )
};