import { inventories, mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { purchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import timeUtil from "@/utils/timeUtil";
import styled from "@emotion/styled";
import { Button, Flex, Radio, Select, Table, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { DefaultApiWmsOutgoingPostRequest, ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner, PurchaseOrdersGet200ResponseRowsInner, WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface PurchaseOrderItemsSelectFormProps {
    formatterProps: PurchaseOrdersGet200ResponseRowsInner
}

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Date: DateInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    ItemAutoComplete: ItemAutoComplete,
});

export const PurchaseOrderItemsOutgingForm = (params: PurchaseOrderItemsSelectFormProps) => {
    const { formatterProps } = params;
    const { closeModal } = useModal();
    const [selectedRadio, setSelectedRadio] = useState(null);

    const queryClient = useQueryClient();
    const { mutate: outgoingMutate } = useMutation(
        (params: DefaultApiWmsOutgoingPostRequest) =>
            mutateInventories.outgoing(params).mutationFn(params as DefaultApiWmsOutgoingPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["inventories"]);
            }
        }
    )

    const { data: purchaseItemData } = useQuery({
        ...purchaseOrderItems.detail({
            purchaseOrderItemId: selectedRadio ?? 0,
        }),
        enabled: !!selectedRadio,
    })

    const [selectLot, setSelectLot] = useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner>();
    const [selectedLotId, setSelectedLotId] = useState<string | null>(null); // Select의 선택된 값 상태

    const { data: inventoryData } = useQuery({
        ...inventories.find({
            query: {
                $and: [
                    { itemCode: { $eq: purchaseItemData?.data?.itemCode } }
                ]
            },
            populate: ["lot", "location", "item"],
        }),
        enabled: !!purchaseItemData?.data?.itemCode,
    })

    const form = useForm<Partial<WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner>>({
        initialValues: {
            itemCode: undefined,
            locationCode: undefined,
            quantity: undefined,
            lotId: null,
            lotInfo: {
                name: null,
                expiration: null,
            },
            purchaseOrderItemId: undefined,
            ...(customFunctions.ADD_PURCHASE_ORDER_INVOICE_DATE ? { invoiceDate: new Date().toISOString(), invoiceRefNo: undefined } : {}),
        },
    });

    const handleRadioChange = (id: number | any) => {
        setSelectedRadio(id);
    };

    useEffect(() => {
        // selectedRadio가 변경될 때 Select 값 초기화
        setSelectedLotId(null);
        setSelectLot(undefined);
    }, [selectedRadio]);

    useEffect(() => {
        if (selectLot?.locationCode) {
            form.setFieldValue('locationCode', selectLot.locationCode);
        }
    }, [selectLot]);

    const onSubmit = () => {
        try {
            const { itemCode, locationCode, quantity } = form.values;
            if (!itemCode && !locationCode && !quantity) return;

            outgoingMutate(
                {
                    workLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner: {
                        itemCode:
                            purchaseItemData?.data?.itemCode ?? '',
                        locationCode:
                            form.values.locationCode as string ?? selectLot?.locationCode,
                        quantity: form.values.quantity ? form.values.quantity : selectLot?.quantity || form.values.quantity as string,
                        lotId: Number(selectLot?.lotId || form.values.lotId),
                        lotInfo: {
                            name:
                                selectLot?.lot?.name,
                            expiration:
                                selectLot?.lot?.expiration,
                        },
                        purchaseOrderItemId: formatterProps.id,
                        actionFrom: 'WEB',
                        ...(customFunctions.ADD_PURCHASE_ORDER_INVOICE_DATE ? { invoiceDate: form.values.invoiceDate, invoiceRefNo: undefined } : {}),
                        // invoiceRefNo: undefined, // TODO: invoiceRefNo 추가 필요 (입력폼 필요)
                    },
                },
                {
                    onSuccess: (res: { status: number }) => {
                        closeModal(form.values);
                        if (res.status === 200) {
                            customAlert(
                                "납품에 성공하였습니다.",
                                "납품 성공",
                                "green"
                            )
                        } else {
                            customAlert(
                                "납품에 실패하였습니다.",
                                "납품 실패",
                                "red"
                            )
                        }
                    },
                }
            );
        } catch (e) {
            customAlert(
                "납품에 실패하였습니다.",
                "납품 실패",
                "red"
            )
        }
    };

    return (
        <>
            <Wrapper>
                {formatterProps.purchaseOrderItems && formatterProps.purchaseOrderItems.length > 0 && (
                    formatterProps.purchaseOrderItems.map((items) => (
                        <Table key={items.id} withBorder withColumnBorders w="40rem">
                            <thead>
                                <Tr>
                                    <Th flex={0.5} className="small-column"></Th>
                                    <Th flex={2}>협력사명</Th>
                                    <Th flex={2}>품목명</Th>
                                    <Th flex={1}>수량</Th>
                                    <Th flex={2}>로케이션</Th>
                                </Tr>
                            </thead>
                            <tbody>
                                <Tr>
                                    <Td flex={0.5} className="small-column">
                                        <Flex align="center" justify="center">
                                            <Radio
                                                value={items.id}
                                                checked={selectedRadio === items.id}
                                                onChange={() => handleRadioChange(items.id)}
                                            />
                                        </Flex>
                                    </Td>
                                    <Td flex={2}>{items.partnerCode}</Td>
                                    <Td flex={2}>{items.itemCode}</Td>
                                    <Td flex={1} className="textRight">{items.quantity}</Td>
                                    <Td flex={2}>{items.sourceLocationCode}</Td>
                                </Tr>
                            </tbody>
                        </Table>
                    ))
                )}
                {formatterProps.purchaseOrderItems && formatterProps.purchaseOrderItems.length === 0 ? (
                    <Flex>
                        <Text size="xl" fw="bold">
                            수주항목이 없습니다. 수주항목을 생성해주세요.
                        </Text>
                    </Flex>
                ) : (
                    <>
                        <Create.Input
                            disabled
                            label="품목코드"
                            withAsterisk
                            {...form.getInputProps("itemCode")}
                            defaultValue={purchaseItemData?.data?.itemCode}
                        />
                        <Select
                            label="LOT 정보"
                            clearable
                            value={selectedLotId} // Select의 value를 상태로 연결
                            data={inventoryData?.data ? inventoryData.data
                                .filter((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => item?.quantity && item?.quantity > "0")
                                .map((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => {
                                    return {
                                        value: item.lotId ? item.lotId.toString() : '',
                                        label: `이름 : ${item?.lot?.name ?? ''} 유효기한 : ${item?.lot?.expiration === null ? '-' : timeUtil(item?.lot?.expiration ?? '')} 로케이션코드 : ${item?.locationCode} 수량 : ${item?.quantity}`
                                    }
                                }) : []}
                            onChange={(e) => {
                                setSelectedLotId(e); // 선택된 LOT의 ID를 상태로 업데이트
                                const matchedItem = inventoryData?.data?.find((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => item.lotId === Number(e));
                                setSelectLot(matchedItem);
                            }}
                        />
                        <LocationAutoComplete
                            label="로케이션코드"
                            withAsterisk
                            {...form.getInputProps("locationCode")}
                            defaultValue={selectLot !== undefined ? selectLot?.locationCode : ''}
                        />
                        <Create.Input
                            label="수량"
                            withAsterisk
                            type="number"
                            {...form.getInputProps("quantity")}
                            defaultValue={selectLot !== undefined ? selectLot?.quantity : ''}
                        />
                        <Create.Input
                            disabled
                            label="만료일"
                            {...form.getInputProps("lotInfo.expiration")}
                            defaultValue={selectLot !== undefined ? selectLot?.lot?.expiration : ''}
                        />
                        <Create.Input
                            label="수주 정보"
                            {...form.getInputProps("purchaseOrderItemId")}
                            defaultValue={formatterProps?.partnerCode}
                        />
                        {
                            customFunctions.ADD_PURCHASE_ORDER_INVOICE_DATE && (
                                <DateInput
                                    label="출하 날짜"
                                    valueFormat="YYYY-MM-DD"
                                    value={dayjs(form.values.invoiceDate).toDate()}
                                    onChange={(e) => {
                                        form.setFieldValue('invoiceDate', dayjs(e).startOf('day')?.toISOString());
                                    }}
                                />
                            )
                        }
                        <Create.ButtonBox>
                            <Create.Button color="gray" onClick={closeModal}>
                                취소
                            </Create.Button>
                            <Create.Button onClick={onSubmit} disabled={!selectedRadio}>납품</Create.Button>
                        </Create.ButtonBox>
                    </>
                )}
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const Th = styled.th<{ flex: number }>`
    padding: 0.5rem;
    text-align: center;
    flex : ${(props) => (props.flex ? props.flex : 1)};
`
const Tr = styled.tr`
    display: flex;
    flex-direction: row;
`

const Td = styled.td<{ flex: number }>`
    padding: 0.5rem;
    text-align: center;
    flex : ${(props) => (props.flex ? props.flex : 1)};

    &.textRight {
        text-align: right;
    }
`