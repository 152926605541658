import { mutateTransportation } from "@/api/transportation/useTransportationQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ActionBarContainer, LeftActionBar, RightActionBar } from "@/features/work/WorkView/ActionBar";
import { Button } from "@mantine/core";
import { ScmApiTransportationTransportationIdDeleteRequest, TransportationGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TransportationStatusForm } from "../form/statusForm";
import { TransportationUpdateForm } from "../form/updateForm";

interface TransportationDetailActionBarProps {
    formatterProps: TransportationGet200ResponseRowsInner | undefined;
}

export const TransportationDetailActionBar = (prarms: TransportationDetailActionBarProps) => {
    const { formatterProps } = prarms;

    const { openModal } = useModal();
    const queryClient = useQueryClient();
    const { mutate: deleteMutate } = useMutation(
        (params: ScmApiTransportationTransportationIdDeleteRequest) =>
            mutateTransportation.delete(params).mutationFn(params as ScmApiTransportationTransportationIdDeleteRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['transportation']);
            }
        }
    )

    const deleteTransportation = () => {
        if (window.confirm("정말 삭제하시겠습니까?")) {
            deleteMutate(
                {
                    transportationId: formatterProps?.id ?? 0
                },
                {
                    onSuccess: (res) => {
                        if (res.status === 200) {
                            customAlert(
                                "배차 삭제에 성공하였습니다.",
                                "삭제 성공",
                                "green"
                            )
                        }
                    },
                    onError: (error: any) => {
                        customAlert(
                            `${error.response?.data?.message}` ?? "배차 삭제에 실패하였습니다.",
                            "삭제 실패",
                            "red"
                        )
                    }
                }
            )
        }
    };

    return (
        <ActionBarContainer>
            <LeftActionBar>
                <Button
                    variant="outline"
                    onClick={() => openModal(
                        <TransportationStatusForm formatterProps={formatterProps} />,
                        null,
                        "배차상태 변경"
                    )}
                >배차상태 변경</Button>
            </LeftActionBar>
            <RightActionBar>
                <Button
                    color="orange"
                    rightIcon={<IconEdit />}
                    onClick={() => openModal(
                        <TransportationUpdateForm formatterProps={formatterProps} />,
                        null,
                        "배차 수정"
                    )}
                >수정</Button>
                <Button
                    color="red"
                    rightIcon={<IconTrash />}
                    onClick={deleteTransportation}
                >삭제</Button>
            </RightActionBar>
        </ActionBarContainer>
    )
}