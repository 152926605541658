import CustomTable from "@/features/ui/table/CustomTable"
import { Button, Flex, Input, Select, Text } from "@mantine/core"
import { IconSearch } from "@tabler/icons-react"

export const PressErrorViewPage = () => {

    const Header = {
        machine : ['기계 제조사', '기계 이름', '제조 연월일', '제조 번호'],
        error : ['에러 번호', '명칭', '알림']
    }

    const BodyData = {
        machine : [{'기계 제조사' : '-', '기계 이름' : '-', '제조 연월일' : '-', '제조 번호' : '-'}],
        error : [{'에러 번호' : '1', '명칭' : '에러1', '알림' : '알림1'}]
    }
    return (
        <>
            <Flex
                gap="md"
                direction='column'
            >
                <Flex justify='space-between'>
                    <Text size="lg">에러보기</Text>
                    <Flex gap="sm" align='center'>
                        <Select
                            w={'8rem'}
                            data={[
                                {label: '에러 번호', value: 'all'},
                            ]}
                        />
                        <Input 
                            w={'16rem'}
                            placeholder="검색어를 입력하세요" 
                            rightSection={<IconSearch />}
                        />
                        <Button color="gray">저장하기</Button>
                    </Flex>
                </Flex>
                <Flex gap="sm" justify='left' align='center'>
                    버전
                    <Select
                        defaultValue={'v1.0'}
                        w={'6rem'}
                        data={[
                            {label: 'v1.0', value: 'v1.0'},
                            {label: 'v2.0', value: 'v2.0'},
                            {label: 'v3.0', value: 'v3.0'},
                        ]}
                    />
                </Flex>
                <Flex gap="xl">
                    <CustomTable 
                        headers={Header.machine}
                        data={BodyData.machine}
                    />
                    <CustomTable 
                        headers={Header.error}
                        data={BodyData.error}
                    />
                </Flex>
            </Flex>
        </>
    )
}