import { ScmInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { ScmApiPurchaseOrderItemsFindPostRequest, ScmApiPurchaseOrderItemsGetRequest, ScmApiPurchaseOrderItemsPostRequest, ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest, ScmApiPurchaseOrderItemsPurchaseOrderItemIdGetRequest, ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const purchaseOrderItems = createQueryKeys('purchaseOrderItems', {
    all : null,
    get :  (params : ScmApiPurchaseOrderItemsGetRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.
        if (Array.isArray(params?.query.$and) && params?.query.$and.length > 0) {
            query["query"] = JSON.stringify(params?.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params?.search) {
            query["search"] = params?.search;
            query["searchFields"] = params?.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => ScmInstance.purchaseOrderItemsGet(query),
        }
    },
    detail : (params : ScmApiPurchaseOrderItemsPurchaseOrderItemIdGetRequest) => ({
        queryKey: [params.purchaseOrderItemId],
        queryFn: () => ScmInstance.purchaseOrderItemsPurchaseOrderItemIdGet(params)
    }),
    find : (params : ScmApiPurchaseOrderItemsFindPostRequest ) => ({
        queryKey: [params],
        queryFn: () => ScmInstance.purchaseOrderItemsFindPost(params),
    })
});

export const mutatePurchaseOrderItems = createMutationKeys('purchaseOrderItems', {
    create : (params : ScmApiPurchaseOrderItemsPostRequest) => ({
        mutationKey: [params.purchaseOrderItemsGetRequest],
        mutationFn: () => ScmInstance.purchaseOrderItemsPost(params),
    }),
    update : (params : ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest) => ({
        mutationKey: [params.purchaseOrderItemId, params.purchaseOrderItemsGetRequest],
        mutationFn: () => ScmInstance.purchaseOrderItemsPurchaseOrderItemIdPut(params),
    }),
    delete : (params : ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest) => ({
        mutationKey: [params.purchaseOrderItemId],
        mutationFn: () => ScmInstance.purchaseOrderItemsPurchaseOrderItemIdDelete(params),
    }),
})

export const purchaseOrderItemsQueryKeys = mergeQueryKeys(purchaseOrderItems, mutatePurchaseOrderItems);