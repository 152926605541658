import styled from "@emotion/styled";
import { ActionIcon, Button, Flex, Grid, Text } from "@mantine/core";
import { IconArrowLeft, IconArrowRight, IconPlus } from "@tabler/icons-react";
import { useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';
import { StrictModeDroppable } from "./StrictModeDroppable";

interface TableRow {
    id: string;
    name: string;
    modelNumber: string;
    overHaul?: string;
    isDropped?: boolean;
}

interface Page {
    id: string;
    items: TableRow[];
}

const initialTableRows: TableRow[] = [
    { id: '1', name: 'DDD', modelNumber: '1231564', overHaul: '유', isDropped: false },
    { id: '2', name: '요기계', modelNumber: 'Y-12345', overHaul: '유', isDropped: false },
    { id: '3', name: '차기계', modelNumber: 'C-12345', overHaul: '유', isDropped: false },
    { id: '4', name: '정기계', modelNumber: 'M-12345', overHaul: '유', isDropped: false }
];

const initialPages: Page[] = [
    { id: '1', items: [] },
];

export const FactoryPageSetting = () => {
    // TO-BE : 테이블과 name은 수정하기
    const [tableRows, setTableRows] = useState<TableRow[]>(initialTableRows);
    const [pages, setPages] = useState<Page[]>(initialPages);
    const [selectedRow, setSelectedRow] = useState<TableRow | null>(null);
    const [selectedPage, setSelectedPage] = useState<string | null>(null);

    const onDragStart = (start: any) => {
        const { source } = start;
        if (source.droppableId === "table") {
            setSelectedRow(tableRows[source.index]);
        }
    };

    const onDragEnd = (result: any) => {
        const { source, destination } = result;

        if (!destination) return;

        if (source.droppableId === destination.droppableId && source.index === destination.index) return;

        if (source.droppableId === "table" && destination.droppableId.startsWith("page")) {
            const sourceRow = tableRows[source.index];
            const destinationPageId = destination.droppableId.split("-")[1];

            // 테이블에서 페이지로 이동할 때, 새로운 객체로 관리
            const newRow = { ...sourceRow, id: uuidv4() };

            const newPages = pages.map(page => {
                if (page.id === destinationPageId) {
                    return {
                        ...page,
                        items: [...page.items, newRow],
                    };
                }
                return page;
            });

            setPages(newPages);
            setTableRows(tableRows.map(row => row.id === sourceRow.id ? { ...row, isDropped: true } : row));
        } else if (source.droppableId.startsWith("page") && destination.droppableId === "table") {
            const sourcePageId = source.droppableId.split("-")[1];
            const sourcePage = pages.find(page => page.id === sourcePageId);
            if (!sourcePage) return;

            const item = sourcePage.items[source.index];

            // 페이지에서 테이블로 복구할 때 원래 ID로 복원
            const originalRow = tableRows.find(row => row.name === item.name && row.modelNumber === item.modelNumber);

            const newTableRows = tableRows.map(row =>
                originalRow && row.id === originalRow.id ? { ...row, isDropped: false } : row
            );

            const newPages = pages.map(page => {
                if (page.id === sourcePageId) {
                    return {
                        ...page,
                        items: page.items.filter((_, idx) => idx !== source.index),
                    };
                }
                return page;
            });

            setPages(newPages);
            setTableRows(newTableRows);
        }
    };

    const handleRowClick = (row: TableRow) => {
        if (selectedRow?.id === row.id) {
            setSelectedRow(null);
        } else {
            setSelectedRow(row);
        }
    };

    const handlePageClick = (pageId: string) => {
        setSelectedPage(pageId);
    };

    // ArrowRightButton
    const handleMoveClick = () => {
        if (selectedRow && selectedPage) {
            // 중복되지 않게 새로운 row 생성
            const newRow = { ...selectedRow, id: uuidv4() };

            const newPages = pages.map(page => {
                if (page.id === selectedPage) {
                    return {
                        ...page,
                        items: [...page.items, newRow],
                    };
                }
                return page;
            });

            setPages(newPages);
            setSelectedRow(null);
            setSelectedPage(null);

            setTableRows(tableRows.map(row =>
                row.id === selectedRow.id ? { ...row, isDropped: true } : row
            ));
        }
    };

    // ArrowLeftButton
    const handleMoveAllToTable = () => {
        if (selectedPage) {
            const selectedPageIndex = pages.findIndex(page => page.id === selectedPage);
            if (selectedPageIndex === -1) return;

            const selectedPageItems = pages[selectedPageIndex].items;

            const newTableRows = tableRows.map(row =>
                selectedPageItems.find(item => item.name === row.name && item.modelNumber === row.modelNumber)
                    ? { ...row, isDropped: false }
                    : row
            );

            const newPages = pages.map((page, index) =>
                index === selectedPageIndex ? { ...page, items: [] } : page
            );

            setTableRows(newTableRows);
            setPages(newPages);
        }
    };

    const handleAddPage = () => {
        const newPageId = (pages.length + 1).toString();
        setPages([...pages, { id: newPageId, items: [] }]);
    };

    const handleDeletePage = (pageId: string) => {
        const remainingPages = pages.filter(page => page.id !== pageId);

        const reorderedPages = remainingPages.map((page, index) => ({
            ...page,
            id: (index + 1).toString()
        }));

        setPages(reorderedPages);
    };

    const handleReset = () => {
        setTableRows(initialTableRows);
        setPages(initialPages);
        setSelectedRow(null);
        setSelectedPage(null);
    };

    // TO-BE : API 나오면 구현하기
    const handleSave = () => {

    }

    return (
        <Flex gap="md" direction={'column'} p="1rem">
            <Flex justify={'space-between'}>
                <Text size="xl">프레스별 분석 모니터링(페이지설정)</Text>
                <Flex gap="sm">
                    <Button color="gray" onClick={() => handleReset()}>초기화 하기</Button>
                    <Button onClick={() => handleSave()}>저장하기</Button>
                </Flex>
            </Flex>
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Flex gap="xl" justify={'center'} >
                    <Table>
                        <StrictModeDroppable droppableId="table">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    <Thead>
                                        <tr>
                                            <th>기계 이름</th>
                                            <th>제조 번호</th>
                                            <th>오버홀</th>
                                            <th>사용</th>
                                        </tr>
                                    </Thead>
                                    {tableRows.map((row, index) => (
                                        <Draggable key={row.id} draggableId={row.id} index={index}>
                                            {(provided) => (
                                                <TableRowStyled
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    selected={selectedRow?.id === row.id}
                                                    onClick={() => handleRowClick(row)}
                                                >
                                                    <tr>
                                                        <td>{row.name}</td>
                                                        <td>{row.modelNumber}</td>
                                                        <td>{row.overHaul}</td>
                                                        <td>{row.isDropped ? '●' : '○'}</td>
                                                    </tr>
                                                </TableRowStyled>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </Table>
                    <Flex direction={'column'} gap="sm" justify={'center'}>
                        <ActionIcon size="5rem" variant="outline" color="gray" onClick={handleMoveClick}>
                            <IconArrowRight size="3rem" />
                        </ActionIcon>
                        <ActionIcon size="5rem" variant="outline" color="gray" onClick={handleMoveAllToTable}>
                            <IconArrowLeft size="3rem" />
                        </ActionIcon>
                    </Flex>

                    <PageContainer>
                        {pages.map(page => (
                            <StrictModeDroppable droppableId={`page-${page.id}`} key={page.id}>
                                {(provided) => (
                                    <Grid.Col span={6}>
                                        <PageStyled
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            selected={selectedPage === page.id}
                                            onClick={() => handlePageClick(page.id)}
                                        >
                                            <Flex justify={'space-between'} align={'center'}>
                                                <PageHeader>페이지 번호: {page.id}</PageHeader>
                                                <Button color="red" onClick={() => handleDeletePage(page.id)}>페이지 삭제</Button>
                                            </Flex>
                                            {page.items.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided) => (
                                                        <PageRowStyled
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            selected={selectedRow?.id === item.id}
                                                        >
                                                            {item.name} ({item.modelNumber})
                                                        </PageRowStyled>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </PageStyled>
                                    </Grid.Col>
                                )}
                            </StrictModeDroppable>
                        ))}
                        <Grid.Col span={6}>
                            <PageAddStyled onClick={handleAddPage}>
                                <ActionIcon variant="transparent">
                                    <IconPlus size="6rem" />
                                </ActionIcon>
                            </PageAddStyled>
                        </Grid.Col>
                    </PageContainer>
                </Flex>
            </DragDropContext>
        </Flex>
    );
};

const Table = styled.div`
  background-color: #39404A;
  padding: 1rem;
  border-radius: 8px;
  color : #FFFFFF;
  width : 35%;
  height: auto;
  max-height: 340px;
`;

const Thead = styled.div`
    color: #FFFFFF;
    margin-bottom: 1rem;
    width : 100%;
    tr {
        display: flex;
    }

    th {
        flex : 1;
        text-align: center;
    }
`

const TableRowStyled = styled.div<{ selected: boolean }>`
  padding: 1rem;
  background-color: ${({ selected }) => (selected ? '#4CAF50' : '#3B4049')};
  margin-bottom: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${({ selected }) => (selected ? '#45A049' : '#4E5259')};
  }

  tr {
    display: flex;
  }

  td {
    flex : 1;
    text-align: center;
  }
`;

const PageContainer = styled(Grid)`
  padding: 1rem;
  background-color: #333A41;
  border-radius: 8px;
  width : 60%;
  height : 100%;
`;

const PageStyled = styled.div<{ selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? '#4CAF50' : '#3B4049')};
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  flex: 1;
  color : #FFFFFF;
  min-width: 280px;
  min-height: 140px;
  cursor: pointer;
  &:hover {
    background-color: ${({ selected }) => (selected ? '#45A049' : '#4E5259')};
  }
`;

const PageAddStyled = styled.div`
    background-color: #3B4049;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    min-height: 140px;
    flex: 1;
    color : #FFFFFF;
    min-width: 280px;

    cursor: pointer;
    &:hover {
        background-color: #4E5259;
    }
`

const PageRowStyled = styled.div<{ selected: boolean }>`
    background-color: ${({ selected }) => (selected ? '#821d21' : '#3B4049')};
    flex: 1;
  color : #FFFFFF;
    &:hover {
    background-color: ${({ selected }) => (selected ? '#821d21' : '#4E5259')};
  }
`

const PageHeader = styled.h3`
  color: #FFFFFF;
  margin-bottom: 1rem;
`;