export const routingType = [
  { label: "NORMAL", value: "NORMAL" },
  { label: "ASSEMBLE", value: "ASSEMBLE" },
  { label: "EXTERNAL", value: "EXTERNAL" },
];

export const consumeType = [
  { label: "AT_START", value: "AT_START" },
  { label: "AT_FINISH", value: "AT_FINISH" },
];

export const routingTypeColor: { [key: string]: string } = {
  "NORMAL": "green.7",
  "ASSEMBLE": "blue.7",
  "EXTERNAL": "yellow.7",
};

export const consumeTypeColor: { [key: string]: string } = {
  "AT_START": "blue.7",
  "AT_FINISH": "orange.7",
};
