import { productionPerformances } from "@/api/productionPerformance/useProductionPerformanceQuery";
import { Table } from "@mantine/core";
import { SplitLotGet200ResponseRowsInnerDefectsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQueries } from "@tanstack/react-query";
import { useEffect, useState } from "react";

interface ProductionPerformanceTotalTableProps {
    selectedRows: ReadonlySet<any>;
}
export const ProductionPerformanceTotalTable = (params: ProductionPerformanceTotalTableProps) => {
    const { selectedRows } = params;

    const [selectedRowsArray, setSelectedRowsArray] = useState<any[]>([]);

    useEffect(() => {
        setSelectedRowsArray(Array.from(selectedRows));
    }, [selectedRows]);

    const productionPerformanceQueries = useQueries({
        queries: selectedRowsArray.map((id) => {
            const { queryKey, queryFn } = productionPerformances.detail({ workLogId: id });
            return {
                queryKey,
                queryFn,
                // 추가 설정이 필요하면 여기에 옵션을 추가합니다.
                // 예: staleTime: 1000, cacheTime: 5000
            };
        })
    });

    const productionPerformanceData = productionPerformanceQueries.map(query => query.data);

    const totalProductionSum = productionPerformanceData.reduce((total, current) => {
        if (current && Number(current.data?.productionSum)) {
            return total + Number(current.data?.productionSum);
        }
        return total;
    }, 0);

    const totalTotalQuantity = productionPerformanceData.reduce((total, current) => {
        if (current && Number(current.data?.totalQuantity)) {
            return total + Number(current.data?.totalQuantity);
        }
        return total;
    }, 0);

    const totalDefectSum = productionPerformanceData.reduce((total, current) => {
        if (current && current.data.defects && Array.isArray(current.data.defects)) {
            const defectSum = current.data.defects.reduce((defectTotal, defect: SplitLotGet200ResponseRowsInnerDefectsInner) => {
                return defectTotal + (defect.defect_sum || 0);
            }, 0);

            return total + defectSum;
        }
        return total;
    }, 0);

    const totalWorkTimeInSeconds = productionPerformanceData.reduce((total, current) => {
        const time = current?.data?.timeDifference;
        if (time) {
            const timeInSeconds = ((time.days || 0) * 86400) + ((time.hours || 0) * 3600) + ((time.minutes || 0) * 60) + (time.seconds || 0);
            return total + timeInSeconds;
        }
        return total;
    }, 0);

    const totalWorkTime = [
        Math.floor(totalWorkTimeInSeconds / 86400) + '일',
        Math.floor((totalWorkTimeInSeconds % 86400) / 3600) + '시간',
        Math.floor((totalWorkTimeInSeconds % 3600) / 60) + '분',
        (totalWorkTimeInSeconds % 60) + '초'
    ].join(' ');

    return (
        <Table>
            <thead>
                <tr>
                    <th rowSpan={2}>합계</th>
                    <th>생산수량</th>
                    <th>양품수량</th>
                    <th>불량수량</th>
                    <th>작업시간</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td>{totalProductionSum}</td>
                    <td>{totalTotalQuantity}</td>
                    <td>{totalDefectSum}</td>
                    <td>{totalWorkTime}</td>
                </tr>
            </tbody>
        </Table>
    )
}