import { useModal } from "@/features/modal/ModalStackManager"
import { UsersSearchBox } from "@/features/ui/PMS/userSearchBox"
import CustomTable from "@/features/ui/table/CustomTable"
import { ActionIcon, Box, Button, Flex, Text } from "@mantine/core"
import { IconReportSearch } from "@tabler/icons-react"

export const DailyRegisterModal = () => {

    const { openModal } = useModal();
    const Header = {
        register: ['점검 날짜', '금형명', 'CODE', '금형 종류'],
        register2: ['NO.', '점검 항목', '점검 기준', '점검 방법', '결과', '작성자 확인', '관리자 확인', '문제사항'],
        model: ['범례'],
        etc: ['기타사항']
    }
    const BodyData = {
        register: [
            { '점검 날짜': '2024.08.07', '금형명': '금형명1', 'CODE': 'CODE1', '금형 종류': '금형 종류1' },
        ],
        register2: [
            { 'NO.': '1', '점검 항목': '점검 항목1', '점검 기준': '점검 기준1', '점검 방법': '점검 방법1', '결과': '결과1', 
            '작성자 확인': <ActionIcon onClick={()=> openModal(<UsersSearchBox />, null, '')}><IconReportSearch /></ActionIcon>, 
            '관리자 확인': <ActionIcon onClick={()=> openModal(<UsersSearchBox />, null, '')}><IconReportSearch /></ActionIcon>, '문제사항': '문제사항1' },
        ],
        model: [{ '범례': '없음' }],
        etc: [{ '기타사항': '없음' }]
    }
    return (
        <Box h={'auto'} w={'79rem'}>
            <Flex justify={'end'} mb="sm">
                <Button>저장</Button>
            </Flex>
            <Flex style={{ border: '1px solid black' }} gap="sm" direction={'column'}>
                <Text align="center" size="2rem">일상 점검 리스트</Text>
                <CustomTable
                    headers={Header.register}
                    data={BodyData.register}
                />
                <Flex w="100%">
                    <Flex
                        sx={(theme) => ({
                            border: `1px solid ${theme.colors.gray[3]}`,
                            textAlign: 'center',
                            padding: theme.spacing.md,
                            marginLeft: theme.spacing.sm,
                        })} w="30%">
                        <Text>타이틀</Text>
                        <Box
                            sx={(theme) => ({
                                borderLeft: `1px solid ${theme.colors.gray[3]}`,
                                width: '85%',
                                textAlign: 'center',
                                alignContent: 'center',
                                padding: theme.spacing.md,
                            })}
                        >
                            <Text>사진 없음</Text>
                        </Box>
                    </Flex>
                    <Flex direction="column" sx={{ width: '50%' }}>
                        <Flex wrap="wrap" justify={'center'}>
                            {['부위01', '부위02', '부위03', '부위04', '부위05', '부위06', '부위07', '부위08', '부위09'].map((label, index) => (
                                <Flex
                                    sx={(theme) => ({
                                        border: `1px solid ${theme.colors.gray[3]}`,
                                        textAlign: 'center',
                                        padding: theme.spacing.md,
                                    })} w="30%"
                                >
                                    <Text>{label}</Text>
                                    <Box
                                        key={index}
                                        sx={(theme) => ({
                                            borderLeft: `1px solid ${theme.colors.gray[3]}`,
                                            width: '85%',
                                            textAlign: 'center',
                                            padding: theme.spacing.md,
                                            boxSizing: 'border-box',
                                        })}
                                    >
                                        <Text>사진 없음</Text>
                                    </Box>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                    <Flex direction={'column'} w="22%">
                        <Flex h={'50%'}>
                            <CustomTable
                                headers={Header.model}
                                data={BodyData.model}
                            />
                        </Flex>
                        <Flex h={'50%'}>
                            <CustomTable
                                headers={Header.etc}
                                data={BodyData.etc}
                            />
                        </Flex>
                    </Flex>
                </Flex>
                <CustomTable
                    headers={Header.register2}
                    data={BodyData.register2}
                />
            </Flex>
        </Box>
    )
}