import { mutateSites, sites } from "@/api/sites/useSitesQuery";
import { sitesHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { useModal } from "@/features/modal/ModalStackManager";
import { SiteUpdateForm } from "@/features/site/components/form/SiteUpdateForm";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { useSub } from "@/hooks";
import useQueryString from "@/hooks/useQueryString";
import useUpdateUrlParams from "@/hooks/useUpdateUrlParams";
import { theme } from "@/styles/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Pagination, useMantineColorScheme } from "@mantine/core";
import { MasterApiSitesSiteIdDeleteRequest, SitesGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";
import { SiteDetailForm } from "../form/SiteDetailForm";

interface ColorThemeProps {
  isDarkMode: boolean;
}

export const SiteTable = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";

  const { openModal, closeModal } = useModal();

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );

  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const updateUrlParams = useUpdateUrlParams();

  const { data: site, refetch } = useQuery(sites.get({
    query: query,
    search: search.search,
    searchFields: search.searchFields.length
      ? search.searchFields
      : sitesHeader.map((site) => site.value),
    page: activePage,
    pageSize: Number(search.pageSize),
    sort: sort.length ? sort.join(",") : "-id"
  }));

  // 새로운 데이터가 로드될 때, 현재 페이지가 유효하지 않으면 1페이지로 설정
  useEffect(() => {
    if (site && activePage > (site?.data?.totalPages ?? 0)) {
      setPage(1);
      setQueryString((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [site, activePage, setQueryString]);

  // 페이지 Query String으로 공정 이동
  useEffect(() => {
    updateUrlParams({
      page: activePage,
      pageSize: search.pageSize,
      search: search.search,
      searchFields: search.searchFields,
      query: JSON.stringify(query),
      sort: sort
    }, queryDefaultValues);

    // 선택된 행 초기화
    setSelectedRows(new Set());
    selectedRowsRef.current = new Set();
  }, [activePage, search, query, sort, updateUrlParams]);

  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiSitesSiteIdDeleteRequest) =>
      mutateSites.delete(params).mutationFn(params as MasterApiSitesSiteIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          "사업장 삭제에 성공하였습니다.",
          "삭제 성공",
          "green"
        );
        closeModal(null);
        selectedRowsRef.current = new Set();
        setSelectedRows(new Set());
      },
      onError: (error) => {
        customAlert(
          "사업장 삭제에 실패하였습니다.",
          "삭제 실패",
          "red"
        );
      },
    }
  );

  const rows: readonly SitesGet200ResponseRowsInner[] = site?.data.rows ?? [];

  const columns: readonly Column<SitesGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "code",
      name: "사업장코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <DetailLink onClick={() => openModal(<SiteDetailForm siteCode={formatterProps.row.code} />, null, "")}>
            {formatterProps.row.code}
          </DetailLink>
        );
      },
    },
    {
      key: "name",
      name: "사업장명",
      sortable: true,
      resizable: true,
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <OptionBox>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconEdit />}
              color="yellow"
              onClick={() => {
                openModal(
                  <SiteUpdateForm formatterProps={formatterProps.row} />,
                  {},
                  "사업장 업데이트",
                  true
                );
              }}
            ></OptionBtn>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconTrash />}
              color="red"
              onClick={() => {
                openModal(
                  <ConfirmForm 
                    message="정말로 삭제하시겠습니까?"
                    yesCallback={() => 
                      deleteMutate(
                        {
                          siteId: formatterProps.row.id as number,
                        },
                      )
                    }
                    noCallback={() => closeModal(null)}
                  />,
                  null,
                  "사업장 삭제",
                );
              }}
            ></OptionBtn>
          </OptionBox>
        );
      },
    },
  ];

  useSub("deleteSelectedItems", () => {
    const rows = Array.from(selectedRowsRef.current);
    if (!rows.length) return;
    const deleteRows: number[] = Array.from(rows[rows.length - 1].values());
    openModal(
      <ConfirmForm 
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => 
          deleteMutate(
            {
              siteId: deleteRows[deleteRows.length - 1],
            },
          )
        }
        noCallback={() => closeModal(null)}
      />,
      null,
      "사업장 삭제",
    );
  });

  return (
    <SiteTableWrapper>
      <GridWrapper isDarkMode={isDarkMode}>
        <TableWrapper>
          <RetrieveWrapper>
            <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
              <CustomFilter filterType={sitesHeader} setQuery={setQuery} query={query} />
              <CustomSorter sorterType={sitesHeader} setSort={setSort} sort={sort} />
            </Flex>
            <SearchBox searchType={sitesHeader} setSearch={setSearch} search={search} />

          </RetrieveWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rows ?? []}
            rowHeight={40}
            rowKeyGetter={(row: SitesGet200ResponseRowsInner) => row.id}
            sortColumns={sortColumns}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
        </TableWrapper>
        <PaginationWrapper>
          <Pagination
            onChange={setPage}
            value={activePage}
            total={site?.data?.totalPages ?? 0}
            size="lg"
            radius="sm"
          />
        </PaginationWrapper>
      </GridWrapper>
    </SiteTableWrapper>
  );
};

const SiteTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;
const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;
