import { ChartLogicType, SeriesType } from "@/features/PMS/output/service/types";

export class ChartLogic {

    private setSeries = (series: SeriesType[]) => {
        if (!series || !Array.isArray(series)) {
            throw new Error('관리자에게 문의해주시기 바랍니다.');
        }
        return series;
    }

    private setXaxis = (times: string[]) => {
        if (!times) {
            throw new Error('관리자에게 문의해주시기 바랍니다.');
        }
        return {
            categories: times,
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        }
    }

    private setOptions = (xaxis: string[], options: any) => {
        return {
            xaxis: this.setXaxis(xaxis),
            tooltip: {
                enabled: true,
                style: {
                    fontSize: '12px',
                    fontFamily: undefined,
                },
            },
            chart: { type: 'bar', height: 350, stacked: true, toolbar: { show: false } },
            plotOptions: {
                bar: {
                    rangeBarOverlap: true,
                    dataLabels: {
                        enabled: true,
                        total: {
                            enabled: true,
                            style: {
                                color: '#ffffff',
                                fontSize: '15px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            ...options
        }
    }

    setChartLogic = ({ series, xaxis, options }: ChartLogicType) => {
        return {
            series: this.setSeries(series),
            options: {
                ...this.setOptions(xaxis, options),
            },
        }
    }
}
