import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Pagination, useMantineColorScheme } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

import { mutatePurchaseOrderItems, purchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { purchaseOrderItemsHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { OrderItemsUpdateForm } from "@/features/orderItems/form/updarteForm";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { useSub } from "@/hooks";
import useQueryString from "@/hooks/useQueryString";
import useUpdateUrlParams from "@/hooks/useUpdateUrlParams";
import { theme } from "@/styles/theme";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models/src/model";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";

interface ColorThemeProps {
    isDarkMode: boolean;
}

interface OrderDetailTableProps {
    onRowSelect: (
        selectedRows: ReadonlySet<any>,
        formatterPropsData: any,
        selectedRowsRef: React.MutableRefObject<ReadonlySet<any>>,
        selectedRowsInform: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]
    ) => void;
    orderId: number | undefined;
}

export const OrderDetailTable = ({ onRowSelect, orderId }: OrderDetailTableProps) => {
    const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const isDarkMode: boolean = colorScheme === "light";
    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const [selectedRows, setSelectedRows] = useState(
        (): ReadonlySet<any> => new Set()
    );
    const [selectedRowsInform, setSelectedRowsInform] = useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]>([]);

    const [formatterPropsData, setFormatterPropsData] = useState<any>([]);
    const [activePage, setPage] = useState(initialQueryString.page);
    const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
    const [sort, setSort] = useState(initialQueryString.sort);
    const [search, setSearch] = useState<SearchProps>({
        search: initialQueryString.search,
        searchFields: initialQueryString.searchFields,
        pageSize: initialQueryString.pageSize,
    });

    const updateUrlParams = useUpdateUrlParams();

    const { openModal, closeModal } = useModal();
    const { mutate: deleteMutate } = useMutation(
        (params: ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest) =>
            mutatePurchaseOrderItems.delete(params).mutationFn(params as any | ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest),
        {
            onSuccess: () => {
                refetch();
                closeModal(null);
                customAlert(
                    "발주 항목 삭제에 성공하였습니다.",
                    "삭제 성공",
                    "green"
                );
                setSelectedRows(new Set());
                selectedRowsRef.current = new Set();
            },
            onError: (error: any) => {
                customAlert(
                    `${error.response?.data?.message}` ?? "발주 항목 삭제에 실패하였습니다.",
                    "삭제 실패",
                    "red"
                );
            }
        }
    );

    const searchFieldsHeader = purchaseOrderItemsHeader.filter(
        (purchaseOrderItems) => purchaseOrderItems.category === "text"
    );

    const { data: orderData, refetch } = useQuery(purchaseOrderItems.get({
        query: {
            ...query,
            $and: [
                ...(query.$and || []),
                { purchaseOrderId: orderId },
                { direction: "OUTBOUND" }
            ],
        },
        search: search.search,
        searchFields: search.searchFields.length
            ? search.searchFields
            : searchFieldsHeader.map((purchaseOrderItems) => purchaseOrderItems.value),
        page: activePage,
        pageSize: Number(search.pageSize),
        sort: sort.length ? sort.join(",") : "-id",
        populate: ["itemName", "user", "quantities", "location"]
    }));

    // 새로운 데이터가 로드될 때, 현재 페이지가 유효하지 않으면 1페이지로 설정
    useEffect(() => {
        if (orderData && activePage > (orderData?.data?.totalPages ?? 0)) {
            setPage(1);
            setQueryString((prev) => ({
                ...prev,
                page: 1,
            }));
        }
    }, [orderData, activePage, setQueryString]);

    // 페이지 Query String으로 공정 이동
    useEffect(() => {
        updateUrlParams({
            page: activePage,
            pageSize: search.pageSize,
            search: search.search,
            searchFields: search.searchFields,
            query: JSON.stringify(query),
            sort: sort
        }, queryDefaultValues);

        setSelectedRows(new Set());
        selectedRowsRef.current = new Set();
    }, [activePage, search, query, sort, updateUrlParams]);

    useEffect(() => {
        const selectedRowId = selectedRows.values().next().value;
        if (selectedRowId) {
            const selectedRow = rows.find((row) => row.id === selectedRowId);

            setFormatterPropsData(selectedRow);
        } else {
            setFormatterPropsData([]);
        }
    }, [selectedRows]);

    useEffect(() => {
        // 부모에게 선택된 row를 넘겨주기 위한 함수
        if (formatterPropsData) {
            onRowSelect(selectedRows, formatterPropsData, selectedRowsRef, selectedRowsInform);
        }
    }, [selectedRows, formatterPropsData]);

    const rows: readonly AuthSignupPost201ResponseEquipmentPurchaseOrderItem[] =
        orderData?.data.rows ?? [];
    const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

    const columns: readonly Column<AuthSignupPost201ResponseEquipmentPurchaseOrderItem>[] = [
        {
            ...SelectColumn,
            width: 70,
            maxWidth: 500,
            resizable: true,
            headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
            cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
        },
        {
            key: "itemName",
            name: "품목",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <DetailLink>{formatterProps.row.itemName}</DetailLink>
                )
            }
        },
        {
            key: "quantity",
            name: "수량",
            sortable: true,
            resizable: true,
        },
        {
            key: "sumOfStockPlanQuantity",
            name: "납품 계획된 수량",
            sortable: true,
            resizable: true,
            cellClass: css`
        justify-content: flex-end;
      `,
        },
        {
            key: "sumOfDeliveredQuantity",
            name: "납품 완료 수량",
            sortable: true,
            resizable: true,
            cellClass: css`
        justify-content: flex-end;
      `,
        },
        {
            key: "manager",
            name: "담당자 명",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <DetailLink
                        onClick={() => openModal(
                            <UsersDetailForm UserCode={formatterProps.row.userCode} />,
                            null,
                            "사용자 상세",
                        )}
                    >{formatterProps.row.manager}</DetailLink>
                )
            }
        },
        {
            key: "targetLocationName",
            name: "입고 로케이션",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <DetailLink
                        onClick={() => openModal(
                            <LocationDetailForm locationCode={formatterProps.row.targetLocationCode} />,
                            null,
                            "로케이션 상세",
                        )}
                    >{formatterProps.row.targetLocationName}</DetailLink>
                )
            }
        },
        {
            key: "createdAt",
            name: "접수일",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <div>
                        {formatterProps.row.createdAt !== null ? DatetimeUtil(formatterProps.row.createdAt as string) : ''}
                    </div>
                )
            },
        },
        {
            key: "deliveryDeadline",
            name: "납기일",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <div>
                        {formatterProps.row.deliveryDeadline !== null ? DatetimeUtil(formatterProps.row.deliveryDeadline as string) : ''}
                    </div>
                )
            },
        },
        {
            key: "options",
            name: "액션",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {

                return (
                    <OptionBox>
                        <OptionBtn
                            variant="subtle"
                            leftIcon={<IconEdit />}
                            color="yellow"
                            onClick={() => openModal(
                                <OrderItemsUpdateForm formatterProps={formatterProps.row} />,
                                null,
                                "발주 항목 수정"
                            )}
                        ></OptionBtn>
                        <OptionBtn
                            variant="subtle"
                            leftIcon={<IconTrash />}
                            color="red"
                            onClick={() => {
                                openModal(
                                    <ConfirmForm
                                        message="정말 삭제하시겠습니까?"
                                        yesCallback={() => deleteMutate({ purchaseOrderItemId: formatterProps.row.id ?? 0 })}
                                        noCallback={() => closeModal(null)}
                                    />,
                                    null,
                                    "발주 항목 삭제"
                                )
                            }}
                        ></OptionBtn>
                    </OptionBox>
                );
            },
        },
    ];

    useSub("deleteSelectedItems", () => {
        const rows = Array.from(selectedRowsRef.current);
        if (!rows.length) return;
        const deleteRows: number[] = Array.from(rows[rows.length - 1].values());

        openModal(
            <ConfirmForm
                message="선택된 행을 삭제하시겠습니까?"
                yesCallback={() => {
                    deleteRows.forEach((row: number) => {
                        deleteMutate({ purchaseOrderItemId: deleteRows[deleteRows.length - 1] });
                    });
                }}
                noCallback={() => closeModal(null)}
            />,
            null,
            "선택된 행 삭제"
        )
    });

    return (
        <OrderDetailTableWrapper>
            <GridWrapper isDarkMode={isDarkMode}>
                <TableWrapper>
                    <RetrieveWrapper>
                        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
                            <CustomFilter filterType={purchaseOrderItemsHeader} setQuery={setQuery} query={query} />
                            <CustomSorter sorterType={purchaseOrderItemsHeader} setSort={setSort} sort={sort} />
                        </Flex>
                        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
                    </RetrieveWrapper>
                    <DataGrid
                        columns={columns ?? []}
                        rows={rows as any}
                        rowHeight={40}
                        rowKeyGetter={(row: AuthSignupPost201ResponseEquipmentPurchaseOrderItem) => row.id}
                        sortColumns={sortColumns}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={(e) => {
                            setSelectedRows(e);
                            const newSelectedRows = new Set(selectedRowsRef.current);
                            newSelectedRows.add(e);
                            selectedRowsRef.current = newSelectedRows;
                            setSelectedRowsInform(selectedRowsInform);
                        }}
                    />
                </TableWrapper>
                <PaginationWrapper>
                    <Pagination
                        onChange={(page) => {
                            setSelectedRows(new Set());
                            setSelectedRowsInform([])
                            setPage(page)
                        }}
                        value={activePage}
                        total={orderData?.data?.totalPages ?? 0}
                        size="lg"
                        radius="sm"
                    />
                </PaginationWrapper>
            </GridWrapper>
        </OrderDetailTableWrapper>
    );
};

const OrderDetailTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
        props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
        props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  gap: 10px;
  padding : 10px;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;
const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;