import { useQuery } from "@tanstack/react-query";

import DefaultInstance, { MasterInstance } from "@/instance/axios";
import { AuthSignupPost201ResponseEquipment, EquipmentsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";

export type EquipmentOption = {
    value: string;
    label: string;
};

export const useEquipmentsMultiAutoComplete = (
    focus = false,
    keyword = "",
    options?: {
        onSuccess: (data: EquipmentOption[]) => void;
    }
) => {
    return useQuery(
        ["equipments-get-multi-autocomplete-keyword", keyword],
        () =>
            MasterInstance.equipmentsFindPost({
                search: keyword,
                searchFields: ["name"],
            }).then((res) => res.data),
        {
            enabled: focus,
            select: (
                data: AuthSignupPost201ResponseEquipment[]
            ): EquipmentOption[] =>
                data.map((Equipment) => {
                    return {
                        value: String(Equipment.code),
                        label: String(Equipment.name),
                    };
                }) ?? [],
            onSuccess: options?.onSuccess,
        }
    );
};

const getEquipmentByCode = (code: string | null) =>
    DefaultInstance.equipmentsGet({
        ...(code && { search: code }),
        searchFields: ["name", "code"],
    }).then((res) => res.data);

export const useEquipmentsMultiCode = (
    enabled: boolean = false,
    code: string | null,
    options?: {
        onSuccess: (data?: EquipmentOption[]) => void;
    }
) => {
    return useQuery(
        ["equipments-get-multi-code", code],
        () => getEquipmentByCode(code),
        {
            enabled,
            select: (data: EquipmentsGet200Response) =>
                data?.rows?.map((Equipment) => {
                    return {
                        value: Equipment.code as string,
                        label: Equipment.name as string,
                    };
                }),
            onSuccess: options?.onSuccess,
        }
    );
};
