import { FrequentlyProgressBarLegendViewButton, FrequentlyProgressBarProgressGrid, FrequentlyProgressBarValue } from '@/features/PMS/frequently/Frequently'
import FrequentlyFlexMiddle from '@/features/PMS/frequently/FrequentlyFlexMiddle'
import FrequentlyLabel from '@/features/PMS/frequently/FrequentlyLabel'
import styled from '@emotion/styled'
import React, { CSSProperties } from 'react'


interface Props {
    title?: string
    maximum: number
    containerStyle?: CSSProperties
    progressContainerStyle: CSSProperties
    value: FrequentlyProgressBarValue[]
    format?: string
    type? : string
    Drawscale?: boolean
}

const Container = styled.div(() => ({
    backgroundColor: '#111319',
    padding: '8px 16px'

}))

const ProgressContainer = styled.div(() => ({
    display: 'flex',
    marginTop: 8,
    backgroundColor: '#353B48',
    width: '100%'
}))

const LegendButton = styled.div(({ backgroundColor }: FrequentlyProgressBarLegendViewButton) => ({
    width: 24,
    height: 8,
    backgroundColor,
    marginRight: 8,
    marginTop: 5
}))


const ProgressGrid = styled.div(({ width, backgroundColor }: FrequentlyProgressBarProgressGrid) => ({
    width,
    backgroundColor,
    // padding: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))




const FrequentlyProgressBar: React.FunctionComponent<Props> = ({
    title,
    maximum,
    progressContainerStyle,
    value,
    format,
    containerStyle
}) => {

    const resizeWidth = React.useCallback((target: number) => {
        return (maximum * target / 100) + '%'
    }, [value, maximum])


    const valueValidation = React.useMemo(() => {
        let deadCount = 0

        value.forEach((element) => {
            element.value === 0 && deadCount++
        })

        return !(deadCount === value.length)
    }, [value])

    const exceptionStyles = React.useCallback((elementValue: number, index: number) => {
        let position = {}
        if (value) {
            if (value.length > 0) {
                if (index === 0) {
                    if (elementValue < 4 && value[index + 1] && value[index + 1].value < 4) {
                        position = {
                            paddingLeft: 100
                        }
                    }
                } else {
                    if (elementValue < 4) {
                        if (index > value.length - 2) {
                            if (value[index + 1] && value[index + 1].value < 4) {
                                position = {
                                    paddingLeft: 200
                                }
                            } else if (value[index - 1] && value[index - 1].value < 4) {
                                console.log('value[index - 1].value < 4', value[index - 1].value)
                                position = {
                                    paddingRight: 200
                                }
                            }
                        }
                    }
                }
            }
        }
        return position
    }, [])


    return (
        <Container style={containerStyle}>
            <div>
                <FrequentlyLabel text={title} size={16} weight={'bold'} />
            </div>
            <ProgressContainer style={progressContainerStyle}>
                {
                    valueValidation ? value.map((element, index) => {
                        return (
                            <React.Fragment>
                                <ProgressGrid width={resizeWidth(element.value)}
                                    backgroundColor={element.color}
                                    key={element.name}
                                    data-for={element.name}
                                    data-tip>

                                    <FrequentlyLabel text={element.value > 3 ? element.value + (format ? format : '%') : undefined} size={15} weight={'bold'}
                                        textShadow={'0px 0px 3px #000000B0'}
                                        fontFamily={'Noto Sans CJK KR'}

                                        containerStyles={{ position: 'absolute', ...exceptionStyles(element.value, index) }} />

                                </ProgressGrid>
                                {/* <ReactTooltip id={element.name} place="top" effect="solid" backgroundColor={element.color}>
                                    <span>{`${element.value}%`}</span>
                                </ReactTooltip> */}
                            </React.Fragment>
                        )
                    }
                    ) : <FrequentlyFlexMiddle styles={{ width: '100%' }}>
                        <FrequentlyLabel text={'정보 없음'} size={24} weight={'bold'}
                            fontFamily={'Noto Sans CJK KR'} textAlign={'center'}
                            containerStyles={{ opacity: .5 }} />
                    </FrequentlyFlexMiddle>
                }

            </ProgressContainer>

            {/*Legend*/}
            <FrequentlyFlexMiddle styles={{ height: 30 }}>
                {
                    value.map((element) => <FrequentlyFlexMiddle key={element.name}>
                        <LegendButton backgroundColor={element.color} />
                        <div style={{ marginRight: 16 }}>
                            <FrequentlyLabel text={element.name} size={12} />
                        </div>
                    </FrequentlyFlexMiddle>)
                }
            </FrequentlyFlexMiddle>
        </Container>
    )
}

export default FrequentlyProgressBar
