import { customFunctions } from "@/config/customFunction";
// Routing 페이지의 유효성 검사 함수

type PageType =
  | "items"
  | "equipment"
  | "defects"
  | "operations"
  | "routing"
  | "users"
  | "site"
  | "location"
  | "routingOutsource"
  | "inspectSpecification"
  | "mold"
  | "moldStock"
  | "moldCavity"
  | "abstractItem"
  | "itemCategory"
  | "downtimeReasons"
  | "inputMaterial"
  | "incoming"
  | "incomingAll"
  | "purchaseOrderItems"
  | "purchaseOrder"
  | "material"
  | "partner"
  | "stockPlan"
  | "transportation"
  | "systemParameter"
  | "commonCode"
  | "partCommonCode"
  | "department"
  | "bom"
  | "authority"
  | "peripheral"
  | "machine"
  ;

interface RoutingFormValues {
  pageType: PageType;
  values: any; // TODO: 타입 지정
}

// 페이지별 유효성 검사
export function validateFormValues(params: RoutingFormValues) {
  const { pageType, values } = params;

  switch (pageType) {
    case "items":
      return !(values.itemCode && values.code);
    case "equipment":
      return !(values.siteCode && values.code && values.name);
    case "defects":
      return !(values.code && values.name);
    case "operations":
      return !(
        values.siteCode &&
        values.code &&
        values.fromLocationCode &&
        values.toLocationCode
      );
    case "routing":
      return !(
        values.itemCode &&
        values.operationCode &&
        values.code &&
        values.routingType &&
        values.consumeType &&
        values.seq
      );
    case "users":
      return !(values.code && values.name && values.username);
    case "site":
      return !(values.code && values.name);
    case "location":
      return !(values.code && values.name && values.siteCode);
    case "routingOutsource":
      return !(
        values.name &&
        values.routingCode &&
        values.fromLocationCode &&
        values.toLocationCode
      );
    case "inspectSpecification":
      return !(values.itemCode && values.criteria);
    case "mold":
      return !values.code;
    case "moldStock":
      return !(values.code && values.moldCode);
    case "moldCavity":
      return !(values.moldCode && values.num);
    case "abstractItem":
      return !values.code;
    case "itemCategory":
      return !values.name;
    case "downtimeReasons":
      return !(values.code && values.name);
    case "inputMaterial":
      return !(values.lotId && values.sourceLocationCode);
    case "incoming":
      return customFunctions.ADD_VALIDATION_INCOMING_LOT ? 
        !(values.itemCode && values.locationCode && values.quantity && values.lotId) : 
        !(values.itemCode && values.locationCode && values.quantity);
    case "incomingAll":
      return !(values.itemCode && values.locationCode && values.quantity && values.lotInfo.name && values.num);
    case "purchaseOrderItems":
      return !(values.partnerCode && values.itemCode && values.quantity);
    case "purchaseOrder":
      return !(values.partnerCode && values.code);
    case "material":
      return !(values.code && values.name && values.itemType && values.unitText);
    case "partner":
      return !(values.code && values.name && values.contact && values.type);
    case "stockPlan":
      return !(values.scheduledAt && values.itemCode && values.quantity);
    case "transportation":
      return !(values.partnerCode && values.direction);
    case "systemParameter":
      return !(values.systemKey && values.systemValue);
    case "commonCode":
      return !(values.commonCode && values.codeName);
    case "partCommonCode":
      return !(values.categoryCode && values.categoryName);
    case "department":
      return !(values.departmentCode && values.departmentName);
    case "bom":
      return !(values.partnerCode && values.modelName && values.bomCode && values.itemName && values.spec && values.itemType && values.unitText && values.operationCode);
    case "authority":
      return !values.authorityName;
    case "peripheral":
      return !(values.manufacturedAt && values.manufactureCompany && values.modelName && values.modelType && values.manufactureId && values.userName && values.factoryName && values.factoryDetailName);
    case "machine":
      return !(values.machineCompany && values.machineName && values.machineType && values.manufacturedAt && values.manufactureId && values.userName && values.factoryName && values.factoryDetailName);
    default:
      break;
  }

  return false;
}
