import { MasterInstance } from "@/instance/axios";
import { createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiEquipmentsAnalysisPostRequest } from "@sizlcorp/sizl-api-document/dist/models";


const ANALYSIS_QUERY_KEY = 'analysis';

export const analysis = createQueryKeys(ANALYSIS_QUERY_KEY, {
    all: null,
    get: (params: MasterApiEquipmentsAnalysisPostRequest) => {
        return {
            queryKey: [params.equipmentsAnalysisPostRequest],
            queryFn: () => MasterInstance.equipmentsAnalysisPost(params)
        }
    },

})


export const wmsLogQuery = mergeQueryKeys(analysis);