import { DEPARTMENT_QUERY_KEY, mutateDepartment } from "@/api/department/useDepartmentQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { Box, Button, Checkbox, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DepartmentsGetRequest, MasterApiDepartmentsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Checkbox,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
});

interface DepartmentCreateFormProps {
    code: string;
}

export const DepartmentCreateForm = ({ code }: DepartmentCreateFormProps) => {
    const { closeModal } = useModal();

    const form = useForm<DepartmentsGetRequest>({
        initialValues: {
            code: code,
            name: '',
        },
        validate: {
            code: (value) => value ? null : '부서 코드를 입력해주세요.',
            name: (value) => value ? null : '부서명을 입력해주세요.',
        }
    })
    const queryClient = useQueryClient();

    const { mutate: createMutate } = useMutation(
        (params: MasterApiDepartmentsPostRequest) =>
            mutateDepartment.create(params).mutationFn(undefined),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([DEPARTMENT_QUERY_KEY]);
                customAlert(
                    "부서가 성공적으로 등록되었습니다.",
                    "등록 성공",
                    "green"
                )
            },
            onError: (error: AxiosError<Error>) => {
                customAlert(
                    error.response?.data?.message ?? "부서 등록에 실패하였습니다.",
                    "등록 실패",
                    "red"
                )
            }
        }
    );


    const onSubmit = (values: DepartmentsGetRequest) => {
        createMutate({
            departmentsGetRequest: values
        });
        closeModal({});
    };

    return (
        <Box>
            <form
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    width: "30rem",
                    height: "15rem",
                }}
                onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}
            >
                <Create.Input
                    withAsterisk
                    label="부서 코드"
                    placeholder="부서 코드"
                    {...form.getInputProps("code")}
                />
                <Create.Input
                    withAsterisk
                    label="부서명"
                    placeholder="부서명"
                    {...form.getInputProps("name")}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>취소</Create.Button>
                    <Create.Button
                        disabled={!form.isValid()}
                        type="submit"
                    >
                        부서 생성
                    </Create.Button>
                </Create.ButtonBox>
            </form>
        </Box>
    )
};