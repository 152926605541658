import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import DatetimeUtil from "@/utils/dateTimeUtil";
import timeUtil from "@/utils/timeUtil";
import { Space } from "@mantine/core";

const ProductionPlan = Object.assign(DetailHeader, {
    Title: DetailTitle,
    Inform: DetailInformTitle,
    Field: DetailField,
    Label: DetailLabel,
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
    Content: DetailContent,
});

interface ProductionPlanPurchaseOrderItemFormProps {
    purchaseOrderItemData: any;
}

export const ProductionPlanPurchaseOrderItemForm = (params: ProductionPlanPurchaseOrderItemFormProps) => {
    const { purchaseOrderItemData } = params;
    const { openModal } = useModal();
    
    return (
        <ProductionPlan>
            <ProductionPlan.Title>{`[수주번호] ${purchaseOrderItemData?.purchaseOrderItem?.purchaseOrderId}`}</ProductionPlan.Title>
            <Space h={8} />
            <ProductionPlan.Field>
                <ProductionPlan.Label>{"협력사 코드"}</ProductionPlan.Label>
                <ProductionPlan.Content>{purchaseOrderItemData?.purchaseOrderItem?.partnerCode}</ProductionPlan.Content>
            </ProductionPlan.Field>
            <ProductionPlan.Field>
                <ProductionPlan.Label>{"생산 품목명"}</ProductionPlan.Label>
                <DetailLinkWrapper>
                    <ProductionPlan.Badge>{"제품"}</ProductionPlan.Badge>
                    <ProductionPlan.Link
                        onClick={() => {
                            openModal(
                                <ItemsDetailForm itemCode={purchaseOrderItemData?.itemCode} />,
                                null,
                                ""
                            );
                        }}
                    >
                        {purchaseOrderItemData?.itemName}
                    </ProductionPlan.Link>
                </DetailLinkWrapper>
            </ProductionPlan.Field>
            <ProductionPlan.Field>
                <ProductionPlan.Label>{"납기일자"}</ProductionPlan.Label>
                <ProductionPlan.Content>{DatetimeUtil(purchaseOrderItemData?.purchaseOrderItem?.deliveryDeadline ?? '')}</ProductionPlan.Content>
            </ProductionPlan.Field>
            <ProductionPlan.Field>
                <ProductionPlan.Label>{"접수일"}</ProductionPlan.Label>
                <ProductionPlan.Content>{DatetimeUtil(purchaseOrderItemData?.purchaseOrderItem?.orderDate ?? '')}</ProductionPlan.Content>
            </ProductionPlan.Field>
            <ProductionPlan.Field>
                <ProductionPlan.Label>{"작업 일시"}</ProductionPlan.Label>
                <ProductionPlan.Content>{timeUtil(purchaseOrderItemData?.scheduledAt ?? '')}</ProductionPlan.Content>
            </ProductionPlan.Field>
            <ProductionPlan.Field>
                <ProductionPlan.Label>{"생성정보"}</ProductionPlan.Label>
                <ProductionPlan.Content>{purchaseOrderItemData?.creatorUserCode}({DatetimeUtil(purchaseOrderItemData?.createdAt ?? '')})</ProductionPlan.Content>
            </ProductionPlan.Field>
            <ProductionPlan.Field>
                <ProductionPlan.Label>{"수정정보"}</ProductionPlan.Label>
                <ProductionPlan.Content>{purchaseOrderItemData?.updaterUserCode}({DatetimeUtil(purchaseOrderItemData?.updatedAt ?? '')})</ProductionPlan.Content>
            </ProductionPlan.Field>
            <ProductionPlan.Field>
                <ProductionPlan.Label>{"비고"}</ProductionPlan.Label>
                <ProductionPlan.Content>{purchaseOrderItemData?.description}</ProductionPlan.Content>
            </ProductionPlan.Field>
        </ProductionPlan>
    )
}