import useGetOverViewQuery from "@/api/overview/useGetOverViewQuery";
import { DowntimeReasonsDetailForm } from "@/features/downtimeReasons/form/DetailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { theme } from "@/styles/theme";
import { setFilteringDecimalPoint, setToLocaleString } from "@/utils/unitMark";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import {
  Anchor,
  useMantineColorScheme
} from "@mantine/core";
import { useEffect } from "react";
import type { Column } from "react-data-grid";
import DataGrid from "react-data-grid";
import { useTranslation } from "react-i18next";

interface Row {
  equipmentName: string;
  equipmentStatus: string;
  downtimeReasonCode: string;
  downtimeReasonName: string;
  itemCode: string;
  itemName: string;
  itemSpec: string;
  goalForToday: string;
  goalForNow: number;
  output: number;
  totalTime: number;
  routingTime: number;
  nonRoutingTime: number;
}

interface ColorThemeProps {
  isDarkMode: boolean;
}

export const OverViewTable = () => {
  const { t } = useTranslation();
  // const [rows, setRows] = useState<any>(createRows);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";


  const { data: overView, refetch } = useGetOverViewQuery();

  useEffect(() => { refetch() }, [])

  const rows: readonly Row[] = overView?.data ?? [];

  const { openModal } = useModal();
  const columns: readonly Column<Row>[] = [
    {
      key: "equipmentName",
      name: "설비명",
      sortable: true,
      resizable: true,
    },
    {
      key: "equipmentStatus",
      name: "설비 상태",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <>
            {t(row.equipmentStatus)}
          </>
        )
      },
    },
    {
      key: "downtimeReasonName",
      name: "일시정지 사유",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <>
            <Anchor
              onClick={() => openModal(<DowntimeReasonsDetailForm downtimeReasonCode={row.downtimeReasonCode} />, null, "")}
            >
              {row.downtimeReasonName}
            </Anchor>
          </>
        )

      },
    },
    {
      key: "itemCode",
      name: "품목코드",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <>
            <Anchor
              onClick={() => openModal(<ItemsDetailForm itemCode={row.itemCode} />, null, "")}
            >
              {row.itemCode}
            </Anchor>
          </>
        )
      },
    },
    {
      key: "itemName",
      name: "품목명",
      sortable: true,
      resizable: true,
    },
    {
      key: "itemSpec",
      name: "규격",
      width: 100,
      sortable: true,
      resizable: true,
    },
    {
      key: "goalForToday",
      name: "일일 목표",
      width: 100,
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => {
        return (
          <>
            {row.goalForToday === '0' ? '' : setToLocaleString(row.goalForToday)}
          </>
        )
      },
    },
    {
      key: "goalForNow",
      name: "현재 목표",
      width: 100,
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => {
        return (
          <>
            {setToLocaleString(row.goalForNow)}
          </>
        )
      },
    },
    {
      key: "output",
      name: "생산수량",
      width: 100,
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => {
        return (
          <>
            {setToLocaleString(row.output)}
          </>
        )
      },
    },
    {
      key: "totalTime",
      name: "가동률",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <>
            {setFilteringDecimalPoint(row.routingTime / row.totalTime, 2)} %
          </>
        )
      },
    },
  ];

  return (
    <ItemTableWrapper>
      <GridWrapper isDarkMode={isDarkMode}>
        <TableWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rows ?? []}
            rowHeight={40}
            rowKeyGetter={(row: Row) => row.equipmentName}
          />
        </TableWrapper>
      </GridWrapper>
    </ItemTableWrapper>
  );
};

const ItemTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

