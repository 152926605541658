import { useQuery } from "@tanstack/react-query";

import { MasterInstance } from "@/instance/axios";
import { SitesGet200Response, SitesGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";

export type SiteOption = {
    value: string;
    label: string;
};

export const useSitesMultiAutoComplete = (
    focus = false,
    keyword = "",
    options?: {
        onSuccess: (data: SiteOption[]) => void;
    }
) => {
    return useQuery(
        ["sites-get-multi-autocomplete-keyword", keyword],
        () =>
            MasterInstance.sitesFindPost({
                search: keyword,
                searchFields: ["name"],
            }).then((res) => res.data),
        {
            enabled: focus,
            select: (
                data: SitesGet200ResponseRowsInner[]
            ): SiteOption[] =>
                data.map((Site) => {
                    return {
                        value: String(Site.code),
                        label: String(Site.name),
                    };
                }) ?? [],
            onSuccess: options?.onSuccess,
        }
    );
};

const getSiteByName = (name: string | null) =>
    MasterInstance.sitesGet({
        ...(name && { search: name }),
        searchFields: ["name"],
    }).then((res) => res.data);

export const useSitesMultiCode = (
    enabled: boolean = false,
    name: string | null,
    options?: {
        onSuccess: (data?: SiteOption[]) => void;
    }
) => {
    return useQuery(
        ["sites-multi-get-name", name],
        () => getSiteByName(name),
        {
            enabled,
            select: (data: SitesGet200Response) =>
                data?.rows?.map((Site) => {
                    return {
                        value: Site.code as string,
                        label: Site.name as string,
                    };
                }),
            onSuccess: options?.onSuccess,
        }
    );
};
