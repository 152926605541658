import { mutateAbstractltems } from "@/api/abstractItems/useAbstractltemsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { AbstractItemsGet200ResponseRowsInner, DefaultApiAbstractItemsAbstractItemIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Update = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
});

export const AbstractItemUpdateForm = (params: {
    formatterProps: AbstractItemsGet200ResponseRowsInner;
}) => {
    const { closeModal } = useModal();
    const { formatterProps } = params;
    const queryClient = useQueryClient();
    const { mutate: updateMutate } = useMutation(
        (params: DefaultApiAbstractItemsAbstractItemIdPutRequest) =>
            mutateAbstractltems.update(params).mutationFn(params as DefaultApiAbstractItemsAbstractItemIdPutRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['abstractItems']);
            },
        }
    );

    const form = useForm({
        initialValues: {
            code: formatterProps.code,
        },
    });

    const onSubmit = () => {
        try {
            updateMutate(
                {
                    abstractItemId: formatterProps.id as number,
                    abstractItemsGetRequest: {
                        code: form.values.code!,
                    },
                },
                {
                    onSuccess: (res) => {
                        closeModal(formatterProps);
                        if (res.status === 200) {
                            customAlert(
                                "품목군 수정에 성공하였습니다.",
                                "수정 성공",
                                "green"
                            )
                        } else {
                            customAlert(
                                "품목군 수정에 실패하였습니다.",
                                "수정 실패",
                                "red"
                            )
                        }
                    },
                }
            );
        } catch (e) {
            customAlert(
                "품목군 수정에 실패하였습니다.",
                "수정 실패",
                "red"
            )
        }
    };

    return (
        <Update.Wrapper>
            <Update.Input
                label="품목군 코드"
                withAsterisk
                defaultValue={formatterProps?.code}
                {...form.getInputProps("code")}
                error={form.isDirty("code") ? "저장 필요" : ""}
                styles={{
                    input: {
                        color: form.isDirty("code") ? "red !important" : "",
                        borderColor: form.isDirty("code")
                            ? "red !important"
                            : "",
                    },
                    error: { fontSize: 11, color: "red" },
                }}
            />
            <Update.ButtonBox>
                <Update.Button color="gray" onClick={closeModal}>
                    취소
                </Update.Button>
                <Update.Button
                    color="blue"
                    disabled={validateFormValues({
                        pageType: "abstractItem",
                        values: form.values,
                    })}
                    onClick={onSubmit}
                >
                    품목군 수정
                </Update.Button>
            </Update.ButtonBox>
        </Update.Wrapper>
    );
};
