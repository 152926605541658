import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm"
import { useModal } from "@/features/modal/ModalStackManager"
import { PartnerDetailForm } from "@/features/partner/form/detailForm"
import { DetailBadge } from "@/features/ui/detail/DetailBadge"
import { DetailContent } from "@/features/ui/detail/DetailContent"
import { DetailField } from "@/features/ui/detail/DetailField"
import { DetailHeader } from "@/features/ui/detail/DetailHeader"
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle"
import { DetailLabel } from "@/features/ui/detail/DetailLabel"
import { DetailLink } from "@/features/ui/detail/DetailLink"
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper"
import { DetailTitle } from "@/features/ui/detail/DetailTitle"
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm"
import { getUserCodeByUser } from "@/utils/checkData"
import { purchaseOrderStatusColor, setPurchaseOrderStatus } from "@/utils/scmDataStatus"
import { Badge, Checkbox, Space } from "@mantine/core"
import { PurchaseOrdersGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models"
import dayjs from "dayjs"

const OrderDetail = Object.assign(DetailHeader, {
    Title: DetailTitle,
    Field: DetailField,
    Label: DetailLabel,
    Content: DetailContent,
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
    CheckBox: Checkbox,
    Inform: DetailInformTitle,
})

export interface OrderDetailHeaderProps {
    data: PurchaseOrdersGet200ResponseRowsInner | undefined;
}

export const OrderDetailHeader = (params: OrderDetailHeaderProps) => {
    const { data } = params;
    const userCode = getUserCodeByUser();
    const { openModal } = useModal();
    return (
        <>
            <OrderDetail>
                <OrderDetail.Title>{`${userCode === "FLOUR" ? '[수주]' : '[발주]'}`} {data?.code}</OrderDetail.Title>
                <Space h={8} />
                <OrderDetail.Field>
                    <OrderDetail.Label>
                        {`${userCode === "FLOUR" ? '수주번호' : '발주번호'}`}
                    </OrderDetail.Label>
                    <OrderDetail.Content>
                        {data?.id}
                    </OrderDetail.Content>
                </OrderDetail.Field>
                <OrderDetail.Field>
                    <OrderDetail.Label>
                        {`${userCode === "FLOUR" ? '수주코드' : '발주코드'}`}
                    </OrderDetail.Label>
                    <OrderDetail.Content>
                        {data?.code}
                    </OrderDetail.Content>
                </OrderDetail.Field>
                <OrderDetail.Field>
                    <OrderDetail.Label>
                        {"협력사"}
                    </OrderDetail.Label>
                    <OrderDetail.Content>
                        <OrderDetail.Link
                            onClick={() => openModal(
                                <PartnerDetailForm partnerCode={data?.partnerCode} />,
                                null,
                                "협력사 상세",
                            )}
                        >
                            {data?.partnerCode}
                        </OrderDetail.Link>
                    </OrderDetail.Content>
                </OrderDetail.Field>
                <OrderDetail.Field>
                    <OrderDetail.Label>
                        {"상태"}
                    </OrderDetail.Label>
                    <OrderDetail.Content>
                        <Badge
                            variant="filled"
                            color={purchaseOrderStatusColor[data?.status as keyof typeof purchaseOrderStatusColor]}
                        >{setPurchaseOrderStatus(data?.status ?? '')}</Badge>
                    </OrderDetail.Content>
                </OrderDetail.Field>
                <OrderDetail.Field>
                    <OrderDetail.Label>
                        {"담당자"}
                    </OrderDetail.Label>
                    <OrderDetail.Content>
                        <OrderDetail.Link
                            onClick={() => openModal(
                                <UsersDetailForm UserCode={data?.userCode} />,
                                null,
                                "담당자 상세",
                            )}
                        >
                            {data?.userCode}
                        </OrderDetail.Link>
                    </OrderDetail.Content>
                </OrderDetail.Field>
                <OrderDetail.Field>
                    <OrderDetail.Label>
                        {"입고 로케이션"}
                    </OrderDetail.Label>
                    <OrderDetail.Content>
                        <OrderDetail.Link
                            onClick={() => openModal(
                                <LocationDetailForm locationCode={data?.targetLocationCode} />,
                                null,
                                "로케이션 상세",
                            )}
                        >
                            {data?.targetLocationName}
                        </OrderDetail.Link>
                    </OrderDetail.Content>
                </OrderDetail.Field>
                <OrderDetail.Field>
                    <OrderDetail.Label>
                        {"비고"}
                    </OrderDetail.Label>
                    <OrderDetail.Content>
                        {data?.description}
                    </OrderDetail.Content>
                </OrderDetail.Field>
                <OrderDetail.Field>
                    <OrderDetail.Label>
                        {"발주일자"}
                    </OrderDetail.Label>
                    <OrderDetail.Content>
                        {dayjs(data?.orderDate).format("YYYY-MM-DD")}
                    </OrderDetail.Content>
                </OrderDetail.Field>
                <OrderDetail.Field>
                    <OrderDetail.Label>
                        {"납기일자"}
                    </OrderDetail.Label>
                    <OrderDetail.Content>
                        {dayjs(data?.deliveryDeadline).format("YYYY-MM-DD")}
                    </OrderDetail.Content>
                </OrderDetail.Field>
                <OrderDetail.Field>
                    <OrderDetail.Label>
                        {"접수일시"}
                    </OrderDetail.Label>
                    <OrderDetail.Content>
                        {dayjs(data?.createdAt).format("YYYY-MM-DD")}
                    </OrderDetail.Content>
                </OrderDetail.Field>
                <OrderDetail.Field>
                    <OrderDetail.Label>
                        {"수정정보"}
                    </OrderDetail.Label>
                    <OrderDetail.Content>
                        {data?.updatedAt === null ? '' : dayjs(data?.updatedAt).format("YYYY-MM-DD")}
                    </OrderDetail.Content>
                </OrderDetail.Field>
            </OrderDetail>
        </>
    )
}