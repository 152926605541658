import { useModal } from "@/features/modal/ModalStackManager"
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete"
import { PurchaseOrderItemsAutoComplete } from "@/features/ui/autoComplete/purchaseOrderItems/purchaseOrderItems-autoComplete"
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete"
import { Calendar, isCalendarDate } from "@/features/ui/Calendar"
import styled from "@emotion/styled"
import { Flex, Text, TextInput } from "@mantine/core"
import { DateInput } from "@mantine/dates"
import { UseFormReturnType } from "@mantine/form"
import { ItemsGet200ResponseRowsInnerItemTypeEnum, ProductionPlansWithWorksPostRequest } from "@sizlcorp/sizl-api-document/dist/models"
import { IconCalendar } from "@tabler/icons-react"
import dayjs from "dayjs"

interface ProductionPlanFormProps {
    form: UseFormReturnType<Partial<ProductionPlansWithWorksPostRequest> &
    {
        scheduledAtDate: Date;
        siteCode?: string | undefined;
        siteName?: string | undefined;
    }>;
    setSiteName: (e: string) => void;
    isProductable: boolean;
}
export const ProductionPlanForm = (params: ProductionPlanFormProps) => {
    const { form, setSiteName, isProductable } = params;
    const { openModal } = useModal();
    return (
        <Flex gap="lg" direction="column">
            <Flex gap="lg" justify="center" align="center">
                <DateInput
                    withAsterisk
                    popoverProps={{ withinPortal: true }}
                    readOnly
                    icon={<IconCalendar size="1.2rem" />}
                    label="계획일자"
                    description="생산 예정일자를 선택합니다."
                    w={"100%"}
                    valueFormat="YYYY-MM-DD"
                    onClick={() => openModal(
                        <Calendar dates={[form.values.scheduledAtDate, form.values.scheduledAtDate]} />,
                        null,
                        "날짜 선택"
                    ).then((value) => {
                        if(isCalendarDate(value)) form.setFieldValue("scheduledAtDate", dayjs(value[0]).toDate());
                    })}
                    {...form.getInputProps("scheduledAtDate")}
                />
                <SitesAutoComplete
                    label="사업장"
                    description="생산할 사업장을 선택합니다."
                    placeholder="사업장 선택"
                    w={'100%'}
                    {...form.getInputProps("siteCode")}
                    setSiteName={(e: string) => setSiteName(e)}
                />
            </Flex>
            <Flex gap="lg" justify="center" align="center">
                <ItemAutoComplete
                    withAsterisk
                    label="품목 선택"
                    description="생산할 품목을 선택합니다."
                    placeholder="품목 선택"
                    getItemType={[ItemsGet200ResponseRowsInnerItemTypeEnum.PRODUCT, ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT]}
                    w={'100%'}
                    {...form.getInputProps("itemCode")}
                />
                <TextInput
                    withAsterisk
                    type="number"
                    label="목표 수량"
                    description="생산 목표 수량을 입력합니다."
                    placeholder="목표 수량"
                    w={'100%'}
                    {...form.getInputProps("targetQuantity")}
                />
            </Flex>
            <Flex gap="lg" >
                <PurchaseOrderItemsAutoComplete
                    disabled={!!!form.values.itemCode}
                    label="납품 할 수주 선택"
                    description="납품 할 수주를 선택합니다."
                    placeholder="수주 선택"
                    w={'100%'}
                    itemCodes={form.values.itemCode}
                    {...form.getInputProps("purchaseOrderItemId")}
                />
            </Flex>
            {!isProductable &&
                form.values.itemCode &&
                form.values.targetQuantity && (
                    <WarningText>
                        품목을 생산하기 위한 라우팅이 설정되어 있지 않아 생산을 계획할
                        수 없습니다.
                    </WarningText>
                )}
        </Flex>
    )
}

const WarningText = styled<any>(Text)`
  color: var(--red-6, #fa5252);
  /* components/input/error/xs */
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
`;
