import { ProductionInstance } from "@/instance/axios";
import { createMutationKeys } from "@lukemorales/query-key-factory";
import { ProductionActionApiWorksWorkIdConsumeSubsidiaryPutRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const mutateConsumeSubsidiary = createMutationKeys('subsidiary', {
    // 입고
    consumeSubsidiary: (params: ProductionActionApiWorksWorkIdConsumeSubsidiaryPutRequest) => {
        return {
            mutationKey: [params.worksWorkIdConsumeSubsidiaryPutRequest],
            mutationFn: () => ProductionInstance.worksWorkIdConsumeSubsidiaryPut(params)
        }
    },

});