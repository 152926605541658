import {
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_SortingState,
    // createRow,
    type MRT_ColumnDef
} from 'mantine-react-table';
import { useMemo, useState } from 'react';

import { inventories } from '@/api/inventories/useInventoriesQuery';
import { ItemsDetailForm } from '@/features/item/components/form/ItemsDetailForm';
import { useModal } from '@/features/modal/ModalStackManager';
import { DetailLink } from '@/features/ui/detail/DetailLink';
import { MantineReactCommonTable } from '@/features/ui/mantineTable';
import timeUtil from '@/utils/timeUtil';
import { setToLocaleString } from '@/utils/unitMark';
import { Checkbox } from '@mantine/core';
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from '@sizlcorp/sizl-api-document/dist/models';
import { useQuery } from "@tanstack/react-query";
import dayjs from 'dayjs';


export interface Row extends ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner {
    itemCode: string;
    id: number;
    itemUsesLotExpiration: boolean;
    itemUsesLotName: boolean;
    lot: {
        expiration: string;
        name: string;
    };
    lotName: string;
    lotExpiration: string;
    quantity: string;
    createdAt: string;
    unitText: string;
}

export const LocationLedgerTabs = ({ locationCode }: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => {

    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 1,
        pageSize: 10,
    });
    // 각 컬럼 sort
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    // 각 컬럼 filter
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    // 전체 검색 (단, serchFields에 필드를 직접 입력해줘야함.)
    const [globalFilter, setGlobalFilter] = useState('');

    // sort를 id 별 string으로 만들어줌
    const sortString = sorting
        .map(({ id, desc }) => (desc ? `-${id}` : id))
        .join(',');

    // filter를 id 별 like를 사용하여 단어가 포함되어있는지 확인
    const filterQuery: { [key: string]: { $iLike: string } } = columnFilters.reduce<{ [key: string]: { $iLike: string } }>((acc, filter) => {
        acc[filter.id] = { $iLike: `%${filter.value}%` as string };
        return acc;
    }, {});

    const { data } = useQuery(inventories.get({
        query: {
            $and: [
                { "locationCode": { "$eq": locationCode } },
                ...Object.keys(filterQuery).map(key => ({ [key]: filterQuery[key] }))
            ]
        },
        populate: ["lot", "item"],
        pageSize: pagination.pageSize,
        page: pagination.pageIndex,
        search: globalFilter,
        searchFields: ["itemCode", "lotName"],
        sort: sortString || undefined,
    }));

    const rows: Row | ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[] = data?.data.rows ?? [];

    const { openModal } = useModal();

    const columns = useMemo<MRT_ColumnDef<Row>[]>(
        () => [
            {
                accessorFn: (row) => row.id,
                accessorKey: 'id',
                header: '품목 번호',
                size: 80,
                enableColumnFilter: false, // id는 검색 못하게
                Cell: (rows) => {
                    return (
                        <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.id}</div>
                    )
                }
            },
            {
                accessorFn: (row) =>
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <ItemsDetailForm itemCode={row.itemCode} />,
                                null,
                                "품목 상세"
                            )
                        }}
                        justify={"flex-start"}
                    >
                        {row.itemCode}
                    </DetailLink>,
                accessorKey: 'itemCode',
                header: '품목코드',
                size: 80,
            },
            {
                accessorFn: (row) => [
                    <div><Checkbox label="이름" checked={row.itemUsesLotName} mb={8} disabled /></div>,
                    <div><Checkbox label="유효기한" checked={row.itemUsesLotExpiration} disabled /></div>
                ],
                accessorKey: 'itemUses',
                header: '로트관리 기준',
                size: 120,
                enableColumnFilter: false,
            },
            {
                accessorFn: (row) => [
                    <div>이름 : {row.lotName}</div>,
                    <div>유효기한 : {timeUtil(row.lotExpiration === null ? '' : row.lotExpiration) === 'NaN-NaN-NaN' ? '' : timeUtil(row.lotExpiration === null ? '' : row.lotExpiration)}</div>
                ],
                accessorKey: 'lot',
                header: '로트 정보',
                size: 160,
            },
            {
                accessorFn: (row) => row.quantity,
                accessorKey: 'quantity',
                header: '수량',
                enableColumnFilter: false,
                size: 80,
                Cell: (rows) => {
                    const quantity = setToLocaleString(rows.row._valuesCache.quantity) === "NaN" ? '0' : setToLocaleString(rows.row._valuesCache.quantity);
                    const unitText = rows.row.original.unitText;
                    return (
                        <div style={{ textAlign: 'right' }}>{quantity} {unitText}</div>
                    )
                }
            },
            {
                accessorFn: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                accessorKey: 'createdAt',
                header: '생성일',
                enableColumnFilter: false,
                size: 200,
            }
        ],
        [],
    );

    return (
        <MantineReactCommonTable
            data={rows as Row[]}
            columns={columns}
            totalCount={data?.data.total}
            totalPages={data?.data.totalPages}
            pagination={pagination} // pagination
            setPagination={setPagination}
            sorting={sorting} // 정렬
            setSorting={setSorting}
            columnFilters={columnFilters} // 컬럼별 검색
            setColumnFilters={setColumnFilters}
            globalFilter={globalFilter} // 전체 검색
            setGlobalFilter={setGlobalFilter}
            enableTopToolbar={true}
        />
    )
};
