import { css } from "@emotion/css";
import styled from "@emotion/styled";
import {
  Checkbox,
  Flex,
  Pagination,
  useMantineColorScheme
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { FormatterProps, SelectColumn } from "react-data-grid";

import { wipInventory } from "@/api/wipInventories/useWipInventoriesQuery";
import { wipInventoriesHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import useQueryString from "@/hooks/useQueryString";
import useUpdateUrlParams from "@/hooks/useUpdateUrlParams";
import { theme } from "@/styles/theme";
import { setToLocaleString } from "@/utils/unitMark";
import { WorkLogsWorkLogIdCancelEndPut200ResponseCancelProgressInner } from "@sizlcorp/sizl-api-document/dist/models/src/model";
import { useQuery } from "@tanstack/react-query";

export interface Row {
  id: number;
  itemCode: string;
  locationCode: string;
  quantity: string;
  productionPlanId: number;
  unitText: string;
  routingStep: {
    map(
      arg0: (step: any, index: any) => import("react/jsx-runtime").JSX.Element
    ): unknown;
    FULL01?: boolean;
    FULL02?: boolean;
    FULL03?: boolean;
    FULL04?: boolean;
  };
  productionPlans: {
    routingsData: [
      {
        seq: number;
        code: string;
        operation: {
          name: string;
        };
      }
    ];
  };
}

interface ColorThemeProps {
  isDarkMode: boolean;
}

export const WipInventoryTable = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [formatterProps, setFormatterProps] = useState<
    FormatterProps<Row, unknown> | undefined
  >();
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );

  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const updateUrlParams = useUpdateUrlParams();

  const { openModal } = useModal();

  const searchFieldsHeader = wipInventoriesHeader.filter(
    (wipInventory) => wipInventory.category === "text"
  );

  // 검색조건이 변경되면 current 페이지를 1로 초기화
  const isSort = sort.length;

  const { data: wipInventories, refetch } = useQuery(wipInventory.get({
    query: query,
    search: search.search,
    searchFields: search.searchFields.length
      ? search.searchFields
      : searchFieldsHeader.map((wipInventory) => wipInventory.value),
    page: activePage,
    pageSize: Number(search.pageSize),
    sort: sort.length ? sort.join(",") : "-id",
    populate: ["productionPlans", "itemUnit"],
  }));

  // 새로운 데이터가 로드될 때, 현재 페이지가 유효하지 않으면 1페이지로 설정
  useEffect(() => {
    if (wipInventories && activePage > (wipInventories?.data?.totalPages ?? 0)) {
      setPage(1);
      setQueryString((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [wipInventories, activePage, setQueryString]);

  // 페이지 Query String으로 공정 이동
  useEffect(() => {
    updateUrlParams({
      page: activePage,
      pageSize: search.pageSize,
      search: search.search,
      searchFields: search.searchFields,
      query: JSON.stringify(query),
      sort: sort
    }, queryDefaultValues);

    // 선택된 행 초기화
    setSelectedRows(new Set());
  }, [activePage, search, query, sort, updateUrlParams]);

  const rows: readonly WorkLogsWorkLogIdCancelEndPut200ResponseCancelProgressInner[] =
    wipInventories?.data.rows ?? [];
  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

  const columns: readonly Column<Row>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "itemCode",
      name: "품목코드",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const detailItemAction = () => {
          openModal(
            <ItemsDetailForm itemCode={row.itemCode} />,
            null,
            ""
          );
        };
        return <DetailLink onClick={detailItemAction}>{row.itemCode}</DetailLink>;
      },
    },
    {
      key: "locationCode",
      name: "위치",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const detailItemAction = () => {
          openModal(
            <LocationDetailForm locationCode={row.locationCode ?? ""} />,
            null,
            "창고 상세"
          );
        };
        return (
          <DetailLink
            onClick={detailItemAction}
          >
            {row.locationCode}
          </DetailLink>
        );
      },
    },
    {
      key: "productionPlanId",
      name: "생산 계획 번호",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: (row) => {
        const detailProductionPlanAction = () => {
          openModal(
            <ProductionPlanViewForm
              ProductionPlanId={row.row.productionPlanId}
            />,
            null,
            ""
          );
        };

        return (
          <DetailLink
            onClick={detailProductionPlanAction}
          >
            {row.row.productionPlanId}
          </DetailLink>
        );
      },
    },
    {
      key: "routingStep",
      name: "재공 라우팅단계",
      width: 700,
      formatter: (row) => {
        return (
          <>
            {row?.row?.routingStep && typeof row?.row === "object"
              ? Object.entries(row.row.routingStep).map(
                ([step, checked], index) => {
                  const routing =
                    row.row.productionPlans.routingsData[index];
                  return (
                    <Checkbox
                      key={step}
                      value={step}
                      label={`(${routing?.seq}) ${routing?.operation?.name === null
                        ? ""
                        : routing?.operation?.name
                        } 라우팅`}
                      mr={12}
                      disabled
                      checked={checked as boolean}
                    />
                  );
                }
              )
              : null}
          </>
        );
      },
    },
    {
      key: "quantity",
      name: "재고",
      sortable: true,
      resizable: true,
      width: 160,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => {
        return (
          <span>
            {setToLocaleString(row.quantity)} {row.unitText}
          </span>
        );
      },
    },
  ];

  return (
    <InventoryTableWrapper>
      <GridWrapper isDarkMode={isDarkMode}>
        <TableWrapper>
          <RetrieveWrapper>
            <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
              <CustomFilter filterType={wipInventoriesHeader} setQuery={setQuery} query={query} />
              <CustomSorter sorterType={wipInventoriesHeader} setSort={setSort} sort={sort} />
            </Flex>
            <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
          </RetrieveWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rows as any}
            rowHeight={40}
            rowKeyGetter={(row: Row) => row.id}
            sortColumns={sortColumns}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              selectedRowsRef.current = e;
            }}
          />
        </TableWrapper>
        <PaginationWrapper>
          <Pagination
            onChange={setPage}
            value={activePage}
            total={wipInventories?.data?.totalPages ?? 0}
            size="lg"
            radius="sm"
          />
        </PaginationWrapper>
      </GridWrapper>
    </InventoryTableWrapper>
  );
};

const InventoryTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;
