import DefaultInstance from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiRoutingsFindPostRequest, DefaultApiRoutingsGetRequest, DefaultApiRoutingsPostRequest, DefaultApiRoutingsRoutingIdDeleteRequest, DefaultApiRoutingsRoutingIdGetRequest, DefaultApiRoutingsRoutingIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const routings = createQueryKeys('routings', {
    all: null,
    get: (params: DefaultApiRoutingsGetRequest) => {
        // const queryParams = { ...params };
        const queryParams = { ...params, query: JSON.stringify({ "$and": params?.query }), sort: params.sort?.length ? params.sort : "-id", }; // 라우팅 조회 시 기본 정렬 표시

        return {
            queryKey: [queryParams, JSON.stringify(queryParams)],
            queryFn: () => DefaultInstance.routingsGet(queryParams),
        }
    },
    // get: (params: DefaultApiRoutingsGetRequest) => {
    //     const query = { ...params }; // params 객체를 복사합니다.
    //     // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
    //     if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
    //         query["query"] = JSON.stringify(params.query);
    //     }
    //     // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    //     if (params.search) {
    //         query["search"] = params.search;
    //         query["searchFields"] = params.searchFields;
    //     }
    //     if (params.populate?.length) {
    //         query["populate"] = params.populate;
    //     }
    //     return {
    //         queryKey: [query, JSON.stringify(query.query)],
    //         queryFn: () => DefaultInstance.routingsGet(query)
    //     };
    // },
    detail: (params: DefaultApiRoutingsRoutingIdGetRequest) => ({
        queryKey: [params.routingId],
        queryFn: () => DefaultInstance.routingsRoutingIdGet(params)
    }),
    find: (params: DefaultApiRoutingsFindPostRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => DefaultInstance.routingsFindPost(query)
        };
    },
})

export const mutateRoutings = createMutationKeys('routings', {
    create: (params: DefaultApiRoutingsPostRequest) => ({
        mutationKey: [params.routingsGetRequest],
        mutationFn: () => DefaultInstance.routingsPost(params)
    }),
    update: (params: DefaultApiRoutingsRoutingIdPutRequest) => ({
        mutationKey: [params.routingId, params.routingsGetRequest],
        mutationFn: () => DefaultInstance.routingsRoutingIdPut(params)
    }),
    delete: (params: DefaultApiRoutingsRoutingIdDeleteRequest) => ({
        mutationKey: [params.routingId],
        mutationFn: () => DefaultInstance.routingsRoutingIdDelete(params)
    }),
});

export const routingsQueryKeys = mergeQueryKeys(routings, mutateRoutings);