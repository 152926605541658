import { mutateUsers } from "@/api/users/useUsersQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { UsersCreateForm } from "@/features/users/components/form/UsersCreateForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import styled from "@emotion/styled";
import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { MasterApiUsersPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EventHandler, ReactNode, forwardRef, useState } from "react";
import customAlert from "../../alert/alert";
import { UserOption, useUsersAutoComplete, useUsersCode } from "./auto-userGetUserQuery";

interface UserProps extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  value: string;
}

export interface UserAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (itemCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
}

export const UserAutoComplete = (params: UserAutoCompleteProps) => {
  const {
    value: userCode,
    onChange,
    maxDropdownHeight,
    width,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const { data: options } = useUsersAutoComplete(focused, searchKeyword);

  const { data: initialOptions } = useUsersCode(
    !!userCode,
    userCode ?? null
  );

  let selectedRoute = initialOptions?.find((item) => {
    return item.value === userCode;
  });

  const onChangeHandler = (e: string | null) => {
    selectedRoute = options?.find((item) => item.value === e);
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, UserProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{code}</Text>
            <Text fz="xs"> (name: {name === "null" ? "-" : name})</Text>
          </Flex>
        </Flex>
      </div>
    )
  );

  return (
    <UserSelect
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {userCode && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "10px",
              }}
            >
              {"code: " + userCode}
            </div>
          )}
        </div>
      )}
      value={userCode}
      itemComponent={SelectItem}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: UserProps[], option: UserProps) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={UserInfo({
        route: selectedRoute as UserOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewUser({
        userCode: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const UserInfo = (params: {
  route?: UserOption;
  onChange: (userCode: string | null) => void;
}) => {
  const { route, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return route?.value ? (
    <UserInfoLabel>
      {/* ({route.value})  */}
      <IconX size="1rem" onClick={clearHandler} />
    </UserInfoLabel>
  ) : null;
};

const AddNewUser = (params: {
  userCode: string;
  onChange: (itemCode: string) => void;
}) => {
  const { userCode, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params: MasterApiUsersPostRequest) =>
      mutateUsers.create(params).mutationFn(params as MasterApiUsersPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
        customAlert(
          "사용자 등록에 성공하였습니다.",
          "등록 성공",
          "green"
        )
      },
      onError: (error: any) => {
        customAlert(handleErrorResponse({ error, errorContext: '사용자 코드나 아이디' }), '사용자 생성 실패', 'red')
      }
    }
  );

  const onCloseHandler: EventHandler<any> = (values) => {
    postMutate(
      {
        authSignupPostRequest: {
          code: values.code!,
          name: values.name!,
          username: values.username!,
          password: values.password!,
        }
      },
    );
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <UsersCreateForm code={userCode} autoCompleteOnSubmit={onCloseHandler} />,
      null,
      "신규 사용자 등록",
      true
    ).then((result) => {
      onChange(result.code);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 사용자({userCode}) 등록
    </Button>
  );
};
const UserInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

const UserSelect = styled(Select)<{value : string | null | undefined}>`
  .mantine-Select-input {
    font-size: 0.8rem;
    padding-bottom: ${(props) => (props.value ? '0.8rem !important' : '')};

    &.item-dirty {
      color: cadetblue !important;
      border-color: cadetblue;
    }
  }
  .mantine-Select-error {
    font-size: 11px;
    color: red;
  }
`
