import DefaultInstance from "@/instance/axios";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export const GET_INVENTORIES_QUERY_KEY = "get-inventories";

export const getInventoriesFetch = (params?: any) => {

  if (params) {
    const query: any = {};
    // query를 보내는 조건은 필터 검색을 할 때이다.
    if (params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    // 검색내용이 있을 때만 search를 보내야 한다.
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return DefaultInstance.inventoriesGet({
      ...query,
      populate: ["lot", "location", "item"],
      page: params.activePage ?? 1,
      pageSize: params.pageSize ?? 10,
      sort: params?.sort,
    });
  } else return DefaultInstance.inventoriesGet({ populate: ["lot"] });
};

const useInventoriesGetQuery = (
  params?: any,
  options?: {
    onSuccess: (data: any) => void;
  }
) => {
  return useQuery<AxiosResponse<any, any[]>>({
    queryKey: params
      ? [
        GET_INVENTORIES_QUERY_KEY,
        params.activePage,
        JSON.stringify(params.query),
        params.searchFields,
        params.search,
      ]
      : [GET_INVENTORIES_QUERY_KEY],
    enabled: JSON.stringify(params.query) !== "{}",
    queryFn: () =>
      params ? getInventoriesFetch(params) : getInventoriesFetch(),
    onSuccess: (res) => res.data && options?.onSuccess(res.data),
  });
};

export default useInventoriesGetQuery;
