import { DEPARTMENT_QUERY_KEY, mutateDepartment } from "@/api/department/useDepartmentQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { Box, Button, Checkbox, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DepartmentsGet200ResponseRowsInner, MasterApiDepartmentsDepartmentIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

const Update = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Checkbox,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
});

export const DepartmentUpdateForm = (params: DepartmentsGet200ResponseRowsInner) => {
    const { code, name, id } = params
    const { closeModal } = useModal();
    const queryClient = useQueryClient();

    const { mutate: updateMutate } = useMutation(
        (params: MasterApiDepartmentsDepartmentIdPutRequest) =>
            mutateDepartment.update(params).mutationFn(undefined),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([DEPARTMENT_QUERY_KEY]);
                customAlert(
                    "부서가 성공적으로 수정되었습니다.",
                    "수정 성공",
                    "green"
                )
            },
            onError: (error: AxiosError<Error>) => {
                customAlert(
                    error.response?.data?.message ?? "부서 수정에 실패하였습니다.",
                    "수정 실패",
                    "red"
                )
            }
        }
    );

    const form = useForm<DepartmentsGet200ResponseRowsInner>({
        initialValues: {
            code: code ?? undefined,
            name: name ?? undefined,
            id: id ?? undefined,
        },
        validate: {
            code: (value) => value ? null : '부서 코드를 입력해주세요.',
            name: (value) => value ? null : '부서명을 입력해주세요.',
        }
    });

    const onSubmit = async (values: DepartmentsGet200ResponseRowsInner) => {
        updateMutate({
            departmentId: values.id as number,
            departmentsGetRequest: {
                code: values.code as string,
                name: values.name as string,
            }
        })
        closeModal({});
    };

    return (
        <Box>
            <form
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    width: "30rem",
                    height: "15rem",
                }}
                onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}
            >
                <Update.Input
                    withAsterisk
                    label="부서 코드"
                    placeholder="부서 코드"
                    {...form.getInputProps("code")}
                    error={form.isDirty("code") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("code")
                                ? "cadetblue !important"
                                : "inherit",
                            borderColor: form.isDirty("code")
                                ? "cadetblue !important"
                                : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" },
                    }}
                />
                <Update.Input
                    withAsterisk
                    label="부서명"
                    placeholder="부서명"
                    {...form.getInputProps("name")}
                    error={form.isDirty("name") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("name")
                                ? "cadetblue !important"
                                : "inherit",
                            borderColor: form.isDirty("name")
                                ? "cadetblue !important"
                                : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" },
                    }}
                />
                <Update.ButtonBox>
                    <Update.Button color="gray" onClick={closeModal}>취소</Update.Button>
                    <Update.Button
                        disabled={!form.isValid()}
                        type="submit"
                    >
                        부서 수정
                    </Update.Button>
                </Update.ButtonBox>
            </form>
        </Box >
    )
};