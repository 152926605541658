import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { Main } from "@/features/standard/Main";
import { SplitLotGet200ResponseRowsInnerDefectsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { DefectDetailView } from "./defectDetailView";

export interface DefectDetailFormProps {
    defectData : SplitLotGet200ResponseRowsInnerDefectsInner;
}

const Detail = Object.assign(Main, {
    Container: DetailContainer,
    Wrapper : DetailWrapper,
    Content : DetailContent
});
export const DefectDetailForm = (params : DefectDetailFormProps) => {
    const { defectData } = params;

    return (
        <Detail.Container>
            <Detail.Wrapper>
                <Detail.Content>
                    <DefectDetailView defectData={defectData} />
                </Detail.Content>
            </Detail.Wrapper>
        </Detail.Container>
    )
};