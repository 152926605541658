import styled from "@emotion/styled";
import { Text } from "@mantine/core";

interface WorkStatusBoxProps {
    children: React.ReactNode;
    backgroundColor?: string | undefined;
}

export const WorkStatusBox = ({ children, backgroundColor }: WorkStatusBoxProps) => {
    return (
        <Content style={{ backgroundColor: backgroundColor }}><Text size="xl" align="center">{children}</Text></Content>
    )
};

const Content = styled.div`
    background-color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: 600;
    border-radius: 4px;
    width: 4.5rem;
    height: 12.4rem;
`