import styled from "@emotion/styled";

interface ParentsBoxDivProps {
    children: React.ReactNode;
    height: string;
}

export const ParentsBoxDiv = ({ children, height }: ParentsBoxDivProps) => {
    return (
        <Content style={{ height: `${height}` }}>{children}</Content>
    )
}

const Content = styled.div`
    width : 100%;
    height : 6rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`